import React, { Fragment, useEffect, useState } from 'react'
import EditTermInsurance from './EditTermInsurance';
import EditHealthInsurance from './EditHealthInsurance';
import axioInstance from '../../axiosInstance';

export default function EditCommitments({ commitments, setActive }) {

    const [expand, setExpand] = useState(0);

    const activeExpand = (status) => {
        setExpand(expand === 0 ? 1 : 0)
    }

    const [expandHealth, setExpandHealth] = useState(0);

    const healthExpand = (status) => {
        setExpandHealth(expand === 0 ? 1 : 0)
    }

    const Back = () => {
        setActive(false);
    }


    // eslint-disable-next-line
    const [self, setSelf] = useState([]);
    // eslint-disable-next-line
    const [spouse, setSpouse] = useState([]);

    useEffect(() => {


        axioInstance.post(`profile-details/get-details`, {profile_id:sessionStorage.getItem('profile_id')}).then(
            (response, data) => {

                let self = response.data.find(product => product.relation === "self");

                console.log(self);
                setSelf(self);


                let spouse = response.data.find(product => product.relation === "spouse");
                setSpouse(spouse);

            });

    }, []);

    return (
        <Fragment>

            <div className="rightSide_menu_outer">
                <div className="rightSide_menu">
                    <div className="rightSide_menu_header">
                        <div>
                            <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
                        </div>
                        <div className="font14 fw500 color182">
                            Edit Commitments
                        </div>
                        <div>&nbsp;</div>
                    </div>
                    <div className="topBanner topBanner_editCommitements pl25">
                        <div className="font18 fw600 color182 mt50">Commitments</div>
                        <div className="font14 fw500 color485">{self.full_name} family’s commitments</div>
                    </div>
                    <div className="pl20 pr20">
                        <div className="collapse_outer mt20">
                            <div className="collapse_title">
                                <div className="font16 fw600 color263">Term Insurance</div>
                                <div>
                                    <button className="btn btn-outline-primary font12 fw500 expandBtn" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" onClick={() => activeExpand()}>
                                        + Expand
                                    </button>
                                </div>
                            </div>

                            {
                                expand === 0 && (<><div className="expandtab_outer mt12">
                                    <div className="expandtab font12 color353 fw500">{self.full_name} Term Insurance</div>
                                    {spouse ? <div className="expandtab font12 color353 fw500">{spouse.full_name} Term Insurance</div> : ''}
                                </div></>)
                            }



                            <div className="collapse" id="collapseExample">
                                <div className="card card-body">

                                    {

                                        expand === 1 && (<EditTermInsurance commitments={commitments} self={self} spouse={spouse} />)
                                    }

                                </div>
                            </div>
                        </div>

                        <div className="collapse_outer mt20">
                            <div className="collapse_title">
                                <div className="font16 fw600 color263">Health Insurance</div>
                                <div>
                                    <button className="btn btn-outline-primary font12 fw500 expandBtn" type="button" data-toggle="collapse" data-target="#collapseExample2" aria-expanded="false" aria-controls="collapseExample2" onClick={() => healthExpand()}>
                                        + Expand
                                    </button>
                                </div>
                            </div>

                            {
                                expandHealth === 0 && (<><div className="expandtab_outer mt12">
                                    <div className="expandtab font12 color353 fw500">{self.full_name} Health Insurance</div>
                                    {spouse ? <div className="expandtab font12 color353 fw500">{spouse.full_name} Health Insurance</div> : ''}
                                </div></>)
                            }

                            <div className="collapse" id="collapseExample2">
                                <div className="card card-body">

                                    {
                                        expandHealth === 1 && (<EditHealthInsurance spouse={spouse} />)
                                    }

                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </Fragment>
    )
}