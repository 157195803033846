import React, { Fragment, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
//import SweetAlert from 'sweetalert2';
import {  ageCalculate } from '../../global';
import formatAmount from 'indian-currency-formatter';
//import RangeSlider from 'react-bootstrap-range-slider';
//import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import "./../tabs/slider.css";
import SweetAlert from 'sweetalert2'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import axioInstance from '../../axiosInstance';
import { Encryption } from '../../global';

export default function EditAnnuity({ setGoalType, goalId, planLatestStatus }) {

  const { register, handleSubmit, reset, formState: { errors, isDirty, isValid } } = useForm();

  const [buttonSpinner, setButtonSpinner] = useState(false);

  const Back = () => {
    setGoalType('');
  }
  // eslint-disable-next-line
  const [max, setMax] = useState();


  const onSubmit = data => {

    setButtonSpinner(true);

    const achivingYear = input;
    const age = data.profile_age;
    const life_expectancy = requiredUpto;
    const relation = data.relation;
    const required_after = parseInt(achivingYear) - parseInt(age);
    const required_till = parseInt(life_expectancy) - parseInt(age) - 1;
    const aspire = "Annuity";

    const PostData = {
      "goal_type": "Annuity",
      "auto_reset": "0",
      "aspire": aspire,
      "goal_name": data.goal_name,
      "goal_id": data.goal_id,
      "profile_id": Encryption(data.profile_id),
      "admin_id":sessionStorage.getItem("admin_id"),
      "plan_for": {
        "profile_details_id": Encryption(data.plan_for),
        "current_age": age,
        "relation": relation
      },
      "goal_params": {
        "age": age,
        "retire_age": achivingYear,
        "life_expectancy": life_expectancy,
        "post_inflation": 7,
        "post_return": 8,
        "goal_inflation": inflation,
        "required_after": required_after,
        "required_till": required_till
      },
      "yearly_expense": {
        "0": data.amount.replace(/,/g, ""),
        "1": 0
      },
      "no_years": {
        "0": (required_till - required_after) + 1,
        "1": 0
      },
      "risk_profile": {
        "available": false,
        "pre_return": 10,
        "risk_profile_id": data.profile_id
      },
      "assumptions": {
        "assumption_id": false,
        "inflation": inflation,
        "income_grow": "10"
      },
      "total_abcd": [{
        "amount": data.amount.replace(/,/g, ""),
        "frequency": 1,
        "no_of_times": 1,
        "gap": 0
      }],
      "actual": [{
        "actual_y": 0,
        "frequency": 1,
        "actual_ls": 1,
        "grow": "0"

      }]
    };

    axioInstance.post(`goals/add`, PostData)
      .then(function (response) {
        const gpostData = { profile_id: sessionStorage.getItem('profile_id') };

        axioInstance.post(`goals/reset`, gpostData)
          .then(function (response) {
            axioInstance.post(`profile/summary-update`, { profile_id: sessionStorage.getItem('profile_id') }).then(
              (response, data) => {
                window.location.reload();
              }
            );
          })
      })

  }

  const [input, setInput] = useState(60);

  const [requiredUpto, setRequiredUpto] = useState(70);

  const [state, setState] = useState(false);
  const [goalData, setGoalData] = useState([]);
  const [validate, setValidate] = useState(null);

  // eslint-disable-next-line
  const [currAge, setCurrAge] = useState();

  const [selfDetails, setSelfDetails] = useState([]);

  // eslint-disable-next-line
  const [assumptions, setAssumptions] = useState([]);


  useEffect(() => {

    setState(true);
    const profile_id = sessionStorage.getItem('profile_id');
    if (profile_id !== null && state !== false) {
      axioInstance.post(`goals/goalbyid`, { "profile_id": profile_id, "goal_id": goalId }).then(
        (response, data) => {


          setGoalData(response.data);

          setCurrAge(response.data.current_age)

          setInput(parseInt(response.data.profile_age) + parseInt(response.data.required_after));

          setRequiredUpto(parseInt(response.data.profile_age) + parseInt(response.data.required_till) + 1);


          setValidate({ goal_id: response.data.goal_id, plan_for: response.data.plan_for, profile_id: response.data.profile_id, profile_age: response.data.profile_age, amount: formatAmount(response.data.total_abcd), year: response.data.retire_age, goal_name: response.data.goal_name, aspire: response.data.aspire });

          setInflation(Math.round(response.data.goal_inflation));

        });

        axioInstance.post(`profile-details/getfamily`, {profile_id:sessionStorage.getItem('profile_id')}).then(function (response, data) {


        let self = response.data.find(product => product.relation === "self");
        setSelfDetails(self);


        const postData = {
          'profile_id': sessionStorage.getItem('profile_id')
        }
        axioInstance.post(`get_assumption_by_profile_id`, postData).then(function (response) {
          setAssumptions(response.data);
        })

      });
    }
  }, [state, goalId]);

  const [profileAge, setProfileAge] = useState();

  useEffect(() => {

    setProfileAge(ageCalculate(selfDetails.dob));
    setMax((parseInt(assumptions.life_expentancy) - 1));

  }, [max, selfDetails, assumptions]);
  console.log('(parseInt(assumptions.life_expentancy) - 1)', max);
  // effect runs when user state is updated
  useEffect(() => {
    // reset form with user data
    reset(validate);
    // eslint-disable-next-line
  }, [validate]);

  const [expenses, setExpenses] = useState();
  const handleChange = (e) => {

    const { value } = e.target;

    if (e.target.name === 'amount') {
      setExpenses(formatAmount(value.replace(/,/g, "")));
    }

  };

  const [inflation, setInflation] = useState();


  const DeleteGoal = (id) => {


    SweetAlert.fire({
      title: "Are you sure?",
      text: "you want to delete this goal?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#09a635",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
      closeOnCancel: true
    })
      .then((result) => {
        if (result.isConfirmed) {


          axioInstance.post(`goals/delete`, { profile_id: sessionStorage.getItem('profile_id'), 'goal_id': id }).then(
            function (response) {

              axioInstance.post(`profile/summary-update`, { profile_id: sessionStorage.getItem("profile_id") }).then(
                (response, data) => {
                  SweetAlert.fire(
                    'Goals',
                    'Successfully Deleted.',
                    'success'
                  ).then(function () {
                    window.location.href = "/dashboard";
                  });
                }
              );

            }
          )
        }
      });
  }

  return (
    <Fragment>
      <div className="rightSide_menu_outer">
        <div className="rightSide_menu">

          <div className="rightSide_menu_header">
            <div>
              <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => DeleteGoal(goalData.goal_id)}>Delete</button>
            </div>
            <div className="font14 fw500 color182">
              Edit {goalData.goal_name} Goal
            </div>
            <div>&nbsp;</div>
          </div>
          <div className="topBanner topBanner_otherGoals pl25">
            <img src='/images/rupeee.png' alt='img' className='sidebarRupeeeIcon' />
            <div className="font18 fw600 color182">{goalData.goal_name}</div>
            <div className="font14 fw500 color485">Add {goalData.goal_name} plan in your financial journey</div>
          </div>

          <div className="form_prnt p20">
            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

              <div className="expandtab font12 color353 fw500">{goalData.full_name + ' ' + goalData.goal_name}</div>
              <br />
              <small className='text-danger'>{planLatestStatus === 7 && 'Plan Freezed'}</small>
              <input type={"hidden"} name="plan_for" {...register("plan_for")} />
              <input type={"hidden"} name="profile_id" {...register("profile_id")} />
              <input type={"hidden"} name="profile_age" {...register("profile_age")} />
              <input type={"hidden"} name="goal_id" {...register("goal_id")} />
              <input type={"hidden"} name="aspire" {...register("aspire")} />
              <input type={"hidden"} name="goal_name" {...register("goal_name")} />



              <div className="form-row">
                <div className="col-md-12 pr20">
                  <label htmlFor="inputEmail4" className="font14 fw500">Required Amount as of Today</label>
                  <div className="input-group">
                    <input type="text" className="form-control font14 color212 fw500" name="amount" {...register("amount", { required: 'Current expense is required' })} maxLength={12} defaultValue={expenses} value={expenses} onChange={handleChange} />
                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                  </div>
                  <span className="text-danger"> {errors.amount && errors.amount.message}</span>
                </div>
              </div>

              <div className="formdevider mt25 mb25" />



              <div className="rangesliderbox">
                <div className="rangesliderbox_title">
                  <div>
                    <div className="font14 fw500 color182">Achieving year</div>
                    <div className="font12 fw500 color8c9">Adjust the estimate year to achive this goal</div>
                  </div>
                  <div className="tragetvalue font13 fw700">{input}</div>
                </div>
                <div className="rangebox_bar mt12">
                  <div className="form-group">

                    <Slider name={"income_grow"} className='mt15' min={parseInt(profileAge) + 1} max={max} step={1} onChange={e => setInput(e)} value={input} />


                  </div>
                </div>
                <div className="flex_center justify_center mt8">
                  <div className="font12 fw500 color9ba">{parseInt(profileAge) + 1}</div>
                  <div className="font12 fw500 color9ba">{max}</div>
                </div>
              </div>


              <div className="formdevider mt25 mb25" />



              <div className="rangesliderbox">
                <div className="rangesliderbox_title">
                  <div>
                    <div className="font14 fw500 color182">Required Upto</div>
                  </div>
                  <div className="tragetvalue font13 fw700">{requiredUpto}</div>
                </div>
                <div className="rangebox_bar mt12">
                  <div className="form-group">

                    <Slider name={"income_grow"} className='mt15' min={parseInt(profileAge) + 1} max={(max - profileAge) + currAge + 1} step={1} onChange={e => setRequiredUpto(e)} value={requiredUpto} />
                  </div>
                </div>
                <div className="flex_center justify_center mt8">
                  <div className="font12 fw500 color9ba">{parseInt(profileAge) + 1}</div>
                  <div className="font12 fw500 color9ba">{(max - profileAge) + currAge + 1}</div>
                </div>
              </div>



              <div className="saveBtn backsaveBtn">
                <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                {planLatestStatus !== 7 && <button type={buttonSpinner ? "button" : "submit"} className="btn btn-primary" disabled={!isDirty && !isValid}>{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> Updating</> : "Save"} </button>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  )
}