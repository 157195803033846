import React, { Fragment } from 'react'
import axioInstance from './axiosInstance'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'
import { Encryption } from './global'

export default function ProfileInterestStatus({ setActive, setAction, profileId }) {

    const Back = () => {
        setActive(false);
    }

    // eslint-disable-next-line
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = data => {

        const postData = {
            "profile_id": Encryption(profileId),
            "int_level": data.int_level,
            "pi_reason": data.pi_reason,
            "admin_id": sessionStorage.getItem('admin_id')
        };

        axioInstance.post(`profile/add-profile-interest`, postData).then(
            function (response) {

                SweetAlert.fire({
                    toast: true,
                    icon: 'success',
                    title: 'Successfully Updated Profile Interest',
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', SweetAlert
                            .stopTimer)
                        toast.addEventListener('mouseleave', SweetAlert
                            .resumeTimer)
                    }
                }).then(function () {

                    window.location.reload();

                });
            }
        )
    }


    return (
        <Fragment>
            <div className="rightSide_menu_outer">
                <div className="rightSide_menu">

                    <div className="rightSide_menu_header">
                        <div>
                            <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
                        </div>
                        <div className="font14 fw500 color182">
                            Profile Interest Status
                        </div>
                        <div>&nbsp;</div>
                    </div>
                    <div className="topBanner pl25">
                        <img src='/images/rupeee.png' alt='img' className='sidebarRupeeeIcon' />
                    </div>

                    <div className="form_prnt p20">

                        <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

                            <div className="form-group">
                                <label>Select Status*</label>

                                <select className='form-control' name='int_level' id='int_level' {...register("int_level", { required: "Status is required" })}>
                                    <option disabled="" value="" selected="">select int level</option>
                                    <option value="Unassigned">Unassigned</option>
                                    <option value="HOT">HOT</option>
                                    <option value="WARM">WARM</option>
                                    <option value="COLD">COLD</option>
                                    <option value="NI">Not Interested</option>
                                </select>


                                {errors.int_level && <span className='redtext'>{errors.int_level.message}</span>}
                            </div>

                            <div className="form-group mt-4">
                                <label>Reason/Remark*</label>
                                <textarea
                                    type="textarea"
                                    className="form-control"
                                    placeholder="Enter Reason/Remark"
                                    autoComplete="nope"
                                    name="pi_reason"
                                    {...register("pi_reason", { required: "Reason/Remark is required" })}
                                />
                                {errors.pi_reason && <span className='redtext'>{errors.pi_reason.message}</span>}
                            </div>



                            <div className="saveBtn backsaveBtn">
                                <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                                <button type="submit" className="btn btn-primary">Save </button>
                            </div>

                        </form>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}
