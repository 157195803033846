import React, { Fragment, useEffect, useState } from 'react'
import axioInstance from '../../axiosInstance';
import {  inWords } from '../../global';
import { useForm } from 'react-hook-form'
import formatAmount from 'indian-currency-formatter';

export default function AddEmergencyFund({ toggleClass, setActive }) {

    const Back = () => {
        setActive(false);
    }

    const [buttonSpinner, setButtonSpinner] = useState(false);

    // eslint-disable-next-line
    const [investArr, setInvestArr] = useState([]);

    useEffect(() => {


        const profile_id = sessionStorage.getItem('profile_id')
        axioInstance.post(`investment/recommended-assets`, { profile_id: profile_id, primary_flag: "Y", radio_type: 10 }).then((response, data) => {

            if (response.data.main_assets.length > 0) {
                setInvestArr(response.data.main_assets);
                let i = 0;
                response.data.main_assets.forEach(item => {

                    if (i === 0) {
                        setEmergencyValue0(formatAmount(item.amount));
                    } else if (i === 1) {
                        setEmergencyValue1(formatAmount(item.amount));
                    } else if (i === 2) {
                        setEmergencyValue2(formatAmount(item.amount));
                    } else if (i === 3) {
                        setEmergencyValue3(formatAmount(item.amount));
                    } else if (i === 4) {
                        setEmergencyValue4(formatAmount(item.amount));
                    } else if (i === 5) {
                        setEmergencyValue5(formatAmount(item.amount));
                    } else if (i === 6) {
                        setEmergencyValue6(formatAmount(item.amount));
                    } else if (i === 7) {
                        setEmergencyValue7(formatAmount(item.amount));
                    } else if (i === 8) {
                        setEmergencyValue8(formatAmount(item.amount));
                    } else if (i === 9) {
                        setEmergencyValue9(formatAmount(item.amount));
                    } else if (i === 10) {
                        setEmergencyValue10(formatAmount(item.amount));
                    }

                    i++;
                  });
                  
            } else {
                setInvestArr([]);
            }

        });

        return () => console.log("Cleanup..");
    }, []);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = data => {
        setButtonSpinner(true);

        axioInstance.post(`emergency-fund/add`, data)
        .then(function (response) {
            if(response.data.status === 100){
                setButtonSpinner(false);
                window.location.reload(true)
            }
        })

    }

    // eslint-disable-next-line
    const [emergencyValue0, setEmergencyValue0] = useState(0);
    // eslint-disable-next-line
    const [emergencyValue1, setEmergencyValue1] = useState(0);
    // eslint-disable-next-line
    const [emergencyValue2, setEmergencyValue2] = useState(0);
    // eslint-disable-next-line
    const [emergencyValue3, setEmergencyValue3] = useState(0);
    // eslint-disable-next-line
    const [emergencyValue4, setEmergencyValue4] = useState(0);
    // eslint-disable-next-line
    const [emergencyValue5, setEmergencyValue5] = useState(0);
    // eslint-disable-next-line
    const [emergencyValue6, setEmergencyValue6] = useState(0);
    // eslint-disable-next-line
    const [emergencyValue7, setEmergencyValue7] = useState(0);
    // eslint-disable-next-line
    const [emergencyValue8, setEmergencyValue8] = useState(0);
    // eslint-disable-next-line
    const [emergencyValue9, setEmergencyValue9] = useState(0);
    // eslint-disable-next-line
    const [emergencyValue10, setEmergencyValue10] = useState(0);

    const handleChange = (e) => {

        if (e.target.name === 'investedArr.0.invested') {
            setEmergencyValue0(formatAmount(e.target.value.replace(/,/g, "")));
        } else if (e.target.name === 'investedArr.1.invested') {
            setEmergencyValue1(formatAmount(e.target.value.replace(/,/g, "")));
        } else if (e.target.name === 'investedArr.2.invested') {
            setEmergencyValue2(formatAmount(e.target.value.replace(/,/g, "")));
        } else if (e.target.name === 'investedArr.3.invested') {
            setEmergencyValue3(formatAmount(e.target.value.replace(/,/g, "")));
        } else if (e.target.name === 'investedArr.0.invested') {
            setEmergencyValue4(formatAmount(e.target.value.replace(/,/g, "")));
        } else if (e.target.name === 'investedArr.0.invested') {
            setEmergencyValue5(formatAmount(e.target.value.replace(/,/g, "")));
        } else if (e.target.name === 'investedArr.0.invested') {
            setEmergencyValue6(formatAmount(e.target.value.replace(/,/g, "")));
        } else if (e.target.name === 'investedArr.0.invested') {
            setEmergencyValue7(formatAmount(e.target.value.replace(/,/g, "")));
        } else if (e.target.name === 'investedArr.0.invested') {
            setEmergencyValue8(formatAmount(e.target.value.replace(/,/g, "")));
        } else if (e.target.name === 'investedArr.0.invested') {
            setEmergencyValue9(formatAmount(e.target.value.replace(/,/g, "")));
        } else if (e.target.name === 'investedArr.0.invested') {
            setEmergencyValue10(formatAmount(e.target.value.replace(/,/g, "")));
        }

    };

    return (
        <Fragment>


            <div className="rightSide_menu_outer">
                <div className="rightSide_menu">
                    <div className="rightSide_menu_header">
                        <div>
                            <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
                        </div>
                        <div className="font14 fw500 color182">
                            Add Emergency Fund
                        </div>
                        <div>&nbsp;</div>
                    </div>
                    <div className="topBanner pl25">
                        <div className="font18 fw600 color182 mt50">{"Emergency Fund"}</div>
                        <div className="font14 fw500 color485">{sessionStorage.getItem('full_name')} family’s {"Emergency Fund"}</div>
                    </div>
                    <div className="pl20 pr20">
                        <div className="collapse_outer mt20 activeshow">
                            <div className="card card-body">
                                <div className="form_prnt">
                                    <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>


                                        {

                                            investArr.length > 0 && (
                                                <>
                                                    {
                                                        investArr.map((investment, i) => {

                                                            return (
                                                                <>


                                                                    <div className="form_prnt">
                                                                        <input type="hidden" name={`investedArr[${i}]invest_id`} {...register(`investedArr.${i}.invest_id`)} defaultValue={investment.invest_id} />
                                                                        <input type="hidden" name={`investedArr[${i}]profile_id`} {...register(`investedArr.${i}.profile_id`)} defaultValue={sessionStorage.getItem("profile_id")} />
                                       


                                                                        <div className="expandtab font12 color353 fw500">{investment.asset_type}</div>
                                                                        <div className="expandtab font12 color353 fw500">{investment.full_name !== "" ? investment.full_name : ''} <b>{inWords(investment.till_date)}</b></div>


                                                                        <div className="custome_form">

                                                                            <div className="form-row mt20">
                                                                                <div className="col-md-12 pr20">
                                                                                    <label htmlFor={'investedArrCurr' + i} className="font14 fw500">Current value</label>
                                                                                    <div className="input-group">

                                                                                        <input type="text" className="form-control font14 color212 fw500" id={'childName' + i} name={`investedArr[${i}]currentVal`} {...register(`investedArr.${i}.currentVal`, { required: 'Required.' })} defaultValue={formatAmount(investment.till_date)} readOnly />

                                                                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                                                                    </div>
                                                                                    <span className="text-danger"> {errors.investedArr?.[i]?.currentVal?.message} </span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="form-row mt20">
                                                                                <div className="col-md-12 pr20">
                                                                                    <label htmlFor={'investedArrInvest' + i} className="font14 fw500">Enter Amount to Map Emergency Fund</label>
                                                                                    <div className="input-group">
                                                                                        <input type="text" className="form-control font14 color212 fw500" id={'investedArrInvest' + i} name={`investedArr[${i}]invested`} {...register(`investedArr.${i}.invested`, { required: 'Required.' })} onChange={(e) => handleChange(e)} value={
                                                                                            i === 0 ?
                                                                                                emergencyValue0 :
                                                                                                i === 1 ?
                                                                                                    emergencyValue1 :
                                                                                                    i === 2 ?
                                                                                                        emergencyValue2 :
                                                                                                        i === 3 ?
                                                                                                            emergencyValue3 :
                                                                                                            i === 4 ?
                                                                                                                emergencyValue4 :
                                                                                                                i === 5 ?
                                                                                                                    emergencyValue5 :
                                                                                                                    i === 6 ?
                                                                                                                        emergencyValue6 :
                                                                                                                        i === 7 ?
                                                                                                                            emergencyValue7 :
                                                                                                                            i === 8 ?
                                                                                                                                emergencyValue8 :
                                                                                                                                i === 9 ?
                                                                                                                                    emergencyValue9 :
                                                                                                                                    i === 10 ?
                                                                                                                                        emergencyValue10 : emergencyValue0
                                                                                        } />
                                                                                        <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                                                                    </div>
                                                                                    <span className="text-danger"> {errors.invested && errors.invested.message}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="formdevider mt25 mb25" />

                                                                </>
                                                            )
                                                        })
                                                    }
                                                </>
                                            )
                                        }

                                        {
                                            investArr.length > 0 ? "" : <><h1>No Funds to Map</h1></>
                                        }

                                        <div className="saveBtn backsaveBtn">
                                            <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                                            <button type={buttonSpinner ? "button" : "submit"} className="btn btn-primary">{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> Updating</> : "Save"} </button>
                                        </div>

                                    </form>
                                </div>
                            </div>

                        </div>


                    </div>

                </div>
            </div>



        </Fragment>
    )
}
