import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
//import SweetAlert from 'sweetalert2';
//import formatAmount from 'indian-currency-formatter';
import RangeSlider from 'react-bootstrap-range-slider';
import SweetAlert from 'sweetalert2'
import axioInstance from '../../axiosInstance';
import { Encryption } from '../../global';
export default function EditVacation({ setGoalType, goalId, planLatestStatus }) {

  const { register, handleSubmit, reset, formState: { errors, isDirty, isValid } } = useForm();

  const [buttonSpinner, setButtonSpinner] = useState(false);

  const [state, setState] = useState(false);
  const [goalData, setGoalData] = useState([]);


  const [requiredFor, setRequiredFor] = useState();
  const [requiredAfter, setRequiredAfter] = useState();

  const Back = () => {
    setGoalType('');
  }
  const [validate, setValidate] = useState(null);
  // eslint-disable-next-line
  const [max, setMax] = useState(30);
  useEffect(() => {

    setState(true);
    const profile_id = sessionStorage.getItem('profile_id');
    if (profile_id !== null && state !== false) {
      axioInstance.post(`goals/goalbyid`, { "profile_id": profile_id, "goal_id": goalId }).then(
        (response, data) => {


          setGoalData(response.data);
          let gData = JSON.parse(response.data.goal_data);
          

          const moneyDigit = gData[0].toString().length >= 8 ? 10000000 : 100000;


          setValidate({ goal_id: response.data.goal_id, plan_for: response.data.plan_for, profile_id: response.data.profile_id, profile_age: response.data.profile_age, amount: gData[0] / moneyDigit, moneyDigits: moneyDigit, requiredFor:(parseInt(response.data.required_till+1)-parseInt(response.data.required_after-1)), requiredAfter: parseInt(response.data.required_after), goal_name:response.data.goal_name, aspire: response.data.aspire});

          setRequiredFor((parseInt(response.data.required_till+1)-parseInt(response.data.required_after-1)));
          setRequiredAfter(parseInt(response.data.required_after))
          setMax(85 - parseInt(response.data.current_age));
        });
    }
  }, [state, goalId]);

  // effect runs when user state is updated
  useEffect(() => {
    // reset form with user data
    reset(validate);
    // eslint-disable-next-line
  }, [validate]);




  const onSubmit = data => {

  setButtonSpinner(true);

    const age = data.profile_age;
    const required_after = requiredAfter < 1 ? 1 :requiredAfter;
    const required_till = parseInt(required_after) + parseInt(requiredFor);

    const PostData = {
      "goal_type": "Vacation",
      "aspire": data.aspire,
      "goal_name":data.goal_name,
      "profile_id": sessionStorage.getItem('profile_id'),
      "admin_id":sessionStorage.getItem("admin_id"),
      "goal_id": data.goal_id,
      "plan_for": {
        "profile_details_id": Encryption(data.plan_for),
        "current_age": age < 1 ? 1 : age,
      },
      "yearly_expense": {
        "0": data.amount*data.moneyDigits,
        "1": 0
      },
      "no_years": {
        "0": requiredFor,
        "1": 0
      },
      "goal_params": {
        "age": age < 1 ? 1 : age,
        "post_inflation": 7,
        "post_return": 7,
        "goal_inflation": 7,
        "required_after": required_after,
        "required_till": required_till
      },
      "risk_profile": {
        "available": false,
        "pre_return": 8,
        "risk_profile_id": sessionStorage.getItem('profile_id')
      },
      "assumptions": {
        "assumption_id": false,
        "inflation": 7.5,
        "income_grow": "10"
      },
      "total_abcd": [{
        "amount": data.amount*data.moneyDigits,
        "frequency": 12,
        "no_of_times": 1,
        "gap": 0
      }],
      "actual": [{
        "actual_y": 0,
        "frequency": 1,
        "actual_ls": 1,
        "grow": "0"

      }]
    };

    axioInstance.post(`goals/add`, PostData)
      .then(function (response) {
        setGoalType('');

        const profile_id = sessionStorage.getItem('profile_id');
        const gpostData = { profile_id: profile_id };
        axioInstance.post(`goals/reset`, gpostData)
          .then(function (response) {
            axioInstance.post(`profile/summary-update`, { profile_id: profile_id }).then(
              (response, data) => {
                window.location.reload();
              }
            );
          })

      });

  }


  const onChangeFor = (e) => {
    console.log(e.currentTarget.value)
    setRequiredFor(e.currentTarget.value);
  };

  const onChangeAfter = (e) => {
    console.log(e.currentTarget.value)
    setRequiredAfter(e.currentTarget.value);
  };

  const DeleteGoal = (id) => {


    SweetAlert.fire({
       title: "Are you sure?",
       text: "you want to delete this goal?",
       type: "warning",
       showCancelButton: true,
       confirmButtonColor: "#09a635",
       confirmButtonText: "Yes",
       cancelButtonText: "Cancel",
       closeOnConfirm: false,
       closeOnCancel: true
    })
       .then((result) => {
          if (result.isConfirmed) {


             axioInstance.post(`goals/delete`, { profile_id: sessionStorage.getItem('profile_id'), 'goal_id': id }).then(
                function (response) {

                   axioInstance.post(`profile/summary-update`, { profile_id: sessionStorage.getItem("profile_id") }).then(
                      (response, data) => {
                         SweetAlert.fire(
                            'Goals',
                            'Successfully Deleted.',
                            'success'
                         ).then(function () {
                            window.location.href = "/dashboard";
                         });
                      }
                   );

                }
             )
          }
       });
 }

  return (
    <Fragment>
      <div className="rightSide_menu_outer">
        <div className="rightSide_menu">

          <div className="rightSide_menu_header">
            <div>
              <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => DeleteGoal(goalData.goal_id)}>Delete</button>
            </div>
            <div className="font14 fw500 color182">
              Edit {goalData.goal_name} Goal
            </div>
            <div>&nbsp;</div>
          </div>
          <div className="topBanner topBanner_otherGoals pl25">
          <img src='/images/rupeee.png' alt='img' className='sidebarRupeeeIcon'/>
            <div className="font18 fw600 color182">{goalData.goal_name}</div>
            <div className="font14 fw500 color485">{goalData.goal_name} plan in your financial journey</div>
          </div>

          <div className="form_prnt p20">
            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

            <div className="expandtab font12 color353 fw500">{goalData.full_name + ' ' + goalData.goal_name}</div>
            <br />
              <small className='text-danger'>{planLatestStatus === 7 && 'Plan Freezed'}</small>
              <input type={"hidden"} name="plan_for" {...register("plan_for")} />
              <input type={"hidden"} name="profile_id" {...register("profile_id")} />
              <input type={"hidden"} name="profile_age" {...register("profile_age")} />
              <input type={"hidden"} name="goal_id" {...register("goal_id")} />
              <input type={"hidden"} name="aspire" {...register("aspire")} />
              <input type={"hidden"} name="goal_name" {...register("goal_name")} />


              <div className="form-row">
                <div className="col-md-12 pr20">
                  <label htmlFor="inputEmail4" className="font14 fw500">Required Amount as of Today</label>
                  <div className="input-group selectarrow">
                    <input type="text" className="form-control font14 color212 fw500" name="amount" {...register("amount", { required: 'Cost of house today required' })} />
                    <select className="custom-select font14" id="inputGroupSelect01" name='moneyDigits' {...register("moneyDigits")}>
                      <option selected value="100000">Lakhs</option>
                      <option value="10000000">Crore</option>
                    </select>
                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                  </div>
                  <span className="text-danger"> {errors.amount && errors.amount.message}</span>
                </div>
              </div>

              <div className="devider_line mt25 mb25" />

              <div className="rangesliderbox">
                <div className="rangesliderbox_title">
                  <div>
                    <div className="font14 fw500 color182">Required After</div>
                    <div className="font12 fw500 color8c9">Adjust the estimate year to achive this goal</div>
                  </div>
                  <div className="tragetvalue font13 fw700">{requiredAfter}</div>
                </div>
                <div className="rangebox_bar mt12">
                  <div className="form-group">


                    <RangeSlider
                      value={requiredAfter}
                      onChange={onChangeAfter}
                      step={1}
                      min={1}
                      max={25}
                      className={"form-control-range"}
                      tooltip={"off"}
                      defaultValue={1}
                    />


                  </div>
                </div>
                <div className="flex_center justify_center mt8">
                  <div className="font12 fw500 color9ba">1</div>
                  <div className="font12 fw500 color9ba">25</div>
                </div>
              </div>


              <div className="devider_line mt25 mb25" />

              <div className="rangesliderbox">
                <div className="rangesliderbox_title">
                  <div>
                    <div className="font14 fw500 color182">Required For</div>
                    <div className="font12 fw500 color8c9">Adjust the estimate year to achive this goal</div>
                  </div>
                  <div className="tragetvalue font13 fw700">{requiredFor}</div>
                </div>
                <div className="rangebox_bar mt12">
                  <div className="form-group">


                    <RangeSlider
                      value={requiredFor}
                      onChange={onChangeFor}
                      step={1}
                      min={1}
                      max={25}
                      className={"form-control-range"}
                      tooltip={"off"}
                      defaultValue={1}
                    />


                  </div>
                </div>
                <div className="flex_center justify_center mt8">
                  <div className="font12 fw500 color9ba">1</div>
                  <div className="font12 fw500 color9ba">25</div>
                </div>
              </div>

              <div className="saveBtn backsaveBtn">
                <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                {planLatestStatus !== 7 && <button type={buttonSpinner ? "button" : "submit"} className="btn btn-primary" disabled={!isDirty && !isValid}>{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> Updating</> : "Save"} </button>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  )
}