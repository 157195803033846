import React, { Fragment, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'
import axioInstance from '../axiosInstance';
import {  ageCalculate } from '../global';
import SideImg from '../../img/BasicProfile.png';
import moment from 'moment';
import { Encryption } from '../global';
export default function BasicDetailsScreen2({ setSubstep, substep, nextsubStep, childCount, setChildCount, lifeStage, setLifeStage }) {

  const [childShow, setChildShow] = useState(1);

  const decrementChild = () => {
    if (childCount === 1) return;
    setChildCount((childCount) => childCount - 1);
  };

  const incrementChild = () => {
    if (childCount === 4) return;
    setChildCount((childCount) => childCount + 1);
  };

  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();

  const isChecked = watch("lifeStage");

  const [user, setUser] = useState(null);

  const [buttonSpinner, setButtonSpinner] = useState(false);

  useEffect(() => {
    const profile_id = sessionStorage.getItem('profile_id');
    if (profile_id !== null) {
      axioInstance.post(`profile-details/getcreateprofile`, {profile_id:profile_id}).then(
        (response, data) => {
          if (response.data.length > 0) {
            sessionStorage.setItem('CreateProfile', JSON.stringify(response.data[0]));

            if (response.data[0].lifeStage === "marriedWithKids") {
              setChildShow(4);
            }

            console.log(response.data[0].pan);
            setUser({ emailAddress: response.data[0].email, fullName: response.data[0].first_name + ' ' + (response.data[0].middle_name !== null ? response.data[0].middle_name : '') + ' ' + response.data[0].last_name, mobileNumber: response.data[0].phone, birthDate: response.data[0].dob, panNumber: response.data[0].pan, lifeStage: response.data[0].lifeStage });

            sessionStorage.setItem('profile_details_id', Encryption(response.data[0].profile_details_id));

          }
        });





    } else {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = "/sign-in";
    }

  }, [setChildCount]);

  // effect runs when user state is updated
  useEffect(() => {
    // reset form with user data
    reset(user);
    // eslint-disable-next-line
  }, [user]);

  const onSubmit = data => {

    setButtonSpinner(true);

    sessionStorage.setItem("BasicDetailsScreen1", JSON.stringify(data));

    setLifeStage(data.lifeStage);
    const PostData = {
      "life_stage": "single",
      "gender": 'na',
      "dob": data.birthDate,
      "pan": data.panNumber,
      "profile_id": sessionStorage.getItem('profile_id'),
      "profile_details_id": sessionStorage.getItem("profile_details_id"),
      "source": 1,
      "step": 2,
      "dob_flag": 2
    };


    axioInstance.post(`add-basic-profile`, PostData)
      .then(function (response) {

        sessionStorage.setItem('self_dob', data.birthDate);

        const PostData = {
          "life_stage": data.lifeStage,
          "profile_id": sessionStorage.getItem('profile_id'),
          "profile_details_id": sessionStorage.getItem("profile_details_id"),
          "step": 2
        }

        axioInstance.post(`set-life-stage`, PostData)
          .then(function (response) {



            const profile_id = sessionStorage.getItem('profile_id');

            var profile_age = ageCalculate(data.birthDate);

            if (profile_age >= 65) {

              const postData = { profile_id: profile_id, assumption: { retire_age: parseInt(profile_age) + 5 } };

              axioInstance.post(`profile-assumptions/update_assumption`, postData);

            }
            sessionStorage.setItem('life_stage', data.lifeStage);
            setSubstep(nextsubStep(substep, 2));
            setButtonSpinner(false);
          })
          .catch(function (error) {

            SweetAlert.fire({
              toast: true,
              icon: 'error',
              title: 'Failed. Something went wrong. Retry again',
              animation: false,
              position: 'top-right',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
              }
            });

          });


      })
      .catch(function (error) {
        SweetAlert.fire({
          toast: true,
          icon: 'error',
          title: error,
          animation: false,
          position: 'top-right',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', SweetAlert.stopTimer)
            toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
          }
        });
      });


    return;
  }


  const [panNumber, setPanNumber] = useState('');

  const handleInput = (event) => {
    event.preventDefault();
    setPanNumber(event.target.value);

  }

  const changeCase = (event) => {
    event.preventDefault();
    setPanNumber(event.target.value.toUpperCase());
  }

  const [myAge, setMyAge] = useState();
  const [displayAge, setDisplayAge] = useState('');
  // eslint-disable-next-line
  const selfAge = (e) => {
    setDisplayAge(ageCalculate(e.target.value) > 64 ? 'Age is greater than 64. Do you want to continue' :  (ageCalculate(e.target.value) < 64 ? ageCalculate(e.target.value) + ' years' : ''));
    setMyAge(ageCalculate(e.target.value));
  }

  return (
    <Fragment>

      <div className="mainwrapper">

        <div className="form_title">
          <div className="mt30 font20 fw600">First things first!</div>
          <div className="font15 color495">Questions to know basic details</div>
        </div>


        <div className="row formWpr mt25">
          <div className="col-lg-8 formouter">
            <ul className="form_swipe_tab font12 fw600">
              <li className="act">1</li>
              <li>2</li>
            </ul>

            <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>

              <div className="formtitle flex_center justify_center">
                <div>
                  <div className="font18 fw600">Knowing you!</div>
                  <div className="font15 color495 ">You, your age and your family</div>
                </div>
                <div>

                  <button type={buttonSpinner ? "button" : "submit"} className="bluebtn font13 fw600 d-none d-md-block" disabled={!isChecked}>{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Save"}
                  
                    <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                    </svg></span></button>
                </div>
              </div>

              {/* Form */}
              <div className="form_prnt pt25 pl35 pr35 pb35">
                <div className="custome_form">

                  <div className="form-row">

                    <div className="form-group col-md-6 pr20">
                      <label htmlFor="fullName" className="font14 fw600">Your full name <span className="colorfa8">*</span></label>
                      <input type="text" className="form-control" id="fullName" name="fullName" autoComplete='off' {...register("fullName", { required: 'Name is required', minLength: { value: 3, message: 'Name is too short' }, maxLength: 'Name is too long' })} placeholder="Enter full name" />
                      <span className="text-danger"> {errors.fullName && errors.fullName.message}</span>
                    </div>

                    <div className="form-group col-md-6 pl20">
                      <label htmlFor="birthDate" className="font14 fw600">Date of birth <span className="colorfa8">*</span></label>
                      <input type="date" maxLength="4" pattern="[1-9][0-9]{3}" className="form-control" id="birthDate" name="birthDate" autoComplete='off' {...register("birthDate", { required: 'DOB is required', max: { value: moment().subtract(18, 'years').calendar(), message: 'Age must be between 18 to 90' }, min: { value: moment().subtract(79, 'years').calendar(), message: 'Age is greater than 80. Currently we have enabled planning for up to age 79 years' } })} placeholder="Select the date" onBlurCapture={selfAge} />
                      <span className="text-danger font11"> {errors.birthDate && errors.birthDate.message}</span>
                      <span className={myAge > 64 ? 'text-danger font11 font-weight-bold' :'text-success font11 font-weight-bold'}>{errors.birthDate && errors.birthDate.message ? '' : displayAge}</span>
                    </div>

                  </div>

                  <div className="form-row">

                  </div>





                  <div className="form-row">
                    <div className="form-group col-md-6 pr20">
                      <label htmlFor="panNumber" className="font14 fw600">PAN number <span className="colorfa8">*</span></label>
                      <input type="text" className="form-control" id="panNumber" name="panNumber" autoComplete='off' {...register("panNumber", { required: 'PAN is required', pattern: { value: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, message: 'Invalid PAN' } })} placeholder="Enter PAN number" onMouseEnter={changeCase} onKeyUp={changeCase} onChange={handleInput} value={panNumber} maxLength={10} minLength={10} />
                      <span className="text-danger"> {errors.panNumber && errors.panNumber.message}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="formdevider" />

              <div className="p35">
                <div>
                  <div className="font14 color212 fw600">Select your life stage among these <span className="colorfa8">*</span></div>
                  <ul className="steps_tag font14 fw500 color182 mt15">
                    <li>
                      <label>
                        <input type="radio" name="lifeStage" {...register("lifeStage", { required: 'Life Stage is required' })} value="single" onClick={() => setChildShow(1)} />
                        <span className="rdo_text_box">
                          Single
                        </span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type="radio" name="lifeStage" {...register("lifeStage", { required: 'Life Stage is required' })} value="marriedWithKids" onClick={() => { setChildShow(4); setChildCount(1); }} />
                        <span className="rdo_text_box">
                          Married with kids
                        </span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type="radio" name="lifeStage" {...register("lifeStage", { required: 'Life Stage is required' })} value="married" onClick={() => {
                          setChildShow(3);
                          setChildCount(0);
                        }} />
                        <span className="rdo_text_box">
                          Married without kids
                        </span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type="radio" name="lifeStage" {...register("lifeStage", { required: 'Life Stage is required' })} value="separatedWithKids" onClick={() => { setChildShow(4); setChildCount(1); }} />
                        <span className="rdo_text_box">
                          Single parent with kids
                        </span>
                      </label>
                    </li>
                  </ul>
                  <span className="text-danger"> {errors.lifeStage && errors.lifeStage.message}</span>
                </div>

                {
                  (childShow === 2 || childShow === 4) &&
                  (<>
                    <div className="mt35">
                      <div className="font14 color212 fw600">Number of children in the family <span className="colorfa8">*</span></div>
                      <div className="mt15 quantity_wpr">
                        <button onClick={() => decrementChild()} type="button">-</button>
                        <input type="text" className="font14 fw500 ml15 mr15" value={childCount} name='childCount' {...register("childCount", { required: 'Number of Children is required' })} />
                        <button onClick={() => incrementChild()} type="button" className='greenBtn'>+</button>
                      </div><br />
                      <span className="text-danger"> {errors.childCount && errors.childCount.message}</span>
                    </div>
                  </>)
                }


              </div>

              <div className="saveBtn d-block d-sm-none">
                <button type={buttonSpinner ? "button" : "submit"} disabled={!isChecked} className="btn btn-primary"> {buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Save and Continue"} <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10"></path></svg></span></button>
              </div>

            </form>
            {/* Form End */}

          </div>

          <div className="col-lg-4 qouteouter d-none d-md-block">
            <div className="qoutetext font18 fw500 color212 pl45 pr45">
              <div className="qoutesign">
                &#8220;
              </div>
              {"Don't worry about enclosing PAN details to finnovate. Your details will be secure and encrypted."}
            </div>
            <div className="qouteimg"><img src={SideImg} alt='Completed' /></div>
          </div>
        </div>



      </div>
    </Fragment>
  )
}
