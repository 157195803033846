import React, { useEffect, useState } from 'react'
import axioInstance from '../axiosInstance';
import {  inWords, inFrequency } from '../global';
import formatAmount from 'indian-currency-formatter';

export default function HealthAnalysis() {

    const [health, setHealth] = useState([]);

    const [healthGoal, setHealthGoal] = useState([]);

    const [minSuggest, setMinSuggest] = useState(0);
    const [maxSuggest, setMaxSuggest] = useState(0);

    useEffect(() => {
        const profile_id = sessionStorage.getItem('profile_id');
        axioInstance.post(`profile/summary`, {profile_id:profile_id}).then(
            (response, data) => {
                setHealth(response.data.risk_inv);

                console.log('response.data.risk_goals', response.data.risk_goals);

                let health_goal = response.data.risk_goals.find(product => (product.goal_type === "Health Insurance" && product.relation === "self"));

                console.log(health_goal);

                setHealthGoal(health_goal ? health_goal : []);

                setMinSuggest(health_goal ? health_goal.min_suggested : 0);
                setMaxSuggest(health_goal ? health_goal.max_suggested : 0);

            });
    }, []);

    return (
        <>
            <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Health Insurance Analysis</span></div>

            <div className="font22 fw700 color0C4 pt30">Health Insurance Analysis</div>

            <div className="font14 fw500 color182 mt20">Your health insurance is a financial cover that you have in case on any eventuality related to your life. It your family with a financial security for their living expenses, goals, any liabilities etc.</div>

            <div className="font18 fw500 color mt25">Health Insurance Plan For :<b>{healthGoal.full_name}</b></div>

            <div className="objectivenote mt25">
                <div className="font14 fw600 color1e1">Objective :</div>
                <div className="font14 fw500 color384">To cover the medical expense in case on any eventuality</div>
            </div>

            <div className="analysisnote mt8">
                <div className="font14 fw600 color1e1">Objective :</div>
                <ul className="analysislist font14 fw500">
                    <li>The additional health Insurance Cover required is in the range of Rs {minSuggest > 0 ? formatAmount(minSuggest) : '0'} to Rs {maxSuggest > 0 ? formatAmount(maxSuggest) : '0'}</li>
                </ul>
            </div>

            <div className="lifecoveredbox_outer mt25">
                <div className="lifecoveredbox">
                    <div className="font14 fw500 color263">Your Existing Health Cover</div>
                    <div className="flex_center justify_center mt20">
                        <div>
                            <div className="font13 fw500 color626">Current</div>
                            <div className="fw500 font16 color182">{healthGoal.existing_cover > 0 ? inWords(healthGoal.existing_cover) : '00'}</div>
                        </div>

                    </div>
                </div>
                <div className="lifecoveredbox">
                    <div className="font14 fw500 color263">Health Covered Required</div>
                    <div className="flex_center justify_center mt20">
                        <div>
                            <div className="font13 fw500 color626">Maximum</div>
                            <div className="fw500 font16 color007">{inWords(healthGoal.max_suggested)}</div>
                        </div>
                        <div>
                            <div className="font13 fw500 color626">Minimum</div>
                            <div className="fw500 font16 color007">{inWords(healthGoal.min_suggested)}</div>
                        </div>
                    </div>
                </div>
                <div className="lifecoveredbox">
                    <div className="font14 fw500 color263">Additional Cover Required</div>
                    <div className="flex_center justify_center mt20">
                        <div>
                            <div className="font13 fw500 color626">Maximum</div>
                            <div className="fw500 font16 coloreb4">{(healthGoal.max_suggested - healthGoal.existing_cover) > 0 ? inWords(healthGoal.max_suggested - healthGoal.existing_cover) : '00'}</div>
                        </div>
                        <div>
                            <div className="font13 fw500 color626">Minimum</div>
                            <div className="fw500 font16 coloreb4">{(healthGoal.min_suggested - healthGoal.existing_cover) > 0 ? inWords(healthGoal.min_suggested - healthGoal.existing_cover) : '00'}</div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="lineDevider mt40 mb40" />



            <div className="font18 fw600 color182">Details of your Existing Non-Life Insurance</div>


            {
                health.map((data, index) => {
                    return (
                        <>
                            {
                                (data.type === 9) && (
                                    <>

                                        <div className="loanBox_outer mt20 p25">
                                            <div className="loandtl">
                                                <div className="font16 fw600 color263">{data.first_name}</div>
                                                <div className="font11 fw500 color7b8">Policy Holder</div>
                                            </div>
                                            <div className="loandtl">
                                                <div className="font12 fw500 color6d7">Sum Insured</div>
                                                <div className="font16 fw500 color162">{inWords(data.current_value)}</div>
                                            </div>
                                            <div className="loandtl">
                                                <div className="font12 fw500 color6d7">Premium Amount</div>
                                                <div className="font16 fw500 color162">{inWords(data.invest_amount)}</div>
                                            </div>
                                            <div className="loandtl">
                                                <div className="font12 fw500 color6d7">Premium Frequency</div>
                                                <div className="font16 fw500 color162">{inFrequency(data.frequency)}</div>
                                            </div>
                                            <div className="loandtl">
                                                <div className="font12 fw500 color6d7">Available for</div>
                                                <div className="font16 fw500 color162">{data.insuredName}</div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </>
                    )
                })
            }

            <div className="suggestnote mt20">
                <div className="font16 fw600 color182">Suggestions</div>
                <ul className="suggestlist color182 font14 fw500">
                    <li>If you are covered under a Group Mediclaim Policy from your employer, you should consider having an independent family floater policy cover.</li>
                </ul>
                <div className="font16 fw600 color182 mt25">Critical Illness Insurance</div>
                <ul className="suggestlist color182 font14 fw500">
                    <li>Today’s changing lifestyle has put us at greater risk; and as much as we would want to ignore it, Critical Illnesses have become more commonplace. Which is why, its important to have a comprehensive cover for as many as Critical Illnesses to take care and offer total peace of mind for you and your family.</li>
                </ul>
                <div className="font16 fw600 color182 mt25">Personal Accidental Insurance</div>
                <ul className="suggestlist color182 font14 fw500">
                    <li>Personal Accident insurance covers not only major accident resulting in death or permanent disablement but also serious injury that can have impact on your life, earnings, expenses etc. It pays lump sum compensation for any accidental death or permanent disability as a result of an accident happening anywhere in the world.</li>
                </ul>
            </div>

        </>
    )
}
