import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2';
import axioInstance from '../axiosInstance';
import SideImg from '../../img/Protection.png';
import formatAmount from 'indian-currency-formatter';

export default function CashflowScreen4({ setSubstep, substep, nextsubStep, StepChange, steps, nextStep, prevsubStep, clientName }) {

  const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();

  const [buttonSpinner, setButtonSpinner] = useState(false);

  // eslint-disable-next-line
  const [loans, setLoans] = useState([{
    "id": 1,
    "loanName": "Home Loan",
    "subType": "1",
    checked: true
  }, {
    "id": 2,
    "loanName": "Car Loan",
    "subType": "2",
    checked: true
  }, {
    "id": 3,
    "loanName": "Personal Loan",
    "subType": "3",
    checked: true
  }, {
    "id": 4,
    "loanName": "Other Loan",
    "subType": "6",
    checked: true
  }]);

  const [homeLoan, setHomeLoan] = useState();
  const [carLoan, setCarLoan] = useState();
  const [personalLoan, setPersonalLoan] = useState();
  const [otherLoan, setOtherLoan] = useState();

  const handleChange = (e) => {
    console.log('Name : ',e.target.name);

    const { value } = e.target;

    if (e.target.name === 'loanArr.0.Emi') {
      setHomeLoan(formatAmount(value.replace(/,/g, "")));
    } else if (e.target.name === 'loanArr.1.Emi') {
      setCarLoan(formatAmount(value.replace(/,/g, "")));
    } else if (e.target.name === 'loanArr.2.Emi') {
      setPersonalLoan(formatAmount(value.replace(/,/g, "")));
    } else if (e.target.name === 'loanArr.3.Emi') {
      setOtherLoan(formatAmount(value.replace(/,/g, "")));
    }

  };


  /*const [loanCount, setLoanCount] = useState(1);

  const decrement = () => {
    if (loanCount === 1) return;
    setLoanCount((loanCount) => loanCount - 1);
  };

  const increment = () => {
    if (loanCount === 4) return;
    setLoanCount((loanCount) => loanCount + 1);
  };*/

  const skipStep = () => {

    axioInstance.post(`profile/update-stepper`, { profile_id: sessionStorage.getItem('profile_id'), 'admin_id':sessionStorage.getItem('admin_id'), step: steps + 1 }).then(function (response, data) {
      StepChange(nextStep(steps));
    });

  }

  const onSubmit = data => {


    setButtonSpinner(true);
    

    const profileId = sessionStorage.getItem('profile_id');


    data.loanArr.forEach((goal, index) => {
      
      if (goal.InstrName !== null && goal.Amount !== null && goal.Emi !== null && goal.Outstanding) {

        const Postdata = {
          "profile_id": profileId,
          "profile_details_id": sessionStorage.getItem('profile_details_id'),
          "co_name": profileId,
          "owner": profileId,
          "instr_name": goal.InstrName,
          "type": 20,
          "sub_type": goal.subType,
          "loan_amount": goal.Amount * goal.AmountType,
          "outstanding_loan": goal.Outstanding * goal.OutstandingType,
          "emi": goal.Emi.replace(/,/g, "")
        };
  
        axioInstance.post(`prof-liability`, Postdata)
          .then(function (response) {
          })
          .catch(function (error) {
            console.log(error);
            SweetAlert.fire(
              'Loan',
              'Failed. Something went wrong. Retry again',
              'error'
            )
          });


      }
      

    });



    axioInstance.post(`profile/update-stepper`, { profile_id: sessionStorage.getItem('profile_id'), 'admin_id':sessionStorage.getItem('admin_id'), step: steps + 1 }).then(function (response, data) {
      setButtonSpinner(false);
      StepChange(nextStep(steps));
    });


  }

  return (
    <Fragment>

      <div className="mainwrapper">
        <div className="form_title">
          <div className="mt30 font20 fw600">{clientName} Cashflow</div>
          <div className="font15 color495">we need to map a plan to match your goals. so we need your income infos</div>
        </div>

        <div className="row formWpr mt25">
          <div className="col-lg-8 formouter">
            <ul className="form_swipe_tab font12 fw600">
              <li>1</li>
              <li>2</li>
              <li onClick={() => setSubstep(prevsubStep(substep))}>3</li>
              <li className="act">4</li>
            </ul>

            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

              <div className="formtitle flex_center justify_center">
                <div>
                  <div className="font20 fw600">Loans</div>
                  <div className="font15 color495 ">Also a bit approximate idea about your commitments</div>
                </div>
                <div>

                  <button type={buttonSpinner ? "button" : "submit"} className="bluebtn font13 fw600 d-none d-md-block" disabled={!isDirty && !isValid}>{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Save"}
                    <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                    </svg></span></button>
                </div>
              </div>

              {/* Form */}

              <div className="p35">

                <div className="form-row">


                  <div className="mt25">
                    {/*<div className="font16 color212 fw600">Number of active loans</div>
                    <div className="mt15 quantity_wpr">
                      <button onClick={() => decrement()}>-</button>
                      <input type="text" className="font14 fw500 ml15 mr15" value={loanCount} />
                      <button onClick={() => increment()}>+</button>
                    </div>*/}

                    {
                      loans.map((e, i) => <>
                        <div className="flex_center justify_center">
                          <div className="entergoalname_wpr widthauto">
                            <div className="entergoalname">
                              <form className="custome_form searchinput">
                                <div className="form-row">
                                  <div className="form-group m0">
                                    <input type="text" className="form-control font14 color212 fw500" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Loan name" name={`loanArr.${i}.InstrName`} defaultValue={e.loanName} {...register(`loanArr.${i}.InstrName`, { required: 'Loan Name is required' })} />
                                    <span className="text-danger"> {errors.loanArr?.[i]?.InstrName?.message}</span>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>

                        <input type="hidden" className="form-control font14 color212 fw500" name={`loanArr.${i}.subType`} {...register(`loanArr.${i}.subType`)} defaultValue={e.subType} />

                        <div className="form_prnt mt20">
                          <div className="form-row justify-content-between">
                            <div className="col-md-4 pr20">
                              <label htmlFor="inputEmail4" className="font14 fw600">Total loan </label>
                              <div className="input-group selectarrow select50">
                                <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" placeholder="Enter" name={`loanArr.${i}.Amount`} {...register(`loanArr.${i}.Amount`)} maxLength={3} minLength={1} />
                                <select className="custom-select" id="inputGroupSelect01" name={`loanArr.${i}.AmountType`} {...register(`loanArr.${i}.AmountType`)}>
                                  <option selected value="100000">Lakhs</option>
                                  <option value="10000000">Crore</option>
                                </select>
                                <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                              </div>
                            </div>
                            <div className="col-md-4 pl20">
                              <label htmlFor="inputEmail4" className="font14 fw600">EMI </label>
                              <div className="input-group inputround">
                                <input type="text" className="form-control font14 color212 fw500" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter" name={`loanArr.${i}.Emi`} {...register(`loanArr.${i}.Emi`)} value={e.id === 1 ? homeLoan : (e.id === 2 ? carLoan : (e.id === 3 ? personalLoan : e.id === 4 ? otherLoan : ''))} maxLength={10} minLength={1} onChange={handleChange}></input>
                                <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                              </div>
                            </div>
                            <div className="col-md-4 pl20">
                              <label htmlFor="inputEmail4" className="font14 fw600">Remaining loan </label>
                              <div className="input-group selectarrow select50">
                                <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" placeholder="Enter" name={`loanArr.${i}.Outstanding`} {...register(`loanArr.${i}.Outstanding`)} maxLength={3} minLength={1} />
                                <select className="custom-select" id="inputGroupSelect01" name={`loanArr.${i}.OutstandingType`} {...register(`loanArr.${i}.OutstandingType`)}>
                                  <option selected value="100000">Lakhs</option>
                                  <option value="10000000">Crore</option>
                                </select>
                                <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {e.id <= 3 ? <div className="formdevider mt35 mb35" /> : ""}

                      </>)
                    }
                  </div>
                </div>
              </div>

              <div className="saveBtn backsaveBtn d-block d-sm-none">
                <button type="button" className="btn btn-primary" onClick={() => setSubstep(prevsubStep(substep))}> Back </button>
                <button type={buttonSpinner ? "button" : "submit"} disabled={!isDirty && !isValid} className="btn btn-primary"> {buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Save"} </button>
              </div>

            </form>

            {/* Form End */}

            <div className='pr-5'>


              <div className="mob_left_right mb5" style={{ 'text-align': 'center' }}>
                <button type="button" className="btn bluebtn backtowizardBtn font13 fw600" onClick={() => skipStep()}>Skip Loans</button>
              </div>

            </div>

          </div>

          <div className="col-lg-4 qouteouter d-none d-md-block">
            <div className="qoutetext font18 fw500 color212 pl45 pr45">
              <div className="qoutesign">
                &#8220;
              </div>
              If you have other loan, please click add loan button and add them.
            </div>


            <div className="qouteimg" ><img src={SideImg} alt='Protection' /></div>
          </div>
        </div>

      </div>
    </Fragment>
  )
}
