import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'
import SideImg from '../../img/BasicProfile.png';
import axioInstance from '../axiosInstance';

export default function BasicDetailsScreen2({ setSubstep, substep, nextsubStep, StepChange, steps, nextStep, prevsubStep, childCount, lifeStage }) {

  console.log('screen 2 lifeStage : ' + lifeStage);
  const calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    console.log(age_now);
    return age_now;
  }

  const { register, handleSubmit, reset, formState: { errors, isDirty, isValid } } = useForm();
  const [family, setFamily] = useState(null);

  const [spouseName, setSpouseName] = useState();
  const [spouseAge, setSpouseAge] = useState();
  const [motherName, setMotherName] = useState();
  const [motherAge, setMotherAge] = useState();
  const [fatherName, setFatherName] = useState();
  const [fatherAge, setFatherAge] = useState();

  const [spouseId, setSpouseId] = useState();
  const [motherId, setMotherId] = useState();
  const [fatherId, setFatherId] = useState();

  const [kidsDetails, setKidsDetails] = useState();

  const [action, setAction] = useState('add');

  const [buttonSpinner, setButtonSpinner] = useState(false);

  useEffect(() => {

    const profile_id = sessionStorage.getItem('profile_id');
    axioInstance.post(`profile/summary`, { profile_id: profile_id }).then(
      (response, data) => {
        if (response.data.status === 100) {

          const res = response.data;
          setKidsDetails(res.kids_det);
          setAction(res.child_cnt >= 1 ? 'edit' : 'add');
          res.prof_det.forEach(element => {
            if (element.relation === 'spouse') {
              setSpouseName(element.first_name + ' ' + element.last_name);
              setSpouseAge(calculate_age(element.dob));
              setSpouseId(element.profile_details_id);
            } else if (element.relation === 'mother') {
              setMotherName(element.first_name + ' ' + element.last_name);
              setMotherAge(calculate_age(element.dob));
              setMotherId(element.profile_details_id);
            } else if (element.relation === 'father') {
              setFatherName(element.first_name + ' ' + element.last_name);
              setFatherAge(calculate_age(element.dob));
              setFatherId(element.profile_details_id);
            }

          });

        }
      });




    setFamily({ spouseName: spouseName, spouseAge: spouseAge, motherName: motherName, motherAge: motherAge, fatherName: fatherName, fatherAge: fatherAge })
  }, [spouseName, spouseAge, motherName, motherAge, fatherName, fatherAge]);

  // effect runs when user state is updated
  useEffect(() => {
    // reset form with user data
    reset(family);
    // eslint-disable-next-line
  }, [family]);


  const onSubmit = data => {

    setButtonSpinner(true);

    sessionStorage.setItem("familyData", JSON.stringify(data));

    const postData = {
      spouseName: data.spouseName,
      spouseAge: data.spouseAge,
      motherName: data.motherName,
      motherAge: data.motherAge,
      fatherName: data.fatherName,
      fatherAge: data.fatherAge,
      father_prof_det_id: fatherId,
      mother_prof_det_id: motherId,
      spouse_prof_det_id: spouseId,
      childsArr: data.childsArr,
      profile_id: sessionStorage.getItem('profile_id')
    };

    axioInstance.post(`profile-details/addfamily-profdetails`, postData).then(
      function (response, data) {

        if (response.data.status === 100) {

          axioInstance.post(`profile/update-stepper`, { profile_id: sessionStorage.getItem('profile_id'), 'admin_id': sessionStorage.getItem('admin_id'), step: steps + 1 }).then(function (response, data) {
            setButtonSpinner(false);
            StepChange(nextStep(steps));
          });

        } else {
          SweetAlert.fire({
            toast: true,
            icon: 'error',
            title: 'Failed. Something went wrong. Retry again.',
            animation: false,
            position: 'top-right',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', SweetAlert.stopTimer)
              toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
            }
          })
        }

      }
    );
  }

  return (
    <Fragment>
      <div className="mainwrapper">
        <div className='form_title'>
          <div className="mt30 font20 fw600">First things first!</div>
          <div className="font15 color495">Questions to know basic details</div>
        </div>

        <div className="row formWpr mt25">
          <div className="col-lg-8 formouter">
            <ul className="form_swipe_tab font12 fw600">
              <li onClick={() => setSubstep(prevsubStep(substep))}>1</li>
              <li className="act">2</li>
            </ul>

            <form onSubmit={handleSubmit(onSubmit)}>

              <div className="formtitle flex_center justify_center">
                <div>
                  <div className="font18 fw600">Let's, talk about family</div>
                  <div className="font15 color495 ">Few details about your family members</div>
                </div>
                <div>

                  <button className="bluebtn font13 fw600 d-none d-md-block" type={buttonSpinner ? "button" : "submit"} disabled={!isDirty && !isValid}>{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Save"}
                    <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                    </svg></span></button>
                </div>
              </div>

              {/* Form */}
              <div className={(lifeStage === "single" || lifeStage === "married") ? "form_prnt pt25 pl35 pr35 pb35" : "form_prnt pt25 pl35 pr35 pb35"}>
                <div className="custome_form">

                  {
                    (lifeStage === "marriedWithKids" || lifeStage === "married") &&
                    (<Fragment>

                      <div className="form-row">
                        <div className="form-group col-md-6 pr20">
                          <label htmlFor="spouseName" className="font14 fw600">Your spouse name <span className="colorfa8">*</span></label>
                          <input type="text" className="form-control" id="spouseName" name="spouseName" autoComplete='off' {...register("spouseName", { required: 'Name is required', minLength: { value: 3, message: 'Name is too short' }, maxLength: 'Name is too long' })} placeholder="Enter Spouse Name" />
                          <span className="text-danger"> {errors.spouseName && errors.spouseName.message}</span>
                        </div>
                        <div className="form-group col-md-6 pl20">
                          <label htmlFor="spouseAge" className="font14 fw600">Age <span className="colorfa8">*</span></label>
                          <input type="text" className="form-control" id="spouseAge" name="spouseAge" autoComplete='off' {...register("spouseAge", { required: 'Age is required', min: { value: 18, message: 'Min age is 18' }, max: { value: 90, message: 'Max age is 90' } })} placeholder="Enter Spouse Age" />
                          <span className="text-danger"> {errors.spouseAge && errors.spouseAge.message}</span>
                        </div>
                      </div>


                    </Fragment>)
                  }


                  {(childCount > 0 && action === "edit") && (<Fragment>
                    {
                      Object.keys(kidsDetails).map((kids, i) => {

                        return (<>

                          {
                            kidsDetails[kids].relation === "child" ?
                              <>
                                <><div className="form-row" key={i}>
                                  <div className="form-group col-md-6 pr20">
                                    <label htmlFor={'childName' + i} className="font14 fw600">Child {i + 1} <span className="colorfa8">*</span></label>
                                    <input type="text" className="form-control" id={'childName' + i} name={`childsArr[${i}]name`} autoComplete='off' {...register(`childsArr.${i}.name`, { required: 'Name is required', minLength: { value: 3, message: 'Name is too short' }, maxLength: 'Name is too long' })} placeholder="Enter Child Name" defaultValue={kidsDetails[kids].full_name} />
                                    <span className="text-danger"> {errors.childsArr?.[i]?.name?.message}</span>
                                  </div>

                                  <div className="form-group col-md-6 pl20">
                                    <label htmlFor={'childAge' + i} className="font14 fw600">Age <span className="colorfa8">*</span></label>
                                    <div className="input-group selectarrow">
                                      <input type="text" className="form-control" id={'childAge' + i} name={`childsArr[${i}]age`} autoComplete='off' {...register(`childsArr.${i}.age`, { required: 'Age is required', min: { value: 0, message: 'Min value is 1' } })} placeholder="Enter Child Age" defaultValue={calculate_age(kidsDetails[kids].dob)} onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }} />
                                      <select
                                        className="custom-select font14" id="inputGroupSelect01" name={`childsArr[${i}]ageType`} {...register(`childsArr.${i}.ageType`)}>
                                        <option value="years">Year</option>
                                        <option value="months">Months</option>
                                        <option value="days">Days</option>
                                      </select></div>
                                    <span className="text-danger"> {errors.childsArr?.[i]?.age?.message}</span>
                                  </div>


                                  <input type="hidden" className="form-control" id={'childAge' + i} name={`childsArr[${i}]child_prof_det_id`} autoComplete='off' {...register(`childsArr.${i}.child_prof_det_id`)} defaultValue={kidsDetails[kids].profile_details_id} />

                                </div></>

                              </> : ''
                          }

                        </>)
                      })
                    }
                  </Fragment>)}
                  {((lifeStage === "marriedWithKids" || lifeStage === "married" || lifeStage === "separatedWithKids") && action === "add") && (<Fragment>
                    {
                      [...Array(childCount)].map((e, i) => <><div className="form-row" key={i}>
                        <div className="form-group col-md-6 pr20">
                          <label htmlFor={'childName' + i} className="font14 fw600">Child {i + 1} <span className="colorfa8">*</span></label>
                          <input type="text" className="form-control" id={'childName' + i} name={`childsArr[${i}]name`} autoComplete='off' {...register(`childsArr.${i}.name`, { required: 'Name is required', minLength: { value: 3, message: 'Name is too short' }, maxLength: 'Name is too long' })} placeholder="Enter Child Name" />
                          <span className="text-danger"> {errors.childsArr?.[i]?.name?.message}</span>
                        </div>


                        <div className="form-group col-md-6 pl20">
                          <label htmlFor={'childAge' + i} className="font14 fw600">Age <span className="colorfa8">*</span></label>
                          <div className="input-group selectarrow">
                            <input type="text" className="form-control" id={'childAge' + i} name={`childsArr[${i}]age`} autoComplete='off' {...register(`childsArr.${i}.age`, { required: 'Age is required', min: { value: 0, message: 'Min value is 1' } })} placeholder="Enter Child Age" onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }} />
                            <select
                              className="custom-select font14" id="inputGroupSelect01" name={`childsArr[${i}]ageType`} {...register(`childsArr.${i}.ageType`)}>
                              <option value="years">Year</option>
                              <option value="months">Months</option>
                              <option value="days">Days</option>
                            </select></div>
                          <span className="text-danger"> {errors.childsArr?.[i]?.age?.message}</span>
                        </div>

                        <input type="hidden" className="form-control" id={'childAge' + i} name={`childsArr[${i}]child_prof_det_id`} autoComplete='off' {...register(`childsArr.${i}.child_prof_det_id`)} />

                      </div></>)
                    }
                  </Fragment>)}

                </div>


                {
                  (lifeStage === "single" || lifeStage === "married") && childCount === 0 ? <></> : <div className="formdevider mb20 mt20" />
                }



                <div className="custome_form">

                  <div className="form-row">
                    <div className="form-group col-md-6 pr20">
                      <label htmlFor="fatherName" className="font14 fw600">Your father's name </label>
                      <input type="text" className="form-control" id="fatherName" name="fatherName" autoComplete='off' {...register("fatherName")} placeholder="Enter full name" />
                    </div>
                    <div className="form-group col-md-6 pl20">
                      <label htmlFor="fatherAge" className="font14 fw600">Age</label>
                      <input type="text" className="form-control" id="fatherAge" name="fatherAge" autoComplete='off' {...register("fatherAge")} placeholder="Enter Father Age" />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6 pr20">
                      <label htmlFor="motherName" className="font14 fw600">Your mother's name</label>
                      <input type="text" className="form-control" id="motherName" name="motherName" autoComplete='off' {...register("motherName")} placeholder="Enter Mother Name" />
                    </div>
                    <div className="form-group col-md-6 pl20">
                      <label htmlFor="motherAge" className="font14 fw600">Age </label>
                      <input type="text" className="form-control" id="motherAge" name="motherAge" autoComplete='off' {...register("motherAge")} placeholder="Enter Mother Age" />
                    </div>
                  </div>
                </div>

              </div>
              <div className="saveBtn d-block d-sm-none">
                <button type={buttonSpinner ? "button" : "submit"} disabled={!isDirty && !isValid} className="btn btn-primary">{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Save and Continue"}  <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10"></path></svg></span></button>
              </div>

              <div className="saveBtn backsaveBtn d-block d-sm-none">
                <button type="button" className="btn btn-primary" onClick={() => setSubstep(prevsubStep(substep))}> Back </button>
                <button type='submit' disabled={!isDirty && !isValid} className="btn btn-primary"> Save </button>
              </div>

              {/* Form End */}
            </form>
          </div>

          <div className="col-lg-4 qouteouter d-none d-md-block">
            <div className="qoutetext font18 fw500 color212 pl45 pr45">
              <div className="qoutesign">
                &#8220;
              </div>


              {"We need to mandatorily ask these questions because our mission is to plan not only for you, for your family."}
            </div>


            <div className="qouteimg"><img src={SideImg} alt='Completed' /></div>
          </div>
        </div>

      </div>
    </Fragment >
  )
}
