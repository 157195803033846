import React, { Fragment, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import axioInstance from '../axiosInstance';
import SweetAlert from 'sweetalert2'
import OtpInput from 'react-otp-input';
import axios from 'axios';
import { Encryption } from '../global';

export default function RegisterVerify({ setLoginStep, setLoginType, signupVerifyScreen, setSignupVerifyScreen, signupVerifyPostData, setSignupVerifyPostData, setPopupContent, setShow, setPopupIcon, setPopupTitle }) {

  const { handleSubmit } = useForm();
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const [emailOtpMessage, setEmailOtpMessage] = useState();

  const onSubmit = Formdata => {

    setButtonSpinner(true);

    const Postdata = {
      "email": signupVerifyPostData.emailAddress,
      "mobile": signupVerifyPostData.mobileNumber,
      "emailOtp": otp[0] + '' + otp[1] + '' + otp[2] + '' + otp[3] + '' + otp[4] + '' + otp[5],
      "otp_send_on": 'email'
    };


    axioInstance.post(`otp-verify`, Postdata).then(
      function (response, data) {

        const search = window.location.search;
        const params = new URLSearchParams(search);

        if (response.data.emailVerify === 100) {
          setEmailOtpMessage('');
          const plan_admin_id = params.get('s') !== '' ? params.get('s') : (params.get('s') !== null ? params.get('s') : '');

          const Postdata = {
            "name": signupVerifyPostData.fullName,
            "mobile": signupVerifyPostData.mobileNumber,
            "email": signupVerifyPostData.emailAddress,
            "password": signupVerifyPostData.password,
            "register": 1,
            "registerVia": 2,
            "rel_manager": plan_admin_id
          };

          axioInstance.post(`register`, Postdata)
            .then(function (response) {
              console.log('response.data.token', response.data.token);


              sessionStorage.setItem('token', response.data.token);

              axioInstance.post(`profile/add`, {
                "name": signupVerifyPostData.fullName,
                "mobile": signupVerifyPostData.mobileNumber,
                "email": signupVerifyPostData.emailAddress,
                "password": signupVerifyPostData.password,
                "register": 1,
                "registerVia": 2,
                "rel_manager": plan_admin_id,
                "admin_id": sessionStorage.getItem("admin_id") ? sessionStorage.getItem("admin_id") : 1
              }).then(function (response) {

                const registered_profile_id = Encryption(response.data.profile_id);

                sessionStorage.setItem('profile_id', Encryption(response.data.profile_id));
                sessionStorage.setItem('profile_det_id', Encryption(response.data.profile_det_id.profile_details_id));
                axioInstance.post(`default-risk-profile`, { profile_id: registered_profile_id }).then(function (response) {
                });


                axioInstance.post(`profile/add-default-assumption`, { profile_id: registered_profile_id }).then(function (response) {

                  axioInstance.post(`profile/summary-add`, { profile_id: registered_profile_id }).then(function (response) {
                  });

                });


                axioInstance.post(`profile/summary-add`, { profile_id: registered_profile_id }).then(function (response) {
                });


                let fullname = signupVerifyPostData.fullName;
                var names = fullname.split(' ');


                const relationship_manager = params.get('ref_by') !== '' ? params.get('ref_by') : (params.get('ref_by') !== null ? params.get('ref_by') : 'FINNOVATE');

                const utm_source = params.get('utm_source') ? params.get('utm_source') : 'FinnPlan';
                const utm_campaign = params.get('utm_campaign') ? params.get('utm_campaign') : 'FinnPlan';
                const utm_id = params.get('utm_id') ? params.get('utm_id') : '9423';

                const apiData = JSON.stringify({
                  "campaign_id": utm_id,
                  "campaign_type": 'TeleSales',
                  "check_condication_1": '0',
                  "check_condication_2": '0',
                  "ignore_duplicate": '1',
                  "ignore_open_task": '1',
                  "check_condication_task": '0',
                  "consider_same_account": '0',
                  "match_col": 'email_and_mobile',
                  "lead_source": utm_source,
                  "lead_caimpaign": utm_campaign,
                  "relationship_manager": relationship_manager,
                  "profile_details_id": response.data.profile_det_id.profile_details_id,
                  "tele_caller": '1000049',
                  "lead_url": window.location.href,
                  "import_data": {
                    0: {
                      "f_name": names[0] ? names[0] : '',
                      "m_name": '',
                      "l_name": names[1] ? names[1] : '',
                      "name": signupVerifyPostData.fullName,
                      "mobile": signupVerifyPostData.mobileNumber,
                      "email": signupVerifyPostData.emailAddress
                    }
                  }
                });



                axios.post(`https://lmslive.finnovate.in/import/import-everything-api.php`, apiData).then(function (response) {

                  axioInstance.post(`profile/add-zoho-crm-profile`, { profile_id: registered_profile_id }).then(function (response) {
                  });

                });


                axioInstance.post(`sendinblue/add-list`, { listid: 8, action: 'insert', email: signupVerifyPostData.emailAddress, attributes: { EMAIL: signupVerifyPostData.emailAddress, FULL_NAME: signupVerifyPostData.mobileNumber, MOBILE: '+91' + signupVerifyPostData.mobileNumber, SMS: '+91' + signupVerifyPostData.mobileNumber, LEAD_SOURCE: 4, WHATSAPP: '+91' + signupVerifyPostData.mobileNumber } }).then(function (response) {
                });

                axioInstance.post(`get-plan-steps`, { profile_id: registered_profile_id }).then(
                  (response, data) => {
                    if (response.data.status === 100) {

                      /*setPopupIcon('successIcon')
                      setPopupTitle('Sign Up');
                      setPopupContent("Successfully Registered On Finnovate.");
                      setShow(true);*/

                      setTimeout(() => {

                        const Otpdata = {
                          "full_name": signupVerifyPostData.fullName,
                          "email": signupVerifyPostData.emailAddress,
                          "mobile": signupVerifyPostData.mobileNumber,
                          "otp_send_on": 'mobile'
                        };

                        axioInstance.post(`send-otp`, Otpdata).then(
                          function (response, data) {
                            setButtonSpinner(false);
                            setSignupVerifyScreen('mobile');
                          }
                        )
                      }, 100);

                    }
                  }
                );



              });

            })
            .catch(e => {

              if (e.response.data.data.hasOwnProperty("password")) {
                SweetAlert.fire({
                  toast: true,
                  icon: 'error',
                  title: e.response.data.data.password[0],
                  animation: false,
                  position: 'top-right',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                    toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                  }
                });
              } else {

                setShow(true);
                setPopupContent(e.response.data.data.email[0]);
              }
            });
        } else {

          setEmailOtpMessage(response.data.emailOtpMessage);
          setButtonSpinner(false);
        }

      }
    );

  }

  const backbtn = () => {
    setSignupVerifyScreen(false);
  }

  // eslint-disable-next-line
  const [otpError, setOtpError] = useState();
  //const [otp, setOtp] = useState(new Array(6).fill(""));

  const [otp, setOtp] = useState('');


  // eslint-disable-next-line
  const OtphandleChange = (element, index) => {

    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds]);

  const resendOTP = () => {
    setEmailOtpMessage('');
    const Otpdata = {
      "full_name": signupVerifyPostData.fullName,
      "email": signupVerifyPostData.emailAddress,
      "mobile": signupVerifyPostData.mobileNumber,
      "otp_send_on": 'email'
    };

    axioInstance.post(`send-otp`, Otpdata).then(
      function (response, data) {
        setOtp('');
        setMinutes(1);
        setSeconds(30);
      }
    )


  };

  return (
    <Fragment>
      <div className="logincard">
        <div className="logincard_title pt30 pb30 pl35 pr35">
          <div className="font18 color212 fw600">Verify Your Email Address</div>
          <div className="font13 fw500 color626">OTP has been sent to Email {signupVerifyPostData.emailAddress.slice(0, 8) + signupVerifyPostData.emailAddress.slice(0).replace(/.(?=...)/g, '*')}</div>
          <button type="button" className="btn btn-outline-primary btn-sm" onClick={backbtn}><span className="blueleftarrow mr5" /> Back</button>
        </div>

        <div className="p35">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="font14 fw600 color212">Enter OTP to continue. <span className="colorfa8">*</span></div>
            <div className="mt12 otpfield">

              <OtpInput
                className={"otpfield"}
                name="otp"
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span> </span>}
                renderInput={(props) => <input {...props} className="otp-field otp-field-npm" />}
              />

            </div>
            <div className="error mt5 font13 coloreb4">{emailOtpMessage ? emailOtpMessage : otpError}</div>

            <div className="otptext font13 color626 mt30">

              {seconds > 0 || minutes > 0 ? (
                <p>
                  Resend OTP in: {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </p>
              ) : (
                <>Did not receive the OTP? <span className="color0d6 pointer" onClick={resendOTP}>Resend again</span></>
              )}
            </div>

            <button type={buttonSpinner ? "button" : "submit"} disabled={buttonSpinner} className="btn btn-primary btn-lg btn-block font13 fw500 mt20 continuebtn">{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Verify Email"}</button>


          </form>


          <div className="logintnctext font12 fw500 color485 mt35">
            By continuing, you agree with <a href='https://www.finnovate.in/terms-conditions' target='_blank' rel="noreferrer" className="color06d">Terms of Use</a> of Finnovate Financial Services Pvt Ltd. Our <a href='https://www.finnovate.in/privacy-policy' target='_blank' rel="noreferrer" className="color06d">Privacy Policy</a> describes how data is handled at Finnovate.
          </div>

        </div>


      </div>
    </Fragment >
  )
}
