import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import axioInstance from '../axiosInstance';
import ReCAPTCHA from 'react-google-recaptcha'
import SweetAlert from 'sweetalert2'
import { Encryption } from '../global';

export default function LoginFirst({ setLoginType, setLoginStep, setLoginPara, setPopupContent, setShow, setPopupIcon, setPopupTitle, setVerifiedMobile }) {

    const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();

    const recaptchaRef = React.useRef();
    const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);

    const handleRecaptchaChange = (value) => {
        // Set the state to true when ReCAPTCHA is verified
        setIsRecaptchaVerified(true);
    }

    const onSubmit = data => {

        if (!isRecaptchaVerified) {
            // If ReCAPTCHA is not verified, show error message
            SweetAlert.fire("Warning", "Please verify that you are not a robot.", "warning");
            return;
        }

        setButtonSpinner(true);

        
        axioInstance.post(`profile/view`, { email: Encryption(data.emailAddress) })
            .then(function (response, data) {

                if (response.data) {

                    if (response.data.status === 101) {
                        setPopupIcon('errorIcon')
                        setPopupTitle('Login with Email Address');
                        setPopupContent("Entered email address not found. try again");
                        setShow(true);
                    } else {

                        //if (response.data.waitlist === 1) {

                        //setPopupIcon('errorIcon')
                        //setPopupTitle('You have been added to waitlist.');
                        // setPopupContent("We will review your details soon and will keep you posted.");
                        // setShow(true);

                        // } else {

                        setLoginPara(response.data);
                        axioInstance.post(`check-mobile-verify-otp`, { mobile: response.data.phone })
                            .then(function (result) {

                                if (result.data.mobileVerify === 101) {

                                    const Otpdata = {
                                        "full_name": response.data.first_name,
                                        "email": response.data.email,
                                        "mobile": response.data.phone,
                                        "otp_send_on": 'mobile',
                                        "otp_for": "verify"
                                    };

                                    axioInstance.post(`send-otp`, Otpdata).then(
                                        function (response, data) {
                                            setLoginType('otp');
                                            setLoginStep(2);
                                            setVerifiedMobile(true);
                                        }
                                    )


                                } else {
                                    setLoginType('password');
                                    setLoginStep(2);

                                }


                            })



                        //}

                    }
                } else {
                    setPopupIcon('errorIcon')
                    setPopupTitle('Login with Email Address');
                    setPopupContent("Entered email address not found. try again");
                    setShow(true);
                }
                setButtonSpinner(false);

            })
            .catch(e => {
                setPopupIcon('errorIcon')
                setPopupTitle('Login with Email Address');
                setPopupContent("Entered email address not found. try again");
                setShow(true);
                setButtonSpinner(false);
            });
    }

    const [buttonSpinner, setButtonSpinner] = useState(false);



    return (
        <Fragment>



            <div className="logincard">




                <div className="logincard_title pt30 pb30 pl35 pr35">
                    <div className="font18 color212 fw600">Login your account</div>
                    <div className="font13 fw500 color626">Join us and set your path towards Financial Freedom</div>
                </div>
                <div className="p35">




                    <div className="googlebtn font15 fw600 color222">
                        <span className="gmailicon mr10" /> <span className='googleOpacity'>Continue with Google</span>
                    </div>

                    <div className="orline mt40 mb40">
                        <span className="font13 color7b8 fw600">OR</span>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="font14 fw600 color212">Login with Email Address. <span className="colorfa8">*</span></div>
                        <div className="mt12 mobilefield">
                            <input type="text" id="emailAddress" name="emailAddress" autoComplete='off' {...register("emailAddress", { required: 'Email is required', pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'Invalid Email' } })} placeholder="Enter email address" />
                        </div>
                        <span className="text-danger font11"> {errors.emailAddress && errors.emailAddress.message}</span>

                        <div className="recaptcha-container mt-3">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6LfLyqopAAAAAMrrNZ0gu0_VZZtRh6uIhQc4TVCM"
                            onChange={handleRecaptchaChange}
                        />
                        </div>
                        
                        <button type={buttonSpinner ? "button" : "submit"} disabled={!isDirty && !isValid && !buttonSpinner} className="btn btn-primary btn-lg btn-block font13 fw500 mt30 continuebtn">{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Continue"}</button>
                    </form>
                    <div className="signuptext mt30 font14 color263 fw500">Don't have an account?  <span className="color06d ml5 fw600 pointer" onClick={() => setLoginType('signup')}>Sign up</span></div>

                    <div className="logintnctext font12 fw500 color485 mt35">
                        By continuing, you agree with <a href='https://www.finnovate.in/terms-conditions' target='_blank' rel="noreferrer" className="color06d">Terms of Use</a> of Finnovate Financial Services Pvt Ltd. Our <a href='https://www.finnovate.in/privacy-policy' target='_blank' rel="noreferrer" className="color06d">Privacy Policy</a> describes how data is handled at Finnovate.
                    </div>

                </div>

            </div>
        </Fragment>
    )
}
