import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import axioInstance from '../../axiosInstance';
import formatAmount from 'indian-currency-formatter';

export default function EditHealthInsurance({ spouse }) {

    const [buttonSpinner, setButtonSpinner] = useState(false);

    const { register, handleSubmit, reset, formState: { errors, isDirty, isValid } } = useForm();

    const onSubmit = data => {

        setButtonSpinner(true);

        const Postdata = {
            "prop_id": sessionStorage.getItem('profile_id'),
            "profile_id": sessionStorage.getItem('profile_id'),
            "profile_details_id": sessionStorage.getItem('profile_details_id'),
            "proposer": sessionStorage.getItem('profile_details_id'),
            "type": 9,
            "sub_type": 1,
            "owner": sessionStorage.getItem('profile_id'),
            "instr_name": 'Health',
            "invest_type": 0,
            "invest_amount": data.premiumAmount.replace(/,/g, ""),
            "amount": data.premiumAmount.replace(/,/g, ""),
            "cost_amount": data.premiumAmount.replace(/,/g, ""),
            "insured_value": data.selfMoneyType * data.sumAssured,
            "current_value": data.selfMoneyType * data.sumAssured,
            "frequency": data.selfFrequency,
            "moneyDigits": data.selfMoneyType,
            "ppt": 1,
            "policy_term": 1,
            "rider_id": Array.isArray(data.insured) ? data.insured : [data.insured],
            "invest_id": data.selfInvestId,
            "admin_id": sessionStorage.getItem("admin_id")
        };

        axioInstance.post(`investment/add-investment`, Postdata)
            .then(function (response) {
                //Add spouse Health
                const Postdata = {
                    "prop_id": sessionStorage.getItem('profile_id'),
                    "profile_id": sessionStorage.getItem('profile_id'),
                    "profile_details_id": sessionStorage.getItem('spouse_prof_det_id'),
                    "proposer": sessionStorage.getItem('spouse_prof_det_id'),
                    "type": 9,
                    "sub_type": 1,
                    "owner": sessionStorage.getItem('profile_id'),
                    "instr_name": 'Spouse Health',
                    "invest_type": 0,
                    "invest_amount": data.spousepremiumAmount.replace(/,/g, ""),
                    "amount": data.spousepremiumAmount.replace(/,/g, ""),
                    "cost_amount": data.spousepremiumAmount.replace(/,/g, ""),
                    "insured_value": data.spouseMoneyType * data.spousesumAssured,
                    "current_value": data.spouseMoneyType * data.spousesumAssured,
                    "frequency": data.spouseFrequency,
                    "moneyDigits": data.spouseMoneyType,
                    "ppt": 1,
                    "policy_term": 1,
                    "rider_id": Array.isArray(data.spouseinsured) ? data.spouseinsured : [data.spouseinsured],
                    "invest_id": data.spouseselfInvestId,
                    "admin_id": sessionStorage.getItem("admin_id")
                };

                axioInstance.post(`investment/add-investment`, Postdata)
                    .then(function (response) {

                        setButtonSpinner(false);

                        window.location.reload();

                    })

            })
    }

    const [health, setHealth] = useState(null);
    const [healthFor, setHealthFor] = useState([]);
    const [premiumAmount, setPremiumAmount] = useState();
    const [spousepremiumAmount, setSpousepremiumAmount] = useState();

    // eslint-disable-next-line
    const [maxSelf, setMaxSelf] = useState(3);
    // eslint-disable-next-line
    const [spousemaxSelf, setSpousemaxSelf] = useState(3);

    const handleChange = (e) => {

        const { value } = e.target;

        if (e.target.name === 'premiumAmount') {
            setPremiumAmount(formatAmount(value.replace(/,/g, "")));
        } else if (e.target.name === 'spousepremiumAmount') {
            setSpousepremiumAmount(formatAmount(value.replace(/,/g, "")));
        } else if (e.target.name === "sumAssured") {

            if (e.target.value.indexOf('.') !== -1) {
                setMaxSelf(5);
            } else {
                setMaxSelf(3);
            }

        } else if (e.target.name === "spousesumAssured") {

            if (e.target.value.indexOf('.') !== -1) {
                setSpousemaxSelf(5);
            } else {
                setSpousemaxSelf(3);
            }

        }

    };

    const [selfName, setSelfName] = useState();

    useEffect(() => {

        axioInstance.post(`profile/term-data`, { profile_id: sessionStorage.getItem('profile_id') }).then(function (response, data) {
            const res = response.data[0];
            setSelfName(res.selfName);

            const moneyDigits = res.healthsumAssured.toString().length >= 8 ? 10000000 : 100000;

            const spousemoneyDigits = res.spousehealthsumAssured.toString().length >= 8 ? 10000000 : 100000;

            setHealth({ healthInsurance: res.healthInsurance, sumAssured: res.healthsumAssured, selfMoneyType: moneyDigits, premiumAmount: formatAmount(res.healthpremiumAmount), selfInvestId: res.healthInvestId, insured: res.healthCoverIds, selfFrequency: res.healthFrequency, spousesumAssured: res.spousehealthsumAssured, spouseMoneyType: spousemoneyDigits, spousepremiumAmount: formatAmount(res.spousehealthpremiumAmount), spouseFrequency: res.spousehealthFrequency, spouseinsured: res.spousehealthCoverIds, spouseselfInvestId: res.spousehealthInvestId });

        });

        axioInstance.post(`profile-details/getfamily`, { profile_id: sessionStorage.getItem('profile_id') }).then(function (response, data) {
            setHealthFor(response.data);
        });

    }, []);

    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        reset(health);
        // eslint-disable-next-line
    }, [health]);

    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>

                <input type="hidden" name="selfInvestId" {...register("selfInvestId")} value="" />

                <div className="form_prnt">
                    <div className="expandtab font12 color353 fw500">{selfName} Health Insurance</div>

                    <div className="custome_form">
                        <div className="form-row mt20">
                            <div className="col-md-12 pr20">
                                <label htmlFor="inputEmail4" className="font14 fw500">Sum assured</label>
                                <div className="input-group selectarrow">
                                    <input type="text" className="form-control font14 color212 fw500" name="sumAssured" aria-label="Text input with dropdown button" autoComplete='off' {...register("sumAssured", { required: 'Sum assured is required' })} onChange={handleChange} maxLength={maxSelf} minLength={1} />
                                    <select className="custom-select font14" id="inputGroupSelect01" name='selfMoneyType' {...register("selfMoneyType")}>
                                        <option value="100000">Lakhs</option>
                                        <option value="10000000">Crore</option>
                                    </select>
                                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                </div>
                            </div>
                            <span className="text-danger"> {errors.sumAssured && errors.sumAssured.message}</span>
                        </div>
                        <div className="form-row mt20">
                            <div className="col-md-12 pr20">
                                <label htmlFor="inputEmail4" className="font14 fw500">Premium amount</label>
                                <div className="input-group selectarrow">
                                    <input type="text" className="form-control font14 color212 fw500" name="premiumAmount" autoComplete='off' {...register("premiumAmount", { required: 'Premium Amount is required' })} onChange={handleChange} value={premiumAmount} />
                                    <select className="custom-select font14" id="inputGroupSelect01" name='selfFrequency' {...register("selfFrequency")}>
                                        <option value="12">Monthly</option>
                                        <option value="4">Quarterly</option>
                                        <option value="2">Half Yearly</option>
                                        <option value="1" selected>Yearly</option>
                                        <option value="0">Lumpsum</option>
                                    </select>
                                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                </div>
                            </div>
                            <span className="text-danger"> {errors.premiumAmount && errors.premiumAmount.message}</span>
                        </div>

                        <div className="form-row mt20">
                            <div className="col-md-12 pr20">
                                <div className="font14 fw500">Health Insurance avaialble For</div>
                                <ul className="steps_tag font14 fw500 color182 mt15">

                                    {healthFor.map((data, key) => {
                                        return (
                                            <li key={key}>
                                                <label>
                                                    <input type="checkbox" name="insured" {...register("insured", { required: 'Select at least one member' })} value={data.profile_details_id} />
                                                    <span className="rdo_text_box">
                                                        {data.first_name}
                                                    </span>
                                                </label>
                                            </li>
                                        );
                                    })}
                                </ul>
                                <span className="text-danger"> {errors.insured && errors.insured.message}</span>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="formdevider mt25 mb25" />


                {
                    spouse && (<>
                        <div className="form_prnt">
                            <div className="expandtab font12 color353 fw500">{spouse.full_name} Health Insurance</div>
                            <input type="hidden" name="spouseselfInvestId" {...register("spouseselfInvestId")} value="" />
                            <div className="custome_form">
                                <div className="form-row mt20">
                                    <div className="col-md-12 pr20">
                                        <label htmlFor="inputEmail4" className="font14 fw500">Sum assured</label>
                                        <div className="input-group selectarrow">
                                            <input type="text" className="form-control font14 color212 fw500" name="spousesumAssured" aria-label="Text input with dropdown button" autoComplete='off' {...register("spousesumAssured", { required: 'Sum assured is required' })} onChange={handleChange} maxLength={spousemaxSelf} minLength={1} />
                                            <select className="custom-select font14" id="inputGroupSelect01" name='spouseMoneyType' {...register("spouseMoneyType")}>
                                                <option value="100000">Lakhs</option>
                                                <option value="10000000">Crore</option>
                                            </select>
                                            <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                        </div>
                                    </div>
                                    <span className="text-danger"> {errors.spousesumAssured && errors.spousesumAssured.message}</span>
                                </div>
                                <div className="form-row mt20">
                                    <div className="col-md-12 pr20">
                                        <label htmlFor="inputEmail4" className="font14 fw500">Premium amount</label>
                                        <div className="input-group selectarrow">
                                            <input type="text" className="form-control font14 color212 fw500" name="spousepremiumAmount" autoComplete='off' {...register("spousepremiumAmount", { required: 'Premium Amount is required' })} onChange={handleChange} value={spousepremiumAmount} />
                                            <select className="custom-select font14" id="inputGroupSelect01" name='spouseFrequency' {...register("spouseFrequency")}>
                                                <option value="12">Monthly</option>
                                                <option value="4">Quarterly</option>
                                                <option value="2">Half Yearly</option>
                                                <option value="1" selected>Yearly</option>
                                                <option value="0">Lumpsum</option>
                                            </select>
                                            <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                        </div>
                                    </div>
                                    <span className="text-danger"> {errors.spousepremiumAmount && errors.spousepremiumAmount.message}</span>
                                </div>

                                <div className="form-row mt20">
                                    <div className="col-md-12 pr20">
                                        <div className="font14 fw500">Health Insurance avaialble For</div>
                                        <ul className="steps_tag font14 fw500 color182 mt15">

                                            {healthFor.map((data, key) => {
                                                return (
                                                    <li key={key}>
                                                        <label>
                                                            <input type="checkbox" name="spouseinsured" {...register("spouseinsured", { required: 'Select at least one member' })} value={data.profile_details_id} />
                                                            <span className="rdo_text_box">
                                                                {data.first_name}
                                                            </span>
                                                        </label>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                        <span className="text-danger"> {errors.spouseinsured && errors.spouseinsured.message}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>)
                }

                <div className="saveBtn backsaveBtn">
                    <button type="button" className="btn btn-primary">Cancel </button>
                    <button type={buttonSpinner ? "button" : "submit"} className="btn btn-primary" disabled={!isDirty && !isValid}>{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> Updating</> : "Save"} </button>
                </div>
            </form>
        </Fragment >
    )
}
