import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import axioInstance from '../axiosInstance';
import { Encryption } from '../global';

export default function RegisterFirst({ setLoginStep, setLoginType, setSignupVerifyScreen, setSignupVerifyPostData }) {

  // eslint-disable-next-line
  const { register, handleSubmit, setError, formState: { errors, isDirty, isValid } } = useForm();

  const [buttonSpinner, setButtonSpinner] = useState(false);

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const [emailCheck, setEmailCheck] = useState(false);
  const checkProfile = (data) => {

    axioInstance.post(`profile/view`, { 'email': Encryption(data.target.value), 'validate': '1' }).then(function (response, data) {

      if (response.data.status === 100) {
        setError('emailAddress', { type: 'custom', message: response.data.msg });
        setEmailCheck(true);
      } else {
        setEmailCheck(false);
      }
    })
  }

  const onSubmit = Formdata => {

    setButtonSpinner(true);


    const search = window.location.search;
    const params = new URLSearchParams(search);
    const utm_source = params.get('utm_source');
    const utm_campaign = params.get('utm_campaign');
    const utm_id = params.get('utm_id');

    const Postdata = {
      "full_name": Formdata.fullName,
      "mobile": Formdata.mobileNumber,
      "email": Formdata.emailAddress,
      "password": Formdata.password,
      "utm_source": utm_source,
      "utm_campaign": utm_campaign,
      "utm_id": utm_id
    };



    setSignupVerifyPostData(Formdata);

    axioInstance.post(`add-guestuser`, Postdata).then(
      function (response, data) {

        const Otpdata = {
          "full_name": Formdata.fullName,
          "mobile": Formdata.mobileNumber,
          "email": Formdata.emailAddress,
          "otp_send_on": 'email',
          "password": Formdata.password,
          'guestId': response.data.id
        };

        axioInstance.post(`send-otp`, Otpdata).then(
          function (response, data) {
            setButtonSpinner(false);
            setSignupVerifyScreen(true);
          }
        )

      }
    );

  }
  return (
    <Fragment>
      <div className="logincard">
        <div className="logincard_title pt30 pb30 pl35 pr35">
          <div className="font18 color212 fw600">Sign up to start </div>
          <div className="font13 fw500 color626">Join us and set your path towards Financial Freedom</div>
          <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => { setLoginType(false); setLoginStep(1) }}><span className="blueleftarrow mr5" /> Back</button>
        </div>
        <div className="p35">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="font14 fw600 color212">Your full name<span className="colorfa8 ml5">*</span></div>
              <div className="mt12 mobilefield">
                <input type="text" id="fullName" name="fullName" autoComplete='off' {...register("fullName", { required: 'Name is required', minLength: { value: 5, message: 'Name is too short' }, maxLength: 'Name is too long' })} placeholder="Enter full name" />
              </div>
              <span className="text-danger"> {errors.fullName && errors.fullName.message}</span>
            </div>

            <div className="mt20">
              <div className="font14 fw600 color212">Email Address<span className="colorfa8 ml5">*</span></div>
              <div className="mt12 mobilefield">
                <input type="text" id="emailAddress" name="emailAddress" autoComplete='off' {...register("emailAddress", { required: 'Email is required', pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'Invalid Email' } })} placeholder="Enter email address" onBlurCapture={checkProfile} />
              </div>
              <span className="text-danger font12"> {errors.emailAddress && errors.emailAddress.message} {errors.emailAddress && errors.emailAddress.message === 'Email Address is already exits' ? <><span className="color06d ml5 fw600 font13 pointer" onClick={() => { setLoginType(false); setLoginStep(1) }}>Log In</span></> : ''}</span>
            </div>


            <div className="mt20">
              <div className="font14 fw600 color212">Mobile number<span className="colorfa8 ml5">*</span></div>
              <div className="mt12 mobilefield">
                <input type="text" id="mobileNumber" name="mobileNumber" autoComplete='off' {...register("mobileNumber", { required: 'Mobile Number is required', pattern: { value: /^(?:(?:\+|0{0,2})91(\s*|[-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/, message: 'Invalid Mobile Number' } })} placeholder="Mobile Number" maxLength={10} />
              </div>
              <span className="text-danger"> {errors.mobileNumber && errors.mobileNumber.message}</span>
            </div>

            <div className="mt20">
              <div className="font14 fw600 color212">Set password<span className="colorfa8 ml5">*</span></div>
              <div className="mt12 mobilefield">
                <input type={passwordShown ? "text" : "password"} placeholder="8-10 Charecters" name="password" autoComplete='off' {...register("password", { required: 'Password is required', minLength: { value: 5, message: 'Password is too short' }, maxLength: { value: 15, message: 'Password is too long' } })} />
                <span className="eyeicon mr10" onClick={togglePasswordVisiblity} />
              </div>
            </div>

            <button type={buttonSpinner ? "button" : "submit"} className="btn btn-primary btn-lg btn-block font13 fw500 mt20 continuebtn" disabled={emailCheck === true ? "disabled" : buttonSpinner}>{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Continue"}</button>
          </form>
          <div className="signuptext mt30 font14 color263 fw500 pointer">Already have an account?  <span className="color06d ml5 fw600" onClick={() => { setLoginType(false); setLoginStep(1) }}>Log In</span></div>

          <div className="logintnctext font12 fw500 color485 mt30">
            By continuing, you agree with <a href='https://www.finnovate.in/terms-conditions' target='_blank' rel="noreferrer" className="color06d">Terms of Use</a> of Finnovate Financial Services Pvt Ltd. Our <a href='https://www.finnovate.in/privacy-policy' target='_blank' rel="noreferrer" className="color06d">Privacy Policy</a> describes how data is handled at Finnovate.
          </div>

        </div>

      </div>
    </Fragment>
  )
}
