import React, { Fragment, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'
import OtpInput from 'react-otp-input';
import axioInstance from '../axiosInstance';
import { Encryption } from '../global';

export default function LoginVerifyOtp({ setLoginStep, setLoginType, loginPara, setPopupContent, setShow, setPopupIcon, setPopupTitle, verifiedMobile }) {

  const [buttonSpinner, setButtonSpinner] = useState(false);

  const { handleSubmit } = useForm();

  const backbtn = () => {
    setLoginStep(1);
  }

  const onSubmit = Formdata => {

    setButtonSpinner(true);

    const Postdata = {
      "mobile": loginPara.phone,
      "email": loginPara.email,
      "mobileOtp": otp[0] + '' + otp[1] + '' + otp[2] + '' + otp[3] + '' + otp[4] + '' + otp[5],
      "otp_send_on": 'mobile',
      "otp_for": verifiedMobile === true ? 'verify' : 'login'
    };


    axioInstance.post(`profile/login-via-otp`, Postdata)
      .then(function (response) {

        if (response.data.status === 100) {
          SweetAlert.fire({
            toast: true,
            icon: 'success',
            title: 'Signed in Successfully',
            animation: false,
            position: 'top-right',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', SweetAlert.stopTimer)
              toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
            }
          }).then(function () {

            sessionStorage.setItem('token', response.data.token);
            const pdata = { "email": Encryption(loginPara.email) };

            axioInstance.post(`profile/view`, pdata).then(function (response) {
              sessionStorage.setItem('profile_id', Encryption(response.data.profile_id));
              sessionStorage.setItem('full_name', response.data.first_name + '\'s');
              //sessionStorage.setItem('profile_details_id', response.data.profile_details_id);
              axioInstance.post(`login-logout-logs`, { user_type: 'client', action: 'login', profile_id: response.data.profile_id, login_via:'Otp' }).then(function (response, data) {

                setButtonSpinner(false);
                if (response.data.step > 3) {
                  window.location.href = "/dashboard";
                } else {
                  window.location.href = "/basic-profile";
                }
  
              });

            });

            //

          });

        } else {
          setPopupIcon('errorIcon')
          setPopupTitle('Verify Your Mobile Number');
          setPopupContent("Something went wrong. please try after again");
          setShow(true);
        }


      })
      .catch(e => {
        setPopupIcon('errorIcon')
        setPopupTitle('Verify Your Mobile Number');
        setPopupContent("Something went wrong. please try after again");
        setShow(true);
      });

  }


  // eslint-disable-next-line
  const [otpError, setOtpError] = useState();
  const [otp, setOtp] = useState('');

  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds]);

  const resendOTP = () => {

    const Otpdata = {
      "full_name": '',
      "email": loginPara.email,
      "mobile": loginPara.phone,
      "otp_send_on": 'mobile'
    };

    axioInstance.post(`send-otp`, Otpdata).then(
      function (response, data) {
        setOtp('');
        setMinutes(1);
        setSeconds(30);
      }
    )
  };

  return (
    <Fragment>
      <div className="logincard">
        <div className="logincard_title pt30 pb30 pl35 pr35">
          <div className="font18 color212 fw600">Verify Your Mobile Number</div>
          <div className="font13 fw500 color626">OTP has been sent to Mobile +91 - {loginPara.phone.slice(0, 2) + loginPara.phone.slice(2).replace(/.(?=...)/g, '*')}</div>
          <button type="button" className="btn btn-outline-primary btn-sm" onClick={backbtn}><span className="blueleftarrow mr5" /> Back</button>
        </div>
        <div className="p35">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="font14 fw600 color212">Enter OTP to continue. <span className="colorfa8">*</span></div>
            <div className="mt12 otpfield">

              <OtpInput
                className={"otpfield"}
                name="otp"
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span> </span>}
                renderInput={(props) => <input {...props} className="otp-field otp-field-npm" />}
              />

            </div>
            <div className="error mt5 font13 coloreb4">{otpError}</div>

            <div className="otptext font13 color626 mt30">

              {seconds > 0 || minutes > 0 ? (
                <p>
                  Resend OTP in: {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </p>
              ) : (
                <>Did not receive the OTP? <span className="color0d6 pointer" onClick={resendOTP}>Resend again</span></>
              )}
            </div>



            <button type={buttonSpinner ? "button" : "submit"} className="btn btn-primary btn-lg btn-block font13 fw500 mt20 continuebtn">{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Continue"}</button>


          </form>

          {
            verifiedMobile === false && seconds === 0 && (
              <>
                <div className="orline mt40 mb40">
                  <span className="font13 color7b8 fw600">OR</span>
                </div>

                <div className="googlebtn font15 fw600 color222 pointer" onClick={() => setLoginType('password')}>
                  Login via Password
                </div>
              </>
            )
          }


          <div className="logintnctext font12 fw500 color485 mt35">
            By continuing, you agree with <a href='https://www.finnovate.in/terms-conditions' target='_blank' rel="noreferrer" className="color06d">Terms of Use</a> of Finnovate Financial Services Pvt Ltd. Our <a href='https://www.finnovate.in/privacy-policy' target='_blank' rel="noreferrer" className="color06d">Privacy Policy</a> describes how data is handled at Finnovate.
          </div>

        </div>

      </div>
    </Fragment>
  )
}
