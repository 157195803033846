import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import axioInstance from '../axiosInstance'
import SweetAlert from 'sweetalert2'

export default function ResetSetPassword({ loginPara }) {


  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const onSubmit = data => {

    setButtonSpinner(true);

    const Otpdata = {
      "email": loginPara.email,
      "confirm_password": data.confirm_password,
    };

    axioInstance.post(`profile/update-password`, Otpdata).then(
      function (response, data) {

        SweetAlert.fire({
          toast: true,
          icon: 'success',
          title: 'Password Changed Successfully',
          animation: false,
          position: 'top-right',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', SweetAlert.stopTimer)
            toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
          }
        }).then(function () {

          setButtonSpinner(false);
          window.location.href = '/sign-in';

        });


      }
    )

  }

  const [password, setpassword] = useState();
  const [passwordCnf, setpasswordCnf] = useState()

  const handleChange = (e) => {
    const { value } = e.target;
    if (e.target.name === 'passwordFirst') {
      setpassword(value);
    } else if (e.target.name === 'confirm_password') {
      setpasswordCnf(value);
    }
  }

  const [togglePassword, setTogglePassword] = useState(false)

  const HideShowPassword = (tPassword) => {
    setTogglePassword(!tPassword)
  }

  return (
    <>
      <div className="logincard">
        <div className="logincard_title pt30 pb30 pl35 pr35">
          <div className="font18 color212 fw600">Create New Password</div>
          <div className="font13 fw500 color626">Must be atleast 8 characters in length</div>
          <button type="button" className="btn btn-outline-primary btn-sm"><span className="blueleftarrow mr5" /> Back</button>
        </div>
        <div className="p35">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="font14 fw600 color212">New password<span className="colorfa8 ml5">*</span></div>
              <div className="mt12 mobilefield">
                <input type={togglePassword ? "text" : "password"} placeholder="8-10 Charecters" name="passwordFirst" autoComplete='off' {...register("passwordFirst", { required: 'Password is required', minLength: { value: 5, message: 'Password is too short' }, maxLength: { value: 15, message: 'Password is too long' } })} onChange={handleChange} value={password} />
                <span className="eyeicon mr10" onClick={() => HideShowPassword(togglePassword)} />
              </div>
              <span className="text-danger font11"> {errors.passwordFirst && errors.passwordFirst.message}</span>
            </div>
            <div className="mt20">
              <div className="font14 fw600 color212">Confirm password<span className="colorfa8 ml5">*</span></div>
              <div className="mt12 mobilefield">
                <input type={togglePassword ? "text" : "password"} placeholder="8-10 Charecters" name="confirm_password" autoComplete='off' {...register("confirm_password", {
                  required: 'Password is required', minLength: { value: 5, message: 'Password is too short' }, maxLength: { value: 15, message: 'Password is too long' },
                  validate: (val) => {
                    if (watch('passwordFirst') !== val) {
                      return "Your passwords do no match";
                    }
                  },
                })}
                  onChange={handleChange}
                  value={passwordCnf}
                />
                <span className="eyeicon mr10" onClick={() => HideShowPassword(togglePassword)} />
              </div>
              <span className="text-danger font11"> {errors.confirm_password && errors.confirm_password.message}</span>
            </div>

            <button type={buttonSpinner ? "button" : "submit"} className="btn btn-primary btn-lg btn-block font13 fw500 mt20 continuebtn">{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Continue"}</button>
          </form>
        </div>

      </div>
    </>
  )
}
