import React, { Fragment, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import axioInstance from '../../axiosInstance';
import {  inWords } from '../../global';
import formatAmount from 'indian-currency-formatter';
import { Encryption } from '../../global';

export default function EditInvestmentAll({ investment, i, setActive, assetClassMain }) {

    const [buttonSpinner, setButtonSpinner] = useState(false);

    const Back = () => {
        setActive(false);
    }

    // eslint-disable-next-line
    const [investArr, setInvestArr] = useState([]);

    const name = sessionStorage.getItem("full_name");

    useEffect(() => {
        console.log("First call on mount..assetClassMain "+assetClassMain);

        //const typeArr = assetClassMain === "equity" ? [1, 2, 3, 5, 7, 11] : (assetClassMain === "debt" ? [1, 2, 3, 5, 7, 11] : [1, 2, 3, 5, 7, 11]);

        const typeArr = (assetClassMain === "equity" || assetClassMain === "Equity") ? [1] : ((assetClassMain === "debt" || assetClassMain === "Debt") ? [2] : ((assetClassMain === "epf" || assetClassMain === "Epf") ? [5] : [3, 4]));

        const profile_id = sessionStorage.getItem('profile_id')
        axioInstance.post(`investment/get-investment`, { profile_id: profile_id, type: typeArr }).then((response, data) => {

            console.log(response.data.length);
            if (response.data.length > 0) {
                setInvestArr(response.data);
            } else {
                setInvestArr([]);
            }

        });

        return () => console.log("Cleanup..");
    }, [assetClassMain]);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = data => {

        setButtonSpinner(true);

        data.investedArr.forEach((invest, index) => {


            if (invest.insertType === "insert") {

                if (invest.currentVal > 0) {

                    const Postdata = {
                        "prop_id": invest.invest_id ? Encryption(invest.profileId) : invest.profileId,
                        "profile_id": invest.invest_id ? Encryption(invest.profileId) : invest.profileId,
                        "profile_details_id": invest.invest_id ? Encryption(invest.profile_details_id) : invest.profile_details_id,
                        "type": invest.type,
                        "owner": invest.invest_id ? Encryption(invest.profileId) : invest.profileId,
                        "invest_type": 0,
                        "sub_type": invest.sub_type,
                        "instr_name": invest.instr_name,
                        "frequency": invest.frequency,
                        "current_value": invest.currentVal * invest.moneyDigits,
                        "cost_amount": invest.currentVal * invest.moneyDigits,
                        "amount": invest.invested * invest.pmoneyDigits,
                        "invest_amount": invest.invested * invest.pmoneyDigits,
                        "ppt": 1,
                        "policy_term": 1,
                        "invest_id": invest.invest_id,
                        "moneyDigits": invest.moneyDigits,
                        "value_total": invest.currentVal * invest.moneyDigits,
                        "admin_id":sessionStorage.getItem("admin_id")
                    };

                    axioInstance.post(`investment/add-investment`, Postdata)
                        .then(function (response) {

                        })

                }

            } else {

                if (invest.invest_id > 0) {

                    const Postdata = {
                        "prop_id": Encryption(invest.profileId),
                        "profile_id": Encryption(invest.profileId),
                        "profile_details_id": Encryption(invest.profile_details_id),
                        "type": invest.type,
                        "owner": Encryption(invest.profileId),
                        "invest_type": 0,
                        "sub_type": invest.sub_type,
                        "instr_name": invest.instr_name,
                        "frequency": invest.frequency,
                        "current_value": invest.currentVal * invest.moneyDigits,
                        "cost_amount": invest.currentVal * invest.moneyDigits,
                        "amount": invest.invested * invest.pmoneyDigits,
                        "invest_amount": invest.invested * invest.pmoneyDigits,
                        "ppt": 1,
                        "policy_term": 1,
                        "invest_id": invest.invest_id,
                        "moneyDigits": invest.moneyDigits,
                        "value_total": invest.currentVal * invest.moneyDigits,
                        "admin_id":sessionStorage.getItem("admin_id")
                    };

                    axioInstance.post(`investment/add-investment`, Postdata)
                        .then(function (response) {

                        })

                }

            }



        });


        setTimeout(() => {
            setButtonSpinner(false);
            window.location.reload(true)
        }, 2000);

    }

    // eslint-disable-next-line
    const [invested, setInvested] = useState(investment.amount > 0 ? formatAmount(Math.round(investment.amount / (investment.frequency > 0 ? investment.frequency : 1))) : '0');

    const handleChange = (e) => {

        const { value } = e.target;
        console.log(value);

        if (e.target.name === 'invested') {
            setInvested(formatAmount(value.replace(/,/g, "")));
        }

    };

    // eslint-disable-next-line
    const [maxZero, setMaxZero] = useState(3);
    // eslint-disable-next-line
    const [maxOne, setMaxOne] = useState(3);
    // eslint-disable-next-line
    const [maxTwo, setMaxTwo] = useState(3);
    // eslint-disable-next-line
    const [maxThree, setMaxThree] = useState(3);
    // eslint-disable-next-line
    const [maxFour, setMaxFour] = useState(3);
    // eslint-disable-next-line
    const [maxFive, setMaxFive] = useState(3);
    // eslint-disable-next-line
    const [maxSix, setMaxSix] = useState(3);
    // eslint-disable-next-line
    const [maxSeven, setMaxSeven] = useState(3);
    // eslint-disable-next-line
    const [maxEight, setMaxEight] = useState(3);


    const setMaxNumber = (value, index) => {

        if (value.indexOf('.') !== -1) {

            switch (index) {
                case 0:
                    setMaxZero(5);
                    break;

                case 1:
                    setMaxOne(5);
                    break;

                case 2:
                    setMaxTwo(5);
                    break;

                case 3:
                    setMaxThree(5);
                    break;

                case 4:
                    setMaxFour(5);
                    break;

                case 5:
                    setMaxFive(5);
                    break;

                case 6:
                    setMaxSix(5);
                    break;

                case 7:
                    setMaxSeven(5);
                    break;

                case 8:
                    setMaxEight(5);
                    break;

                default:
            }

        } else {

            switch (index) {
                case 0:
                    setMaxZero(3);
                    break;

                case 1:
                    setMaxOne(3);
                    break;

                case 2:
                    setMaxTwo(3);
                    break;

                case 3:
                    setMaxThree(3);
                    break;

                case 4:
                    setMaxFour(3);
                    break;

                case 5:
                    setMaxFive(3);
                    break;

                case 6:
                    setMaxSix(3);
                    break;

                case 7:
                    setMaxSeven(3);
                    break;

                case 8:
                    setMaxEight(3);
                    break;

                default:
            }

        }
    }


    // Function to calculate the formatted investment amount
    const calculateFormattedInvestment = (amount, frequency) => {
        // Calculate inv_amount based on amount and frequency
        const inv_amount = (amount !== null && frequency !== null) ? amount / frequency : amount ? amount : 0;

        if (inv_amount === 0) {
            // If inv_amount is 0, return 0
            return '0';
        } else {
            const numDigits = inv_amount.toString().length;
            // Determine the divisor based on the number of digits
            let divisor;
            if (numDigits >= 8) {
                divisor = 10000000;
            } else if (numDigits >= 6 && numDigits <= 7) {
                divisor = 100000;
            } else {
                divisor = 1000;
            }
            // Calculate the final formatted amount
            const formattedAmount = inv_amount / divisor;

            return formattedAmount;
        }
    };


    return (
        <>
            <Fragment>

                <div className="card card-body">
                    <div className="form_prnt">
                        <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>


                            {

                                investArr.length > 0 && (
                                    <>
                                        {
                                            investArr.map((investment, i) => {

                                                return (
                                                    <>
                                                        <input type="hidden" name={`investedArr[${i}]type`} {...register(`investedArr.${i}.type`)} defaultValue={investment.type} />
                                                        <input type="hidden" name={`investedArr[${i}]sub_type`} {...register(`investedArr.${i}.sub_type`)} defaultValue={investment.sub_type} />
                                                        <input type="hidden" name={`investedArr[${i}]instr_name`} {...register(`investedArr.${i}.instr_name`)} defaultValue={investment.instr_name} />
                                                        <input type="hidden" name={`investedArr[${i}]invest_id`} {...register(`investedArr.${i}.invest_id`)} defaultValue={investment.invest_id} />
                                                        <input type="hidden" name={`investedArr[${i}]insertType`} {...register(`investedArr.${i}.insertType`)} defaultValue={investment.invest_id > 0 ? 'update' : 'insert'} />

                                                        <input type="hidden" name={`investedArr[${i}]profileId`} {...register(`investedArr.${i}.profileId`)} defaultValue={investment.profile_id !== null ? investment.profile_id : sessionStorage.getItem("profile_id")} />
                                                        <input type="hidden" name={`investedArr[${i}]profile_details_id`} {...register(`investedArr.${i}.profile_details_id`)} defaultValue={investment.profile_details_id !== null ? investment.profile_details_id : sessionStorage.getItem('profile_details_id')} />




                                                        <div className="form_prnt">
                                                            <div className="expandtab font12 color353 fw500">{investment.type === parseInt("4") ? investment.asset_type +" - "+investment.type_name : investment.asset_type}</div>
                                                            <div className="expandtab font12 color353 fw500">{investment.full_name !== "" ? investment.full_name : name} <b>{inWords(investment.current_value)}</b></div>


                                                            <div className="custome_form">

                                                                <div className="form-row mt20">
                                                                    <div className="col-md-12 pr20">
                                                                        <label htmlFor={'investedArrCurr' + i} className="font14 fw500">Current value</label>
                                                                        <div className="input-group selectarrow">

                                                                            <input type="text" className="form-control font14 color212 fw500" id={'childName' + i} name={`investedArr[${i}]currentVal`} {...register(`investedArr.${i}.currentVal`, { required: 'Required.' })} defaultValue={investment.current_value > 0 ? investment.current_value.toString().length >= 8 ? investment.current_value / 10000000 : investment.current_value / 100000 : 0} maxLength={
                                                                                i === 0 ? maxZero : i === 1 ? maxOne : i === 2 ? maxTwo : i === 3 ? maxThree : i === 4 ? maxFour : i === 5 ? maxFive : i === 6 ? maxSix : i === 7 ? maxSeven : i === 8 ? maxEight : maxZero
                                                                            } minLength={1} onChange={(e) => setMaxNumber(e.target.value, i)} />


                                                                            <select className="custom-select font14" name={`investedArr[${i}]moneyDigits`} {...register(`investedArr.${i}.moneyDigits`)} defaultValue={investment.current_value.toString().length >= 8 ? 10000000 : 100000}>
                                                                                <option value="100000">Lakhs</option>
                                                                                <option value="10000000">Crore</option>
                                                                            </select>
                                                                            <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                                                        </div>
                                                                        <span className="text-danger"> {errors.investedArr?.[i]?.currentVal?.message} </span>
                                                                    </div>
                                                                </div>

                                                                <div className="form-row mt20">
                                                                    <div className="col-md-12 pr20">


                                                                        <label htmlFor={'investedArrInvest' + i} className="font14 fw500"> Periodic investment</label>

                                                                        <div className="input-group selectarrow">

                                                                            <input
                                                                                type="text"
                                                                                className="form-control font14 color212 fw500"
                                                                                id={'investedArrInvest' + i}
                                                                                name={`investedArr[${i}]invested`}
                                                                                {...register(`investedArr.${i}.invested`, { required: 'Required.' })}
                                                                                defaultValue={calculateFormattedInvestment(investment.amount, investment.frequency)}
                                                                                onChange={(e) => { setMaxNumber(e.target.value, i); handleChange(e) }}
                                                                                maxLength={
                                                                                    i === 0 ? maxZero : i === 1 ? maxOne : i === 2 ? maxTwo : i === 3 ? maxThree : i === 4 ? maxFour : i === 5 ? maxFive : i === 6 ? maxSix : i === 7 ? maxSeven : i === 8 ? maxEight : maxZero
                                                                                }
                                                                                minLength={1}
                                                                            />


                                                                            <select className="custom-select font14" id="inputGroupSelect01" name={`investedArr[${i}]pmoneyDigits`} {...register(`investedArr.${i}.pmoneyDigits`)}
                                                                                defaultValue={investment.periodic.toString().length >= 8 ? 10000000 : (investment.periodic.toString().length >= 6 && investment.periodic.toString().length < 8) ? 100000 : (investment.periodic.toString().length >= 3 && investment.periodic.toString().length < 6) ? 1000 : 100000}>
                                                                                <option value="10000000">Crore</option>
                                                                                <option value="100000" >Lakhs</option>
                                                                                <option value="1000">Thous.</option>
                                                                            </select>
                                                                            <select className="custom-select font14" name={`investedArr[${i}]frequency`} {...register(`investedArr.${i}.frequency`)} defaultValue={investment.frequency}>
                                                                                <option value="12">Monthly</option>
                                                                                <option value="4">Quarterly</option>
                                                                                <option value="2">Half Yearly</option>
                                                                                <option value="1">Yearly</option>
                                                                                <option value="0">Lumpsum</option>
                                                                            </select>
                                                                            <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                                                        </div>
                                                                        <span className="text-danger"> {errors.invested && errors.invested.message}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="formdevider mt25 mb25" />

                                                    </>
                                                )
                                            })
                                        }
                                    </>
                                )
                            }



                            <div className="saveBtn backsaveBtn">
                                <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                                <button type={buttonSpinner ? "button" : "submit"} className="btn btn-primary" >{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> Updating</> : "Save"} </button>
                            </div>

                        </form>
                    </div>
                </div>
            </Fragment>
        </>
    )
}
