import React, { Fragment } from 'react'
import axioInstance from './axiosInstance'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'

export default function AddDirectProfileSidebar({ setActive }) {

    const Back = () => {
        setActive(false);
    }

    // eslint-disable-next-line
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = Formdata => {


        const search = window.location.search;
        const params = new URLSearchParams(search);
        const utm_source = params.get('utm_source');
        const utm_campaign = params.get('utm_campaign');
        const utm_id = params.get('utm_id');

        const Postdata = {
            "full_name": Formdata.fullName,
            "mobile": Formdata.mobileNumber,
            "email": Formdata.emailAddress,
            "password": Formdata.password,
            "utm_source": utm_source,
            "utm_campaign": utm_campaign,
            "utm_id": utm_id,
            "a_from": "adminDashboard",
            "created_by": sessionStorage.getItem("admin_id")
        };

        axioInstance.post(`add-guestuser`, Postdata).then(function () {
            SweetAlert.fire({
                title: 'Successfully added Guest User',
                text: "Please verify Guest user with Admin to activate the profile.",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OKAY'
            }).then((result) => {
                window.location.href = "admin-dashboard";
            });
        })
    }


    return (
        <Fragment>
            <div className="rightSide_menu_outer">
                <div className="rightSide_menu">

                    <div className="rightSide_menu_header">
                        <div>
                            <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
                        </div>
                        <div className="font14 fw500 color182">
                            Add Guest User
                        </div>
                        <div>&nbsp;</div>
                    </div>
                    <div className="topBanner pl25">
                        <img src='/images/rupeee.png' alt='img' className='sidebarRupeeeIcon' />
                        <div className="font18 fw600 color182">Add Guest User</div>
                        <div className="font14 fw500 color485">For Skipping OTP Purpose</div>
                    </div>

                    <div className="form_prnt p20">

                        <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

                            <div className="form-group">
                                <label>Your full name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    {...register("fullName", { required: "Full name is required" })}
                                    placeholder="Enter full name"
                                    autoComplete="off"
                                />
                                {errors.fullName && <span className='redtext'>{errors.fullName.message}</span>}
                            </div>

                            <div className="form-group">
                                <label>Email address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    {...register("emailAddress", {
                                        required: "Email address is required",
                                        pattern: {
                                            value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                                            message: "Enter a valid email address"
                                        }
                                    })}
                                    placeholder="Enter email address"
                                    autoComplete="nope"
                                />
                                {errors.emailAddress && <span className='redtext'>{errors.emailAddress.message}</span>}
                            </div>

                            <div className="form-group">
                                <label>Mobile number</label>
                                <div className="input-group">
                                    <span className="input-group-text" id="basic-addon1">+91</span>
                                    <input
                                        type="tel"
                                        className="form-control"
                                        {...register("mobileNumber", {
                                            required: "Mobile number is required",
                                            pattern: {
                                                value: /^[0-9]{10}$/,
                                                message: "Enter a valid mobile number"
                                            }
                                        })}
                                        placeholder="Mobile Number"
                                        autoComplete="off"
                                    />
                                </div>
                                {errors.mobileNumber && <span className='redtext'>{errors.mobileNumber.message}</span>}
                            </div>

                            <div className="form-group">
                                <label>Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    {...register("password", { required: "Password is required" })}
                                    placeholder="Enter password"
                                    autoComplete="new-password"
                                />
                                {errors.password && <span className='redtext'>{errors.password.message}</span>}
                            </div>

                            <div className="saveBtn backsaveBtn">
                                <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                                <button type="submit" className="btn btn-primary">Save </button>
                            </div>

                        </form>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}
