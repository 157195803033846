import React, { Fragment, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import axioInstance from '../../axiosInstance';
import {  ageCalculate } from '../../global';
//import formatAmount from 'indian-currency-formatter';
import RangeSlider from 'react-bootstrap-range-slider';
import { Encryption } from '../../global';

export default function AddVacation({ setAddGoal, aspire }) {

  const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();

  const [buttonSpinner, setButtonSpinner] = useState(false);

  const Back = () => {
    setAddGoal('');
  }
  // eslint-disable-next-line
  const [max, setMax] = useState(30);

  const onSubmit = data => {

    setButtonSpinner(true);

    const age = profileAge;
    const required_after = requiredAfter < 1 ? 1 :requiredAfter;
    const required_till = parseInt(required_after) + parseInt(requiredFor);
    const PostData = {
      "goal_type": "Vacation",
      "aspire": "Vacation",
      "goal_name": "Vacation",
      "profile_id": sessionStorage.getItem('profile_id'),
      "plan_for": {
        "profile_details_id": Encryption(data.plan_for),
        "current_age": age < 1 ? 1 : age,
      },
      "yearly_expense": {
        "0": data.amount*data.moneyDigits,
        "1": 0
      },
      "no_years": {
        "0": requiredFor,
        "1": 0
      },
      "goal_params": {
        "age": age < 1 ? 1 : age,
        "post_inflation": 7,
        "post_return": 7,
        "goal_inflation": 7,
        "required_after": required_after,
        "required_till": required_till
      },
      "risk_profile": {
        "available": false,
        "pre_return": 8,
        "risk_profile_id": sessionStorage.getItem('profile_id')
      },
      "assumptions": {
        "assumption_id": false,
        "inflation": 7.5,
        "income_grow": "10"
      },
      "total_abcd": [{
        "amount": data.amount*data.moneyDigits,
        "frequency": 12,
        "no_of_times": 1,
        "gap": 0
      }],
      "actual": [{
        "actual_y": 0,
        "frequency": 1,
        "actual_ls": 1,
        "grow": "0"

      }]
    };


    axioInstance.post(`goals/add`, PostData)
      .then(function (response) {
        setAddGoal('');

        const profile_id = sessionStorage.getItem('profile_id');
        const gpostData = { profile_id: profile_id };
        axioInstance.post(`goals/reset`, gpostData)
          .then(function (response) {
            axioInstance.post(`profile/summary-update`, { profile_id: profile_id }).then(
              (response, data) => {
                setButtonSpinner(false);
                window.location.reload();
              }
            );
          })

      });

  }



  const [requiredFor, setRequiredFor] = useState(5);
  const [requiredAfter, setRequiredAfter] = useState(5);

  const onChangeFor = (e) => {
    setRequiredFor(e.currentTarget.value);
  };

  const onChangeAfter = (e) => {
    setRequiredAfter(e.currentTarget.value);

    console.log(e.currentTarget.value);
  };

  const [goalFor, setGoalFor] = useState([]);

  useEffect(() => {
    console.log("First call on mount..");

    axioInstance.post(`profile-details/getfamily`, {profile_id:sessionStorage.getItem('profile_id')}).then(function (response, data) {

      setGoalFor(response.data)

    });

    return () => console.log("Cleanup..");
  }, []);

  const [profileAge, setProfileAge] = useState();

  const profileSelect = (e) => {

    setProfileAge(ageCalculate(e.target[e.target.selectedIndex].getAttribute('g_dob')));

  };

  return (
    <Fragment>
      <div className="rightSide_menu_outer">
        <div className="rightSide_menu">

          <div className="rightSide_menu_header">
            <div>
              <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
            </div>
            <div className="font14 fw500 color182">
              Add {aspire} Goal
            </div>
            <div>&nbsp;</div>
          </div>
          <div className="topBanner topBanner_otherGoals pl25">
            <div className="font18 fw600 color182 mt50">{aspire}</div>
            <div className="font14 fw500 color485">Add {aspire} plan in your financial journey</div>
          </div>

          <div className="form_prnt p20">
            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

              <div className="form-row">
                <div className="col-md-12 pr20">

                  <div className="font14 color212 fw600">Goal Focused On</div>
                  <div className="selectgoal propertysearch mt10">
                    <div className="input-group selectarrow">
                      <select className="custom-select font14 color212 fw500" id="inputGroupSelect01" name='plan_for' {...register("plan_for", { required: 'Required' })} onChange={profileSelect}>
                        <option value="">select</option>

                        {
                          goalFor.map((prof_det, i) => {

                            return (
                              <>
                                <option value={prof_det.profile_details_id} g_dob={prof_det.dob}>{prof_det.full_name}</option>
                              </>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>

                  <span className="text-danger"> {errors.amount && errors.amount.message}</span>
                </div>
              </div>

              <div className="formdevider mt25 mb25" />

              <div className="form-row mt20">
                <div className="col-md-12 pr20">
                  <label htmlFor="inputEmail4" className="font14 fw500">Required Amount as of Today</label>
                  <div className="input-group selectarrow">
                    <input type="text" className="form-control font14 color212 fw500" name="amount" {...register("amount", { required: 'Cost of house today required' })} />
                    <select className="custom-select font14" id="inputGroupSelect01" name='moneyDigits' {...register("moneyDigits")}>
                      <option selected value="100000">Lakhs</option>
                      <option value="10000000">Crore</option>
                    </select>
                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                  </div>
                  <span className="text-danger"> {errors.amount && errors.amount.message}</span>
                </div>
              </div>

              <div className="devider_line mt25 mb25" />

              <div className="rangesliderbox">
                <div className="rangesliderbox_title">
                  <div>
                    <div className="font14 fw500 color182">Required After</div>
                    <div className="font12 fw500 color8c9">Adjust the estimate year to achive this goal</div>
                  </div>
                  <div className="tragetvalue font13 fw700">{requiredAfter}</div>
                </div>
                <div className="rangebox_bar mt12">
                  <div className="form-group">


                    <RangeSlider
                      value={requiredAfter}
                      onChange={onChangeAfter}
                      step={1}
                      min={1}
                      max={25}
                      className={"form-control-range"}
                      tooltip={"off"}
                      defaultValue={1}
                    />


                  </div>
                </div>
                <div className="flex_center justify_center mt8">
                  <div className="font12 fw500 color9ba">1</div>
                  <div className="font12 fw500 color9ba">25</div>
                </div>

              </div>


              <div className="devider_line mt25 mb25" />

              <div className="rangesliderbox">
                <div className="rangesliderbox_title">
                  <div>
                    <div className="font14 fw500 color182">Required For</div>
                    <div className="font12 fw500 color8c9">Adjust the estimate year to achive this goal</div>
                  </div>
                  <div className="tragetvalue font13 fw700">{requiredFor}</div>
                </div>
                <div className="rangebox_bar mt12">
                  <div className="form-group">


                    <RangeSlider
                      value={requiredFor}
                      onChange={onChangeFor}
                      step={1}
                      min={1}
                      max={25}
                      className={"form-control-range"}
                      tooltip={"off"}
                      defaultValue={1}
                    />


                  </div>
                </div>
                <div className="flex_center justify_center mt8">
                  <div className="font12 fw500 color9ba">1</div>
                  <div className="font12 fw500 color9ba">25</div>
                </div>

              </div>

              <div className="saveBtn backsaveBtn">
                <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                <button type={buttonSpinner ? "button" : "submit"} className="btn btn-primary" disabled={!isDirty && !isValid}>{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> adding</> : "Save"} </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  )
}