import React, { Fragment } from 'react'
import axioInstance from './axiosInstance'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'
import { Encryption } from './global'

export default function AddTestProfileSidebar({ setActive }) {

    const Back = () => {
        setActive(false);
    }

    // eslint-disable-next-line
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = data => {

        const postData = {
            "name": data.fullName,
            "mobile": data.mobileNumber,
            "email": data.emailAddress,
            "password": data.password,
            "register": 1,
            "testProfile": 1,
            "registerVia": 1,
            "rel_manager": sessionStorage.getItem('admin_id')
        };

        axioInstance.post(`register`, postData)
            .then(function (response) {
                console.log('response.data.token', response.data.token);


                sessionStorage.setItem('token', response.data.token);

                axioInstance.post(`profile/add`, postData).then(function (response) {
                    const profile_id  = Encryption(response.data.profile_id);
                    sessionStorage.setItem('profile_id', Encryption(response.data.profile_id));
                    sessionStorage.setItem('profile_det_id', Encryption(response.data.profile_det_id.profile_details_id));
                    axioInstance.post(`default-risk-profile`, { profile_id: Encryption(response.data.profile_id) }).then(function (response) {
                    });

                    axioInstance.post(`profile/add-default-assumption`, { profile_id: Encryption(response.data.profile_id) }).then(function (response) {

                        if(response){
                            axioInstance.post(`profile/summary-add`, { profile_id: profile_id }).then(function (response) {
                                window.location.reload();
                            });
                        }
                    });

                    

                });

            })
            .catch(e => {

                if (e.response.data.data.hasOwnProperty("password")) {
                    SweetAlert.fire({
                        toast: true,
                        icon: 'error',
                        title: e.response.data.data.password[0],
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                            toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                        }
                    });
                } else {

                    SweetAlert.fire({
                        toast: true,
                        icon: 'error',
                        title: e.response.data.data.email[0],
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                            toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                        }
                    });
                }
            });

    }


    return (
        <Fragment>
            <div className="rightSide_menu_outer">
                <div className="rightSide_menu">

                    <div className="rightSide_menu_header">
                        <div>
                            <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
                        </div>
                        <div className="font14 fw500 color182">
                            Add Testing Profile
                        </div>
                        <div>&nbsp;</div>
                    </div>
                    <div className="topBanner pl25">
                        <img src='/images/rupeee.png' alt='img' className='sidebarRupeeeIcon' />
                        <div className="font18 fw600 color182">Add Testing Profile</div>
                        <div className="font14 fw500 color485">For Debug & Testing Purpose</div>
                    </div>

                    <div className="form_prnt p20">

                        <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

                            <div className="form-group">
                                <label>Your full name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    {...register("fullName", { required: "Full name is required" })}
                                    placeholder="Enter full name"
                                    autoComplete="off"
                                />
                                {errors.fullName && <span className='redtext'>{errors.fullName.message}</span>}
                            </div>

                            <div className="form-group">
                                <label>Email address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    {...register("emailAddress", {
                                        required: "Email address is required",
                                        pattern: {
                                            value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                                            message: "Enter a valid email address"
                                        }
                                    })}
                                    placeholder="Enter email address"
                                    autoComplete="nope"
                                />
                                {errors.emailAddress && <span className='redtext'>{errors.emailAddress.message}</span>}
                            </div>

                            <div className="form-group">
                                <label>Mobile number</label>
                                <div className="input-group">
                                    <span className="input-group-text" id="basic-addon1">+91</span>
                                    <input
                                        type="tel"
                                        className="form-control"
                                        {...register("mobileNumber", {
                                            required: "Mobile number is required",
                                            pattern: {
                                                value: /^[0-9]{10}$/,
                                                message: "Enter a valid mobile number"
                                            }
                                        })}
                                        placeholder="Mobile Number"
                                        autoComplete="off"
                                    />
                                </div>
                                {errors.mobileNumber && <span className='redtext'>{errors.mobileNumber.message}</span>}
                            </div>

                            <div className="form-group">
                                <label>Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    {...register("password", { required: "Password is required" })}
                                    placeholder="Enter password"
                                    autoComplete="new-password"
                                />
                                {errors.password && <span className='redtext'>{errors.password.message}</span>}
                            </div>

                            <div className="form-group">
                                <input
                                    type="checkbox"
                                    {...register("excludeLms")}
                                    checked
                                    disabled
                                />
                                <label>Exclude LMS</label>
                            </div>

                            <div className="saveBtn backsaveBtn">
                                <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                                <button type="submit" className="btn btn-primary">Save </button>
                            </div>

                        </form>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}
