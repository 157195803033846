import React, { Fragment, useState, useEffect } from 'react';
import axioInstance from '../components/axiosInstance';
import { useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import AdminDashboardHeader from '../components/layout/AdminDashboardHeader';
import AddTestProfileSidebar from '../components/AddTestProfileSidebar';
import { Dropdown } from 'react-bootstrap'
import { Encryption } from '../components/global';
export default function ReportProfileTimeline() {
  let navigate = useNavigate();
  /*sessionStorage.setItem('admin_id', "1");
  sessionStorage.setItem('admin_role', "0");*/
  // eslint-disable-next-line
  const [data, setData] = useState([])
  const [tableRowsData, setTableRowsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedPlanner, setSelectedPlanner] = useState('ALL');
  const [selectedRelManager, setSelectedRelManager] = useState('ALL');
  
  // State for the input values
  const [filters, setFilters] = useState({
    plan_status: '',
    plan_mapped: '',
    plan_date: '',
    date_filter: '',
    filterDate: '',
    plan_anniversary_years: '',
    plan_anniversary: ''
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [id]: value
    }));
  };

  const handleFilter = () => {

    setLoading(true);

    const queryParams = new URLSearchParams(filters).toString();
    const url = `plan-profiles-timeline?${queryParams}`;
    window.location.href = url;

  }

  const resetFilter = () => {

    window.location.href = 'plan-profiles-timeline';

  }

  const loginUser = (id, emailAddress) => {
    axioInstance.get(`admin/user-login/` + id).then(
      (response, data) => {
        sessionStorage.setItem('token', response.data.token);
        const pdata = { "email": Encryption(emailAddress) };
        axioInstance.post(`profile/view`, pdata).then(function (response) {
          sessionStorage.setItem('profile_id', Encryption(response.data.profile_id));
          sessionStorage.setItem('full_name', response.data.first_name + '\'s');
          sessionStorage.setItem('profile_details_id', Encryption(response.data.profile_details_id));
          if (response.data.step > 3) {
            window.location.href = "/dashboard";
          } else {
            window.location.href = "/basic-profile";
          }
        });
      }
    );
  }

  const [planStatus, setPlanStatus] = useState('');
  // eslint-disable-next-line
  const [planMapped, setPlanMapped] = useState('');
  // eslint-disable-next-line
  const [planDate, setPlanDate] = useState('');
  // eslint-disable-next-line
  const [dateFilter, setDateFilter] = useState('');
  // eslint-disable-next-line
  const [filterDate, setFilterDate] = useState('');
  // eslint-disable-next-line
  const [planAnniversaryYears, setPlanAnniversaryYears] = useState('');
  // eslint-disable-next-line
  const [planAnniversary, setPlanAnniversary] = useState('');

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("admin_id");
    console.log(loggedInUser);
    if (loggedInUser === null) {
      navigate("/ulogin");
    } else {

      const params = new URLSearchParams(window.location.search);
      const plan_status = params.get('plan_status') ? params.get('plan_status') : '';
      const plan_mapped = params.get('plan_mapped') ? params.get('plan_mapped') : '';
      const plan_date = params.get('plan_date') ? params.get('plan_date') : '';
      const date_filter = params.get('date_filter') ? params.get('date_filter') : '';
      const filterDate = params.get('filterDate') ? params.get('filterDate') : '';
      const plan_anniversary_years = params.get('plan_anniversary_years') ? params.get('plan_anniversary_years') : '';
      const plan_anniversary = params.get('plan_anniversary') ? params.get('plan_anniversary') : '';

      setFilters({
        plan_status: plan_status,
        plan_mapped: plan_mapped,
        plan_date: plan_date,
        date_filter: date_filter,
        filterDate: filterDate,
        plan_anniversary_years: plan_anniversary_years,
        plan_anniversary: plan_anniversary
      });
      
      if (plan_status) {
        setPlanStatus(plan_status);
      }

      if (plan_mapped) {
        setPlanMapped(plan_mapped);
      }

      if (plan_date) {
        setPlanDate(plan_date);
      }

      if (date_filter) {
        setDateFilter(date_filter);
      }

      if (filterDate) {
        setFilterDate(filterDate);
      }

      if (plan_anniversary_years) {
        setPlanAnniversaryYears(plan_anniversary_years);
      }

      if (plan_anniversary) {
        setPlanAnniversary(plan_anniversary);
      }

      var PostData = '';

      PostData = {
        plan_status: plan_status,
        plan_mapped: plan_mapped,
        plan_date: plan_date,
        date_filter: date_filter,
        filterDate: filterDate,
        plan_anniversary_years: plan_anniversary_years,
        plan_anniversary: plan_anniversary
      }

      const filteredPostData = Object.fromEntries(
        Object.entries(PostData).filter(([_, value]) => value !== '')
      );

      axioInstance.post(`admin/plan-profiles-timeline`, filteredPostData).then(
        (response, data) => {
          //console.log(response.data);
          setData(response.data.data);
          setTableRowsData(response.data.data);
          setLoading(false);
        }
      );

      axioInstance.get(`admin/get-admins`).then(
        (response, data) => {
          //console.log(response.data);
          setRelManager(response.data);
        }
      );



    }
  }, [navigate]);




  const uniquePlanners = [...new Set(data.map(item => item.admin_name))].filter(planner => planner);

  const uniqueRelManager = [...new Set(data.map(item => item.primary_rel_manager))].filter(manager => manager);

  useEffect(() => {
    let filteredData = data;

    if (selectedPlanner !== 'ALL') {
      filteredData = filteredData.filter(item => item.admin_name === selectedPlanner);
    }

    if (selectedRelManager !== 'ALL') {
      filteredData = filteredData.filter(item => item.primary_rel_manager === selectedRelManager);
    }

    setTableRowsData(filteredData);
  }, [selectedPlanner, selectedRelManager, data]);

  const columns = [
    {
      name: 'ID',
      selector: row => row.profile_id,
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Primary Planner',
      selector: row => row.primary_rel_manager,
      sortable: true,
    },
    {
      name: 'Plan Created',
      selector: row => row.created_date,
      sortable: false,
    },
    {
      name: 'Step Completed',
      selector: row => row.steps_completed_date,
      sortable: false,
    },
    {
      name: 'Plan Completed',
      selector: row => row.plan_completed_date,
      sortable: false,
    },
    {
      name: 'Risk Done',
      selector: row => row.risk_profile_date,
      sortable: false,
    },
    {
      name: 'Shared Date',
      selector: row => row.shared_date,
      sortable: false,
    },
    {
      name: 'Freezed Date',
      selector: row => row.freezed_date,
      sortable: false,
    },
    {
      name: 'Execution Date',
      selector: row => row.execution_date,
      sortable: false,
    },
    {
      name: 'Action',
      selector: row => <></>,
      cell: (d) => <><span className='cursor_pointer' onClick={() => loginUser(d.id, d.email)}><i className="fas fa-sync-alt" style={{ width: 35, fontSize: 16, padding: 11, color: '#06B153' }}></i></span></>,
    },
  ];



  /*const [title, setTitle] = useState("");
  const onChange = async (e) => {
    setTitle(e.target.value);
    // eslint-disable-next-line
    var searchData = data.filter((item) => {


      if (
        item.name
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }

      if (
        item.email
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }


    });
    setTableRowsData(searchData);
  };

  const relmanagerFilter = async (e) => {
    // eslint-disable-next-line
    var searchData = data.filter((item) => {

      if (
        parseInt(item.admin_id) === parseInt(e.target.value)
      ) {
        return item;
      }


    });


    setTableRowsData(e.target.value > 0 ? searchData : data);
  }
*/

  // eslint-disable-next-line
  const [relManager, setRelManager] = useState([])


  const [isActive, setActive] = useState(false);

  if (loading) {
    return (
      <div className="loader-container">
        <div className="spinner"></div>
      </div>
    );
  }


  return (
    <Fragment>
      <AdminDashboardHeader />

      <div className="innercontainer isPlanProfilesTimelinePage mb40">
        <div className="mainwrapper">
          <div className='form_title'><div className="mt30 font21 fw600 color384">PLAN Profile Timeline</div></div>

          <div className='row mt25'>

            <div className="col-md-3">
              <label htmlFor="relManager">REL Manager:<small style={{ fontSize: '9px' }}>(DataTable Filter)</small></label>
              <select id="relManager" onChange={(e) => setSelectedRelManager(e.target.value)} value={selectedRelManager} className="form-control">
                <option value="ALL">ALL</option>
                {uniqueRelManager.map(manager => (
                  <option key={manager} value={manager}>{manager}</option>
                ))}
              </select>
            </div>

            <div className="col-md-3">
              <label htmlFor="primaryPlanner">Primary Planner:<small style={{ fontSize: '9px' }}>(DataTable Filter)</small></label>
              <select id="primaryPlanner" onChange={(e) => setSelectedPlanner(e.target.value)} value={selectedPlanner} className="form-control">
                <option value="ALL">ALL</option>
                {uniquePlanners.map(planner => (
                  <option key={planner} value={planner}>{planner}</option>
                ))}
              </select>
            </div>

            <div className="col-md-3">
              <label htmlFor="plan_status">Plan Status:</label>
              <select id="plan_status" name="plan_status" className="form-control" onChange={handleInputChange} defaultValue={planStatus}>
                <option value="">ALL</option>
                <option value="1">In Progess</option>
                <option value="3">Plan Completed</option>
                <option value="5">Shared</option>
                <option value="7">Freezed</option>
                <option value="9">Execution Done</option>
              </select>
            </div>

            <div className="col-md-3">
              <label htmlFor="plan_mapped">Plan Mapped:</label>
              <select id="plan_mapped" name="plan_mapped" className="form-control" onChange={handleInputChange} defaultValue={planMapped}>
                <option value="">ALL</option>
                <option value="mapped">Mapped</option>
                <option value="Unmapped">Unmapped</option>
              </select>
            </div>

            <div className="col-md-3 mt-4">
              <label htmlFor="plan_date">Status Filter:</label>
              <select id="plan_date" name="plan_date" className="form-control" onChange={handleInputChange} defaultValue={planDate}>
                <option value="">ALL</option>
                <option value="not_complete">Not completed 15 days</option>
                <option value="not_shared">Not shared 15 days</option>
                <option value="not_freeze">Not freeze 15 days </option>
              </select>
            </div>

            <div className="col-md-3 mt-4">
              <label htmlFor="date_filter">Select Date Filter:</label>
              <select id="date_filter" name="date_filter" className="form-control" onChange={handleInputChange} defaultValue={dateFilter}>
                <option value="created_date">Created Date</option>
                <option value="shared_date">Shared Date</option>
                <option value="execution_date">Execution Date</option>
              </select>
            </div>

            <div className="col-md-3 mt-4">
              <label htmlFor="filterDate">Select Date after Dropdown:</label>
              <input type="date" name="filterDate" id="filterDate" className="form-control" onChange={handleInputChange} defaultValue={filterDate} />
            </div>

            <div className="col-md-3 mt-4">
              <label htmlFor="plan_anniversary_years">Anniversary In Year:</label>
              <select id="plan_anniversary_years" name="plan_anniversary_years" className="form-control" onChange={handleInputChange} defaultValue={planAnniversaryYears}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>

            <div className="col-md-3 mt-4">
              <label htmlFor="planAnniversary">Plan Anniversary:</label>
              <input type="date" name="plan_anniversary" id="plan_anniversary" className="form-control" onChange={handleInputChange} defaultValue={planAnniversary} />
            </div>



            <div className="col-md-1 mt-4">
              <button className="btn btn-primary mt-4" type='button' onClick={handleFilter}>Filter</button>
            </div>
            <div className="col-md-1 mt-4">
              <button className="btn btn-secondary mt-4" type='button' onClick={resetFilter}>Reset</button>
            </div>





            {/*<div className='col-md-3'>
              <div className="font14 color212 fw600">Rel Manager Filter</div>
              <select className="custom-select font14 color212 fw500" name='relManager' onChange={(e) => relmanagerFilter(e)}>
                <option value="">Select Rel Manager</option>
                {relManager.map((data, i) => {
                  return (
                    <option value={data.admin_id} key={i}>{data.admin_name}</option>
                  )
                })}
              </select>

            </div>*/}

            <div className='col-md-3 mt-4'>
              <Dropdown className="mt-4">
                <Dropdown.Toggle id="dropdown-basic" className="planstatus_dots">
                  Plan Reports
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href='admin-dashboard'><i className="fas fa-calendar text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i> Admin Dashboard</Dropdown.Item>
                  <Dropdown.Item href='plan-profiles-status'><i className="fas fa-users text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i>Profile Plan Status</Dropdown.Item>
                  <Dropdown.Item href='plan-summary-report-drilldown-month'><i className="fas fa-bar-chart text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i>Summary Month Wise</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>


          </div>


          <div className="">
            <div className="dashboard_outer mt30">
              <div className="dashboard_table">
                <div className="graphtabs_outer mt25">
                  <div className="graph_container">
                    <div className="dashboardTable">
                      <div className="table-responsive">
                        <DataTable
                          columns={columns}
                          data={tableRowsData}
                          pagination
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        (isActive === true) && (<AddTestProfileSidebar setActive={setActive} />)
      }

    </Fragment >
  );
}