import React from 'react'
import '../../css/header.css'
import '../../css/style.css'
import '../../css/dashboard.css'
import '../../css/plan.css'
import { useNavigate } from 'react-router-dom'
import axioInstance from '../axiosInstance'

export default function DashboardHeader() {

    let navigate = useNavigate();


    const loggedInUser = sessionStorage.getItem("token");
    if (loggedInUser === null) {
        navigate("/sign-in");
    }

    const backtoAdmin = () => {
        navigate("/admin-dashboard");
    }

    const page = window.location.pathname;

    const logOut = () =>{

        var user_type = sessionStorage.getItem('admin_id') ? 'adminclient' : 'client';
        axioInstance.post(`login-logout-logs`, { user_type: user_type, action: 'logout', admin_id: sessionStorage.getItem('admin_id'), profile_id:sessionStorage.getItem('profile_id') }).then(function (response, data) {

            sessionStorage.clear();
            navigate("/sign-in");

        });

    }

    return (
        <>
            <div className="headerWpr mainwrapper">
                <div className="innercontainer">
                    <div className="hdrCol pt12 pb12">
                        <div className="hdrleft font13 fw500 color182">
                            <span className="finnvoate_logo mr12"><img src='images/finnoNewLogo.jpg' alt='logo' /></span> <span className="dn">Welcome to Finnovate,</span>
                            <ul className="infoTabs font13">
                                <li>
                                    <a href="/dashboard" className={page === '/dashboard' ? "act" : ""}>Dashboard</a>
                                </li>
                                <li>
                                    <a href="/fp-report" className={page === '/fp-report' ? "act" : ""}>Plan</a>
                                </li>
                                <li>
                                    <a href="/#">Advisory</a>
                                </li>
                                <li>
                                    <a href="/#">Track</a>
                                </li>
                            </ul>
                        </div>
                        <div className="hdrmiddle dn">
                            <ul className="infosteps font12 fw500">
                                <li className="active_done">
                                    <div className="stepsiconbox">
                                        <span className="stepIcon basicIcon_white" />
                                        <span className="stepstext">Basic Details</span>
                                    </div>
                                </li>
                                <li className="active_done">
                                    <div className="stepsiconbox">
                                        <span className="stepIcon protection_clr" />
                                        <span className="stepstext">Protection</span>
                                    </div>
                                </li>
                                <li className="active_done">
                                    <div className="stepsiconbox">
                                        <span className="stepIcon goal_clr" />
                                        <span className="stepstext">Goals</span>
                                    </div>
                                </li>
                                <li className="active">
                                    <div className="stepsiconbox ">
                                        <span className="stepIcon cashflow_clr" />
                                        <span className="stepstext">Cashflow</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="stepsiconbox ">
                                        <span className="stepIcon risk" />
                                        <span className="stepstext"></span>
                                    </div>
                                </li>
                                <li>
                                    <div className="stepsiconbox ">
                                        <span className="stepIcon lock" />
                                        <span className="stepstext"></span>
                                    </div>
                                </li>
                                <li>
                                    <div className="stepsiconbox ">
                                        <span className="stepIcon rupee_clr" />
                                        <span className="stepstext"></span>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className="hdrright d-none d-md-block">


                            <div className="notification_icon mr20" />

                            <span className="profileimg mr10"><img src='images/user.png' alt='User Profile'/></span>

                            <span className="hdr_downarrow">
                                <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 3.68852L8.2821 0.303855C8.66657 -0.0926305 9.29966 -0.10237 9.69615 0.2821C10.0926 0.666571 10.1024 1.29966 9.7179 1.69615L5.7179 5.82115C5.32504 6.22629 4.67496 6.22629 4.2821 5.82115L0.2821 1.69615C-0.10237 1.29966 -0.0926305 0.666571 0.303855 0.2821C0.70034 -0.10237 1.33343 -0.0926305 1.7179 0.303855L5 3.68852Z" fill="#81878C" />
                                </svg>
                                <span className="profilelist">
                                    <ul className="font12 color111">
                                        
                                        {
                                            sessionStorage.getItem('admin_id') !== null ? <li onClick={backtoAdmin}>Back to Admin</li> : <><li onClick={logOut}>Logout</li></>
                                        }
                                    </ul>
                                </span>
                            </span>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}