import React, { useEffect, useState } from 'react'
import { inWords } from '../global';
import SweetAlert from 'sweetalert2';
import axioInstance from '../axiosInstance';
import formatAmount from 'indian-currency-formatter';

export default function NetworthAnalysis() {


  const [liabilities, setLiabilities] = useState();
  const [networth, setNetworth] = useState();

  const [riskDesc, setRiskDesc] = useState();
  const [riskQna, setRiskQna] = useState([]);

  const [drratio, setDrratio] = useState('');
  const [emiburden, setEmiburden] = useState('');
  const [savingrate, setSavingrate] = useState('');
  const [ratioExplain, setRationExplain] = useState([]);

  useEffect(() => {
    const profile_id = sessionStorage.getItem('profile_id');
    axioInstance.post(`profile/summary`, {profile_id:profile_id}).then(
      (response, data) => {

        setDrratio(response.data.dr_ratio);
        setEmiburden(response.data.emi_burden);
        setSavingrate(response.data.saving_rate);

        setLiabilities(response.data.total_liablities)
        //setNetworth(response.data.networth);

        setRiskDesc(response.data.risk_profile.description)

        setRationExplain(response.data.fp_report_ratio_explain);

        const quest = response.data.risk_profile.q_n_a ? JSON.parse(response.data.risk_profile.q_n_a) : '';
        setRiskQna(quest ? quest.question : []);
      });
  }, []);

  console.log(riskQna);
  // eslint-disable-next-line
  const [equity, setEquity] = useState();
  const [debt, setDebt] = useState();
  const [gold, setGold] = useState();
  const [other, setOther] = useState();

  const [total, setTotal] = useState();

  const dbRatio = () => {

    const { outstanding_loan, current_value } = ratioExplain.da_ratio;

    SweetAlert.fire({
      title: "D/A RATIO",
      html: `
     <div style="text-align: left;">
                    <p class="font14">Your loans should be less than 1/3 of your total assets.</p>
                    <p class="font12"><strong>Outstanding Loan:</strong> ${formatAmount(outstanding_loan)}</p>
                    <p class="font12"><strong>Current Value:</strong> ${formatAmount(current_value)}</p>
                    <p class="font12"><strong>Formula:</strong> (outstanding_loan / current_value) * 100</p>
                </div>
  `,
      timer: 5000,
    });

  }

  const emiRatio = () => {

    const { emiSum, incomeSum } = ratioExplain.emi_burden;
    SweetAlert.fire({
      title: "EMI BURDEN",
      html: `
      <div style="text-align: left;">
                     <p class="font14">Your EMIs should be less than 40 % of your Income.</p>
                     <p class="font12"><strong>Total EMI:</strong> ${formatAmount(emiSum)}</p>
                     <p class="font12"><strong>Total Income:</strong> ${formatAmount(incomeSum)}</p>
                     <p class="font12"><strong>Formula:</strong> (Total EMI / Total Income) * 100</p>
                 </div>
   `,
      timer: 5000,
    });

  }

  const savingRatio = () => {


    const { emiSum, incomeSum, expenseSum, lifeSum } = ratioExplain.saving_rate;
    SweetAlert.fire({
      title: "SAVING RATE",
      html: `
      <div style="text-align: left;">
                     <p class="font14">Your savings rate should be 25 % or more.</p>
                     <p class="font12"><strong>Total EMI:</strong> ${formatAmount(emiSum)}</p>
                     <p class="font12"><strong>Total Income:</strong> ${formatAmount(incomeSum)}</p>
                     <p class="font12"><strong>Total Expense:</strong> ${formatAmount(expenseSum)}</p>
                     <p class="font12"><strong>Total LI:</strong> ${formatAmount(lifeSum)}</p>
                     <p class="font12"><strong>Formula:</strong> ((Total Income -  (Total Expense + Total EMI + Total LI)) /  Total Income ) * 100</p>
                 </div>
   `,
      timer: 5000,
    });

  }


  useEffect(() => {

    const profile_id = sessionStorage.getItem('profile_id');
    axioInstance.post(`investment/group-dashboard`, {profile_id:profile_id}).then(
      (response, data) => {


        let find_equity = response.data.sum.find(product => product.name === "Equity");
        setEquity(find_equity ? find_equity.subTotal : '00');

        let find_debt = response.data.sum.find(product => (product.name === "Debt"));
        setDebt(find_debt ? find_debt.subTotal : '00');

        let find_gold = response.data.sum.find(product => (product.name === "Gold"));
        setGold(find_gold ? find_gold.subTotal : '00');

        let find_others = response.data.sum.find(product => product.name === "Property");
        setOther(find_others ? find_others.subTotal : '00');

        // setTotal((find_equity.subTotal + find_debt.subTotal + find_others.subTotal));
      });

  }, []);

  useEffect(() => {

    setTotal((parseInt(equity) + parseInt(debt) + parseInt(other) + parseInt(gold)));

  }, [equity, debt, other, gold]);

  useEffect(() => {

    console.log('total', total, 'liabilities', liabilities);

    setNetworth((parseInt(total) - parseInt(liabilities)));

  }, [total, liabilities]);


  return (
    <>
      <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Networth Analysis</span></div>

      <div className="font22 fw700 color0C4 pt30">Networth Analysis</div>

      <div className="totalSaving_box mt15">
        <div>
          <div className="font12 fw600 color6d7">TOTAL ASSETS</div>
          <div className="font24 fw500 color263 mt12">{inWords(total)}</div>
        </div>
        <div className="circleBtn_outer">
          <div className="circleBtn">-</div>
        </div>
        <div>
          <div className="font12 fw600 color6d7">TOTAL LIABILITIES </div>
          <div className="font24 fw500 color263 mt12">{inWords(liabilities)}</div>
        </div>
        <div className="circleBtn_outer">
          <div className="circleBtn">=</div>
        </div>
        <div>
          <div className="font12 fw600 color6d7">TOTAL NETWORTH</div>
          <div className="font24 fw500 color263 mt12">{inWords(networth)}</div>
        </div>
      </div>
      <div className="font12 fw500 color78B mt15">For details of your existing liabilities , please see the Loans Management section.</div>

      <div className="lineDevider mt40 mb40" />


      <div className="font22 fw700 color0C4">Financial Health</div>


      <div className="loanBox_outer mt25 p25">
        <div className="loandtl">
          <div className="font13 fw500 color6d7">D/A RATIO <i className="fa fa-info-circle" onClick={dbRatio}></i></div>
          <div className="font24 fw500 color182">{drratio > 0 ? drratio + ' %' : ' 0 %'}</div>
        </div>
        <div className="loandtl">
          <div className="font13 fw500 color6d7">EMI BURDEN <i className="fa fa-info-circle" onClick={emiRatio}></i></div>
          <div className="font24 fw500 color182">{emiburden > 0 ? emiburden + ' %' : ' 0 %'}</div>
        </div>
        <div className="loandtl">
          <div className="font13 fw500 color6d7">SAVING RATE <i className="fa fa-info-circle" onClick={savingRatio}></i></div>
          <div className="font24 fw500 color182">{savingrate > 0 ? savingrate + ' %' : ' 0 %'}</div>

        </div>
      </div>

      <div className="lineDevider mt40 mb40" />

      <div className="font22 fw700 color0C4">Risk Profile</div>
      <div className="riskbar_outer mt50">
        <div className="riskbar_sctn">
          {
            riskDesc === "Very Caution" && (<div className="font13 fw500 color485" style={{ 'margin-top': '-42px', 'color': '#22AF4A' }}><img src="images/icons/tooltip.png" alt='Very Caution' /></div>)
          }
          <div className="riskbar_status"></div>
          <div className="font13 fw500 color485 mt10">Very Caution</div>
        </div>
        <div className="riskbar_sctn">
          {
            riskDesc === "Caution" && (<div className="font13 fw500 color485" style={{ 'margin-top': '-42px', 'color': '#3CC97E' }}><img src="images/icons/tooltip.png" alt='Caution' /></div>)
          }
          <div className="riskbar_status"></div>
          <div className="font13 fw500 color485 mt10">Caution</div>
        </div>
        <div className="riskbar_sctn">
          {
            riskDesc === "Moderate" && (<div className="font13 fw500 color485" style={{ 'margin-top': '-42px', 'color': '#FCD64F' }}><img src="images/icons/tooltip.png" alt='Moderate' /></div>)
          }
          <div className="riskbar_status"></div>
          <div className="font13 fw500 color485 mt10">Moderate</div>
        </div>
        <div className="riskbar_sctn">
          {
            riskDesc === "Aggressive" && (<div className="font13 fw500 color485" style={{ 'margin-top': '-42px', 'color': '#FD9D4F' }}><img src="images/icons/tooltip.png" alt='Aggressive' /></div>)
          }
          <div className="riskbar_status"></div>
          <div className="font13 fw500 color485 mt10">Aggressive</div>
        </div>
        <div className="riskbar_sctn">
          {
            riskDesc === "Very Aggressive" && (<div className="font13 fw500 color485" style={{ 'margin-top': '-42px', 'color': '#FF5942' }}><img src="images/icons/tooltip.png" alt='Very Aggressive' /></div>)
          }
          <div className="riskbar_status"></div>
          <div className="font13 fw500 color485 mt10">Very Aggressive</div>
        </div>
      </div>


      <div className="lineDevider mt40 mb40" />

      <div className="font22 fw700 color0C4">Risk Profile QNA</div>

      {riskQna.map((item, index) => {
        return (
          <>

            <div key={index}>
              <label className="font16 fw600 color6d7 mt15">{item.label}</label>
              <div className="row">
                {item.answers.map((company, index) => {
                  return (
                    <>
                      <div className="col-12 col-sm-12">
                        <div className="radio_btn">
                          <label>
                            <input type="radio" name={item.key} value={company.ans_id} ques_id={item.ques_id} checked={company.ans_id === item.ans_value ? true : false} className='resetofgoal_box mr8' />
                            <span className='font13 fw500 color485'>{company.name}</span>
                          </label>
                        </div>
                      </div>
                    </>
                  );
                }
                )}

              </div>

            </div>

            <div className='mt15' />

          </>
        );
      })}

    </>
  )
}
