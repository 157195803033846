import React, { Fragment, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import axioInstance from '../axiosInstance';
import OtpInput from 'react-otp-input';

export default function ResetOtp({ setLoginStep, setLoginType, loginPara, setPopupContent, setShow, setPopupIcon, setPopupTitle }) {

  const { handleSubmit } = useForm();

  const [buttonSpinner, setButtonSpinner] = useState(false);

  const backbtn = () => {
    setLoginStep(1);
  }

  const onSubmit = Formdata => {

    setButtonSpinner(true);

    const Postdata = {
      "email": loginPara.email,
      "emailOtp": otp[0] + '' + otp[1] + '' + otp[2] + '' + otp[3] + '' + otp[4] + '' + otp[5],
      "otp_send_on": 'email',
      "otp_for": 'resetpassword'
    };


    axioInstance.post(`verify-reset-password-otp`, Postdata)
      .then(function (response) {

        if (response.data.emailVerify === 100) {
          setLoginType('forgotpasswordSetPassword');
          setLoginStep(2);
          setButtonSpinner(false);
        } else {
          setPopupIcon('errorIcon')
          setPopupTitle('Reset Password');
          setPopupContent("Invalid OTP");
          setShow(true);
          setButtonSpinner(false);
        }


      })
      .catch(e => {

        setPopupIcon('errorIcon')
        setPopupTitle('Reset Password');
        setPopupContent("Something went wrong. please try after again");
        setShow(true);
        setButtonSpinner(false);
      });

  }


  // eslint-disable-next-line
  const [otpError, setOtpError] = useState();
  const [otp, setOtp] = useState('');

  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds]);

  const resendOTP = () => {

    setButtonSpinner(true);

    const Otpdata = {
      "full_name": loginPara.full_name,
      "email": loginPara.email,
      "otp_send_on": 'email',
      "otp_for": "resetpassword"
    };

    axioInstance.post(`send-reset-password-otp`, Otpdata).then(
      function (response, data) {
        setOtp('');
        setMinutes(1);
        setSeconds(30);
        setButtonSpinner(false);
      }
    )

  };

  return (
    <Fragment>
      <div className="logincard">
        <div className="logincard_title pt30 pb30 pl35 pr35">
          <div className="font18 color212 fw600">Verify OTP</div>
          <div className="font13 fw500 color626">OTP has been sent on {loginPara.email}</div>
          <button type="button" className="btn btn-outline-primary btn-sm" onClick={backbtn}><span className="blueleftarrow mr5" /> Back</button>
        </div>
        <div className="p35">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="font14 fw600 color212">Enter OTP to continue. <span className="colorfa8">*</span></div>
            <div className="mt12 otpfield">

              <OtpInput
                className={"otpfield"}
                name="otp"
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span> </span>}
                renderInput={(props) => <input {...props} className="otp-field otp-field-npm" />}
              />

            </div>
            <div className="error mt5 font13 coloreb4">{otpError}</div>

            <div className="otptext font13 color626 mt30">

              {seconds > 0 || minutes > 0 ? (
                <p>
                  Resend OTP in: {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </p>
              ) : (
                <>Did not receive the OTP? <span className="color0d6 pointer" onClick={resendOTP}>Resend again</span></>
              )}
            </div>

            <button type={buttonSpinner ? "button" : "submit"} className="btn btn-primary btn-lg btn-block font13 fw500 mt20 continuebtn">{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Continue"}</button>


          </form>

          <div className="orline mt40 mb40">
            <span className="font13 color7b8 fw600">OR</span>
          </div>

          {
            (seconds === 0 && minutes === 0) ?
              <>
                <div className="googlebtn font15 fw600 color222 pointer" onClick={() => setLoginType('password')}>
                  Login via Password
                </div> </> :

              <>
                <div className="googlebtn font15 fw600 color222 pointer" disabled={'disabled'}>
                  Login via Password
                </div>
              </>
          }


          <div className="logintnctext font12 fw500 color485 mt35">
            By continuing, you agree with <a href='https://www.finnovate.in/terms-conditions' target='_blank' rel="noreferrer" className="color06d">Terms of Use</a> of Finnovate Financial Services Pvt Ltd. Our <a href='https://www.finnovate.in/privacy-policy' target='_blank' rel="noreferrer" className="color06d">Privacy Policy</a> describes how data is handled at Finnovate.
          </div>

        </div>

      </div>
    </Fragment>
  )
}
