import React, { Fragment, useEffect, useState } from 'react'
import SideImg from '../../img/Protection.png';
import { useForm } from 'react-hook-form';
import axioInstance from '../axiosInstance';
import {  sanitizeNumber } from '../global';
import formatAmount from 'indian-currency-formatter';
import SweetAlert from 'sweetalert2';

export default function CashflowScreen1({ setSubstep, substep, nextsubStep, clientName, incomeSelfParam, setIncomeSelfParam, incomeSpouseParam, setIncomeSpouseParam }) {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const [buttonSpinner, setButtonSpinner] = useState(false);

    const [income, setIncome] = useState(null);

    const [IncomeSelf, setIncomeSelf] = useState(null);
    const [IncomeSelfFreq, setIncomeSelfFreq] = useState(12);
    const [incomeSelfId, setIncomeSelfId] = useState(null);

    const [IncomeSpouse, setIncomeSpouse] = useState(0);
    const [IncomeSpouseFreq, setIncomeSpouseFreq] = useState(12);
    const [IncomeSpouseId, setIncomeSpouseId] = useState(null);

    const [Bonus, setBonus] = useState(0);
    const [BonusFreq, setBonusFreq] = useState(12);
    const [IncomeBonusId, setIncomeBonusId] = useState(null);

    const [RentalIncome, setRentalIncome] = useState(0);
    const [RentalIncomeFreq, setRentalIncomeFreq] = useState(12);
    const [IncomeRentalIncomeId, setIncomeRentalIncomeId] = useState(null);

    const [InvestmentIncome, setInvestmentIncome] = useState(0);
    const [InvestmentIncomeFreq, setInvestmentIncomeFreq] = useState(12);
    const [IncomeInvestmentIncomeId, setIncomeInvestmentIncomeId] = useState(null);


    useEffect(() => {

        const profile_id = sessionStorage.getItem('profile_id');

        if (profile_id !== null) {
            axioInstance.post(`get-income-expense`, { "profile_id": profile_id, "type": 1 }).then(
                (response, data) => {
                    if (response.data.length > 0) {

                        response.data.forEach(item => {

                            switch (item.type_name_sf) {

                                case 'IncomeSelf':
                                    setIncomeSelfId(item.income_id);
                                    setIncomeSelfFreq(item.frequency);
                                    setIncomeSelf(item.amount);
                                    break;

                                case 'IncomeSpouse':
                                    setIncomeSpouseId(item.income_id);
                                    setIncomeSpouseFreq(item.frequency);
                                    setIncomeSpouse(item.amount);
                                    break;

                                case 'RentalIncome':
                                    setIncomeRentalIncomeId(item.income_id);
                                    setRentalIncomeFreq(item.frequency);
                                    setRentalIncome(item.amount);
                                    break;

                                case 'InvestmentIncome':
                                    setIncomeInvestmentIncomeId(item.income_id);
                                    setInvestmentIncomeFreq(item.frequency);
                                    setInvestmentIncome(item.amount);
                                    break;

                                case 'Bonus':
                                    setIncomeBonusId(item.income_id);
                                    setBonusFreq(item.frequency);
                                    setBonus(item.amount);
                                    break;

                                default:
                                    break;

                            }

                        });

                    }
                });
        }
    }, [])

    useEffect(() => {

        setIncome({
            "IncomeSelf": incomeSelfId !== null ? formatAmount(IncomeSelf) : '',
            "IncomeSelfFreq": incomeSelfId !== null ? IncomeSelfFreq : 12,
            "incomeSelfId": incomeSelfId,
            "IncomeSpouse": IncomeSpouseId !== null ? formatAmount(IncomeSpouse) : '0',
            "IncomeSpouseFreq": IncomeSpouseId !== null ? IncomeSpouseFreq : 12,
            "IncomeSpouseId": IncomeSpouseId,
            "Bonus": IncomeBonusId !== null ? formatAmount(Bonus) : '0',
            "BonusFreq": IncomeBonusId !== null ? BonusFreq : 12,
            "IncomeBonusId": IncomeBonusId,
            "RentalIncome": IncomeRentalIncomeId !== null ? formatAmount(RentalIncome) : '0',
            "RentalIncomeFreq": IncomeRentalIncomeId !== null ? RentalIncomeFreq : 12,
            "IncomeRentalIncomeId": IncomeRentalIncomeId,
            "InvestmentIncome": InvestmentIncomeFreq !== null ? formatAmount(InvestmentIncome) : '0',
            "InvestmentIncomeFreq": InvestmentIncomeFreq !== null ? InvestmentIncomeFreq : 12,
            "IncomeInvestmentIncomeId": IncomeInvestmentIncomeId
        });

        setIncomeSelfAmount(incomeSelfId !== null ? formatAmount(IncomeSelf) : '');
        setIncomeSpouseAmount(IncomeSpouseId !== null ? formatAmount(IncomeSpouse) : '0');
        setBonusAmount(IncomeBonusId !== null ? formatAmount(Bonus) : '0');
        setRentalIncomeAmount(IncomeRentalIncomeId !== null ? formatAmount(RentalIncome) : '0');
        setInvestmentIncomeAmount(InvestmentIncomeFreq !== null ? formatAmount(InvestmentIncome) : '0');

    }, [IncomeSelf, IncomeSelfFreq, incomeSelfId, IncomeSpouse, IncomeSpouseFreq, IncomeSpouseId, Bonus, BonusFreq, IncomeBonusId, RentalIncome, RentalIncomeFreq, IncomeRentalIncomeId, InvestmentIncome, InvestmentIncomeFreq, IncomeInvestmentIncomeId])

    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        reset(income);
        // eslint-disable-next-line
    }, [income]);

    const [incomeSelfAmount, setIncomeSelfAmount] = useState();
    const [incomeSpouseAmount, setIncomeSpouseAmount] = useState();
    const [bonusAmount, setBonusAmount] = useState(0);
    const [rentalIncomeAmount, setRentalIncomeAmount] = useState(0);
    const [investmentIncomeAmount, setInvestmentIncomeAmount] = useState(0);

    const handleChange = (e) => {

        const { value } = e.target;

        if (e.target.name === 'IncomeSelf') {
            setIncomeSelfAmount(formatAmount(sanitizeNumber(value)));
        } else if (e.target.name === 'IncomeSpouse') {
            setIncomeSpouseAmount(formatAmount(sanitizeNumber(value)));
        } else if (e.target.name === 'Bonus') {
            setBonusAmount(formatAmount(sanitizeNumber(value)));
        } else if (e.target.name === 'RentalIncome') {
            setRentalIncomeAmount(formatAmount(sanitizeNumber(value)));
        } else if (e.target.name === 'InvestmentIncome') {
            setInvestmentIncomeAmount(formatAmount(sanitizeNumber(value)));
        }
    };

    const onSubmit = data => {

        setButtonSpinner(true);

        const Postdata = {
            profile_id: sessionStorage.getItem('profile_id'),
            admin_id:sessionStorage.getItem('admin_id'),
            type: 1,
            consider: 1,
            step: "2",
            income: {
                IncomeSelf: {
                    "sub_type": "IncomeSelf",
                    "amount": sanitizeNumber(incomeSelfAmount),
                    "frequency": data.IncomeSelfFreq
                },
                IncomeSpouse: {
                    "sub_type": "IncomeSpouse",
                    "amount": sanitizeNumber(incomeSpouseAmount),
                    "frequency": data.IncomeSpouseFreq
                },
                RentalIncome: {
                    "sub_type": "RentalIncome",
                    "amount": sanitizeNumber(rentalIncomeAmount),
                    "frequency": data.RentalIncomeFreq
                },
                OtherIncome: {
                    "sub_type": "Bonus",
                    "amount": sanitizeNumber(bonusAmount),
                    "frequency": data.BonusFreq
                },
                InvestmentIncome: {
                    "sub_type": "InvestmentIncome",
                    "amount": sanitizeNumber(investmentIncomeAmount),
                    "frequency": data.InvestmentIncomeFreq
                }
            }
        };

        setIncomeSelfParam(data.IncomeSelf.replace(/,/g, "") * data.IncomeSelfFreq);
        setIncomeSpouseParam(data.IncomeSpouse.replace(/,/g, "") * data.IncomeSpouseFreq);

        axioInstance.post(`profile-income`, Postdata)
            .then(function (response) {
                setButtonSpinner(false);
                sessionStorage.setItem("incomeData", JSON.stringify(data))
                setSubstep(2)

            })
            .catch(function (error) {
                SweetAlert.fire(
                    'Income',
                    'Failed. Something went wrong. Retry again',
                    'error'
                )
            });
    }

    const InputVal = (e, type) => {

        const inputAmount = sanitizeNumber(e.target.value);

        if (type === 1) {

            if (e.target.name === 'IncomeSelf') {
                setIncomeSelfAmount(inputAmount === "0" ? '' : formatAmount(inputAmount));
            } else if (e.target.name === 'IncomeSpouse') {
                setIncomeSpouseAmount(inputAmount === "0" ? '' : formatAmount(inputAmount));
            } else if (e.target.name === 'Bonus') {
                setBonusAmount(inputAmount === "0" ? '' : formatAmount(inputAmount));
            } else if (e.target.name === 'RentalIncome') {
                setRentalIncomeAmount(inputAmount === "0" ? '' : formatAmount(inputAmount));
            } else if (e.target.name === 'InvestmentIncome') {
                setInvestmentIncomeAmount(inputAmount === "0" ? '' : formatAmount(inputAmount));
            }

        } else if (type === 2) {

            if (e.target.name === 'IncomeSelf') {
                setIncomeSelfAmount(inputAmount > "0" ? formatAmount(inputAmount) : 0);
            } else if (e.target.name === 'IncomeSpouse') {
                setIncomeSpouseAmount(inputAmount > "0" ? formatAmount(inputAmount) : 0);
            } else if (e.target.name === 'Bonus') {
                setBonusAmount(inputAmount > "0" ? formatAmount(inputAmount) : 0);
            } else if (e.target.name === 'RentalIncome') {
                setRentalIncomeAmount(inputAmount > "0" ? formatAmount(inputAmount) : 0);
            } else if (e.target.name === 'InvestmentIncome') {
                setInvestmentIncomeAmount(inputAmount > "0" ? formatAmount(inputAmount) : 0);
            }

        }

    }

    return (
        <Fragment>
            <div className="innercontainer mb40">

                <div className="mainwrapper">

                    <div className="form_title">
                        <div className="mt30 font20 fw600">{clientName} Cashflow</div>
                        <div className="font15 color495">we need to map a plan to match your goal. so we need your income info</div>
                    </div>
                    <div className="row formWpr mt25">
                        <div className="col-lg-8 formouter">
                            <ul className="form_swipe_tab font12 fw600">
                                <li className="act">1</li>
                                <li onClick={() => setSubstep(2)}>2</li>
                                <li>3</li>
                                <li>4</li>
                            </ul>

                            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

                                <input type="hidden" name="IncomeSelfId" {...register("IncomeSelfId")} />
                                <input type="hidden" name="IncomeSpouseId" {...register("IncomeSpouseId")} />
                                <input type="hidden" name="IncomeBonusId" {...register("IncomeBonusId")} />
                                <input type="hidden" name="IncomeRentalIncomeId" {...register("IncomeRentalIncomeId")} />
                                <input type="hidden" name="IncomeInvestmentIncomeId" {...register("IncomeInvestmentIncomeId")} />

                                <div className="formtitle flex_center justify_center">
                                    <div>
                                        <div className="font20 fw600">Income</div>
                                        <div className="font15 color495 ">An approximate idea of your income is enough</div>
                                    </div>
                                    <div>

                                        <button type={buttonSpinner ? "button" : "submit"} className="bluebtn font13 fw600 d-none d-md-block" disabled={incomeSelfAmount ? false : true}>{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Save"}
                                            <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                                            </svg></span></button>
                                    </div>
                                </div>

                                {/* Form */}


                                <div className="p35">

                                    <div className="addincome_wpr">
                                        <div className="font16 fw600 color212">{""} family’s earned income</div>
                                    </div>



                                    <div className="form-row mt20">
                                        <div className="col-md-6 pr20">
                                            <label htmlFor="inputEmail4" className="font14 fw600">{"Self"} income <span className="colorfa8">*</span></label>
                                            <div className="input-group selectarrow">
                                                <input type="text" className="form-control color212 font14 fw500" name="IncomeSelf" {...register("IncomeSelf", { required: 'Income Self is required' })} value={incomeSelfAmount} onChange={(e) => handleChange(e)} />
                                                <select className="custom-select" name="IncomeSelfFreq" {...register("IncomeSelfFreq", {})}>
                                                    <option value="12">Monthly</option>
                                                    <option value="1">Yearly</option>
                                                </select>
                                                <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                            </div>
                                            <span className="text-danger">{errors.IncomeSelf && errors.IncomeSelf.message}</span>
                                        </div>
                                        <div className="col-md-6 pl20">
                                            <label htmlFor="inputEmail4" className="font14 fw600">Spouse income</label>
                                            <div className="input-group selectarrow">
                                                <input type="text" className="form-control color212 font14 fw500" name="IncomeSpouse" {...register("IncomeSpouse", {})} value={incomeSpouseAmount} onChange={(e) => handleChange(e)} onFocus={(e) => InputVal(e, 1)} onBlur={(e) => InputVal(e, 2)} />
                                                <select className="custom-select" name="IncomeSpouseFreq" {...register("IncomeSpouseFreq", {})}>
                                                    <option value="12">Monthly</option>
                                                    <option value="1">Yearly</option>
                                                </select>
                                                <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                            </div>
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>

                                    <div className="formdevider mt35 mb35" />


                                    <div className="addincome_wpr">
                                        <div className="font16 fw600 color212">{""} family’s other income</div>
                                    </div>

                                    <div className="form-row mt25">
                                        <div className="col-md-6 pr20">
                                            <label htmlFor="inputEmail4" className="font14 fw600">Rental</label>
                                            <div className="input-group selectarrow">
                                                <input type="text" className="form-control color212 font14 fw500" name="RentalIncome" {...register("RentalIncome", {})} value={rentalIncomeAmount} onChange={(e) => handleChange(e)} onFocus={(e) => InputVal(e, 1)} onBlur={(e) => InputVal(e, 2)} />
                                                <select className="custom-select" name="RentalIncomeFreq" {...register("RentalIncomeFreq", {})}>
                                                    <option value="12">Monthly</option>
                                                    <option value="1">Yearly</option>
                                                </select>
                                                <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                            </div>
                                            <span className="text-danger"></span>
                                        </div>
                                        <div className="col-md-6 pl20">
                                            <label htmlFor="inputEmail4" className="font14 fw600">Investment Income</label>
                                            <div className="input-group selectarrow">
                                                <input type="text" className="form-control color212 font14 fw500" name="InvestmentIncome" {...register("InvestmentIncome", {})} value={investmentIncomeAmount} onChange={(e) => handleChange(e)} onFocus={(e) => InputVal(e, 1)} onBlur={(e) => InputVal(e, 2)} />
                                                <select className="custom-select" name="InvestmentIncomeFreq" {...register("InvestmentIncomeFreq", {})}>
                                                    <option value="12">Monthly</option>
                                                    <option value="1">Yearly</option>
                                                </select>
                                                <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                            </div>
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="form-row mt25">
                                        <div className="col-md-6 pr20">
                                            <label htmlFor="inputEmail4" className="font14 fw600">Variable/Bonus</label>
                                            <div className="input-group selectarrow">
                                                <input type="text" className="form-control color212 font14 fw500" name="Bonus" {...register("Bonus", {})} value={bonusAmount} onChange={(e) => handleChange(e)} onFocus={(e) => InputVal(e, 1)} onBlur={(e) => InputVal(e, 2)} />
                                                <select className="custom-select" name="BonusFreq" {...register("BonusFreq", {})}>
                                                    <option value="12">Monthly</option>
                                                    <option value="1">Yearly</option>
                                                </select>
                                                <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                            </div>
                                            <span className="text-danger"> </span>
                                        </div>
                                    </div>


                                </div>


                                <div className="saveBtn d-block d-sm-none">
                                    <button type={buttonSpinner ? "button" : "submit"} className="btn btn-primary" disabled={incomeSelfAmount ? false : true}>{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Save and Continue"} <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10"></path></svg></span></button>
                                </div>

                            </form>

                            {/* Form End */}

                        </div>

                        <div className="col-lg-4 qouteouter d-none d-md-block">
                            <div className="qoutetext font18 fw500 color212 pl45 pr45">
                                <div className="qoutesign">
                                    &#8220;
                                </div>
                                If your parents are getting pension, or if you inherit any other income, please just click add income.
                            </div>


                            <div className="qouteimg" ><img src={SideImg} alt='Protection' /></div>
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>
    )
}
