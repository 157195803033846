import React, { useEffect, useState } from 'react'
import axioInstance from '../axiosInstance';
import { ageCalculate } from '../global'

export default function PersonalInformation() {

  // eslint-disable-next-line
  const [family, setFamily] = useState([]);
  const [self, setSelf] = useState([]);

  useEffect(() => {

    const profile_id = sessionStorage.getItem('profile_id');
    axioInstance.post(`profile-details/getfamily`, {profile_id:profile_id}).then(
      (response, data) => {
        setFamily(response.data);

        let selfData = response.data.find(product => product.relation === "self");

        setSelf(selfData);
      });

  }, [])

  return (
    <>
      <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Personal Information</span></div>

      <div className="font22 fw700 color0C4 pt30">Personal Information</div>

      <div className="personInfo_outer mt25">
        <div className="personInfo_title font16 fw600 color263">{self.full_name} <span className="color7B8">(you)</span></div>
        <div className="infotab_outer mt10">
          <span className="infotab font12 fw500 color263">{ageCalculate(self.dob)} yrs</span> <span className="infotab font12 fw500 color263">{self.dob}</span> <span className="infotab font12 fw500 color263">{self.email}</span> <span className="infotab font12 fw500 color263">+91 {self.phone}</span>
        </div>
      </div>

      <div className="person_info_tbl mt30">
        <table className="table font12">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Date of birth</th>
              <th scope="col">Age</th>
              <th scope="col">Relationship</th>
            </tr>
          </thead>
          <tbody>

            {
              family.map((family, key) => {

                
                return (
                  <>
                  {
                    family.relation !== 'self' && (<>
                    
                    <tr>
                      <th scope="row">{family.full_name}</th>
                      <td>{family.dob_flag === "1" ? "NA" : family.dob}</td>
                      <td>{family.age > 0 ? family.age +' y': family.age_month + ' m'} </td>
                      <td>{family.relation}</td>
                    </tr>
                    </>)
                  }
                   
                  </>
                )
              })
            }

          </tbody>
        </table>
      </div>

      <div className="font14 fw500 color182 mt45">
        As per our life stages Wealth Creation can be divided in various phases,
      </div>

      <ul className="variousList mt15 font14 color182 fw500">
        <li>Foundation Phase - Age 20 to 30</li>
        <li>Accumulation Phase Age 30 to 55</li>
        <li>Preservation Phase Age 55 to 75</li>
        <li>Distribution Phase - Age 75+</li>
      </ul>

    </>
  )
}
