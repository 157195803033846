import React, { Fragment, useState, useEffect } from 'react'
import axioInstance from '../components/axiosInstance';
import SweetAlert from 'sweetalert2';
import { Encryption } from '../components/global';
const friendsArray = [
    {
        "ques_id": 152,
        "key": "gender",
        "ans_id": null
    },
    {
        "ques_id": 153,
        "key": "age",
        "ans_id": null
    },
    {
        "ques_id": 154,
        "key": "occupation",
        "ans_id": null
    },
    {
        "ques_id": 155,
        "key": "IncomeSelf",
        "ans_id": null
    },
    {
        "ques_id": 156,
        "key": "IncomeSpouse",
        "ans_id": null
    },
    {
        "ques_id": 157,
        "key": "expense_perc",
        "ans_id": null
    },
    {
        "ques_id": 158,
        "key": "emi_perc",
        "ans_id": null
    },
    {
        "ques_id": 159,
        "key": "dependents",
        "ans_id": null
    },
    {
        "ques_id": 160,
        "key": "exp_inv_equity_mf",
        "ans_id": null
    },
    {
        "ques_id": 161,
        "key": "illustrative_portfolio",
        "ans_id": null
    },
    {
        "ques_id": 162,
        "key": "worry",
        "ans_id": null
    },
    {
        "ques_id": 163,
        "key": "financial_decission",
        "ans_id": null
    },
    {
        "ques_id": 164,
        "key": "equity_perc",
        "ans_id": null
    },
    {
        "ques_id": 165,
        "key": "acco_status",
        "ans_id": null
    },
    {
        "ques_id": 166,
        "key": "invest_knowledge",
        "ans_id": null
    }
];

export default function RiskSet() {

    const [riskq, setriskq] = useState([])
    const [steps, setSteps] = useState(0);
    const [profileId, setProfileId] = useState('');
    const [apiCall, setApiCall] = useState(false);
    // eslint-disable-next-line
    const [friends, setFriends] = useState(friendsArray); // Setting default value

    const StepChange = (step) => {
        setSteps(step);
    }

    const previousButton = () => {
        if (steps === 0) return;
        StepChange(steps - 1);
    }

    const stepNext = (e) => {


        let ques_id = e.target.getAttribute("ques_id");
        setFriends(
            friends.map((friend) =>
                friend.ques_id === parseInt(ques_id)
                    ? { ...friend, ans_id: e.target.value }
                    : { ...friend }
            )
        );

        if (steps === 14) {
            console.log('submit');
            setApiCall(true);
        } else {
            setSteps(steps + 1);
        }

    }

    useEffect(() => {


        if (apiCall === true) {
            const postData = {
                "set_id": 3,
                "fname": "Finnovate",
                "lname": "Finnovate",
                "mobile": 9568952565,
                "profile_id": sessionStorage.getItem('profile_id'),
                "dob": "1990-10-20",
                "email": "no-reply@finnovate.in",
                "weight": 1,
                "goaltype_id": 7,
                "admin_id":sessionStorage.getItem("admin_id"),
                "form_data": friends
            }

            axioInstance.post(`generate-risk`, postData)
                .then(function (response) {

                    const gpostData = { profile_id: sessionStorage.getItem('profile_id') };
                    axioInstance.post(`goals/reset`, gpostData)
                        .then(function (response) {
                            axioInstance.post(`profile/summary-update`, { profile_id: sessionStorage.getItem('profile_id') }).then(
                                (response, data) => {
                                    SweetAlert.fire(
                                        'Risk Profile',
                                        'Successfully created risk. Thank You.',
                                        'success'
                                     ).then(function () {
                                        window.location.href = '/sign-in';
                                     });
                                }
                            );
                        })
                })
        }
    }, [friends, apiCall, profileId]);



    useEffect(() => {

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const profile_id = params.get('profile_id');

        setProfileId(profile_id);

        axioInstance.post(`url-logged`, { url: window.location.href }).then(
            (response, data) => {

            }
        );

        axioInstance.post(`admin/validate-key-id`, { profile_id: profile_id }).then(
            (response, data) => {
                if (response.data.status === 101) {
                    window.location.href = '/sign-in';
                } else {
                    sessionStorage.setItem('profile_id', Encryption(response.data.key))
                }
            }
        ).catch(e => {
            window.location.href = '/sign-in';
        });

        const PostData = { weight: 1, set_id: 3, goaltype_id: 7 }
        axioInstance.post(`risk-question`, PostData).then(
            (response, data) => {
                setriskq(response.data.response_data.question);
                sessionStorage.setItem('questions', JSON.stringify(response.data.response_data.question));
            }
        );
    }, []);

    return (
        <Fragment>
            <div className="innercontainer mb40">
                <div className="mainwrapper">
                    <div className='form_title'>
                        <div className="mt30 font20 fw600">Risk Profile </div>
                        <div className="font15 color495">Few questions to understand your Risk Profile</div>
                    </div>
                    <div className="row formWpr mt25">
                        <div className="col-lg-12 formouter">

                            <div className="formtitle flex_center justify_center">
                                <div>
                                    <div className="font20 fw600">Risk Profile</div>
                                    <div className="font15 color495 ">Set your Risk Profile</div>
                                </div>
                                <div className='d-none d-md-block'>
                                    <button type="button" className="btn btn-primary" onClick={previousButton}>Previous </button>
                                </div>
                            </div>
                            {/* Form */}
                            <div className="p35">

                                <ul className="risk_tag font14 fw500 color182 mt15">

                                    {riskq.map((item, index) => {
                                        return (
                                            <>

                                                <div className={steps === index ? 'show' : 'hide'} >

                                                    <div className="color212 font14 fw600 mb25">{item.label} <span className="colorfa8">*</span></div>

                                                    {item.answers.map((company, index) => {
                                                        return (
                                                            <>
                                                                <li>
                                                                    <label>
                                                                        <input type="radio" name={item.key} value={company.ans_id} ques_id={item.ques_id} onClick={stepNext} />
                                                                        <span className="rdo_text_box">{company.name}</span>
                                                                    </label>
                                                                </li>
                                                            </>
                                                        );
                                                    }
                                                    )}
                                                </div>


                                            </>)
                                    })}
                                </ul>

                            </div>
                            {/* Form End */}
                        </div>
                    </div>
                    <div className="saveBtn backsaveBtn d-block d-sm-none">
                        <button type="button" className="btn btn-primary" onClick={previousButton}>Previous </button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
