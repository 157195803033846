import React, { Fragment, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2';
import axioInstance from '../axiosInstance';
import formatAmount from 'indian-currency-formatter';
import SideImg from '../../img/Protection.png';
import { Encryption, Decryption } from '../global';

export default function ProtectionScreen1({ setSubstep, substep, nextsubStep, lifeStage, clientName }) {

  console.log('protection page lifestage', lifeStage);
  const { register, handleSubmit, reset, formState: { errors, isDirty, isValid } } = useForm();

  const [terms, setTerms] = useState(null);

  useEffect(() => {

    axioInstance.post(`profile/term-data`, { profile_id: sessionStorage.getItem('profile_id') }).then(function (response, data) {
      const res = response.data[0];
      setTerms({ selftermInsurance: res.selftermInsurance, selfsumAssured: res.selfsumAssured > 0 ? res.selfsumAssured : '', selfMoneyType: res.selfMoneyType, selfpremiumAmount: res.selfpremiumAmount > 0 ? formatAmount(res.selfpremiumAmount) : '', spousetermInsurance: res.spousetermInsurance, spousesumAssured: res.spousesumAssured > 0 ? res.spousesumAssured : '', spouseMoneyType: res.spouseMoneyType, spousepremiumAmount: res.spousepremiumAmount > 0 ? formatAmount(res.spousepremiumAmount) : '', selfInvestId: res.selfInvestId, spouseInvestId: res.spouseInvestId, spouseFrequency: res.spouseFrequency, selfFrequency: res.selfFrequency });
    });



    axioInstance.post(`profile-details/getfamilybyrelation`, { profile_id: sessionStorage.getItem('profile_id'), relation: 'onlyspouse' }).then(function (response, data) {

      if (response.data.length > 0) {
        sessionStorage.setItem('spouse_prof_det_id', Encryption(response.data[0].profile_details_id));
      }


    });

  }, []);

  // effect runs when user state is updated
  useEffect(() => {
    // reset form with user data
    reset(terms);
    // eslint-disable-next-line
  }, [terms]);

  const [selfsumAssured, setSelfsumAssured] = useState();

  const [selfpremiumAmount, setSelfpremiumAmount] = useState();
  const [spousepremiumAmount, setSpousepremiumAmount] = useState();

  const [buttonSpinner, setButtonSpinner] = useState(false);

  const handleChange = (e) => {

    const { value } = e.target;

    if (e.target.name === 'selfpremiumAmount') {
      setSelfpremiumAmount(formatAmount(value.replace(/,/g, "")));
    } else if (e.target.name === 'spousepremiumAmount') {
      setSpousepremiumAmount(formatAmount(value.replace(/,/g, "")));
    } else if (e.target.name === "selfsumAssured") {
      setSelfsumAssured(e.target.value);
    }

  };

  const onSubmit = data => {

    setButtonSpinner(true);

    if (data.selftermInsurance === "yes") {


      const Postdata = {
        "prop_id": sessionStorage.getItem('profile_id'),
        "profile_id": sessionStorage.getItem('profile_id'),
        "profile_details_id": sessionStorage.getItem('profile_details_id'),
        "type": 4,
        "sub_type": 1,
        "owner": sessionStorage.getItem('profile_id'),
        "instr_name": 'Self Term',
        "invest_type": 0,
        "invest_amount": data.selfpremiumAmount.replace(/,/g, ""),
        "amount": data.selfpremiumAmount.replace(/,/g, ""),
        "cost_amount": data.selfMoneyType * data.selfsumAssured,
        "moneyDigits": data.selfMoneyType,
        "insured_value": data.selfMoneyType * data.selfsumAssured,
        "current_value": data.selfMoneyType * data.selfsumAssured,
        "frequency": data.selfFrequency,
        "ppt": 1,
        "policy_term": 1,
        "months": "Y",
        "rider_id": [Decryption(sessionStorage.getItem('profile_details_id'))],
        "invest_id": data.selfInvestId,
        "admin_id":sessionStorage.getItem("admin_id")
      };

      // logical fields - start_date, months

      axioInstance.post(`investment/add-investment`, Postdata)
        .then(function (response) {



          if (data.spousetermInsurance === "yes") {


            const Postdata = {
              "prop_id": sessionStorage.getItem('profile_id'),
              "profile_id": sessionStorage.getItem('profile_id'),
              "profile_details_id": sessionStorage.getItem('spouse_prof_det_id'),
              "type": 4,
              "sub_type": 1,
              "owner": sessionStorage.getItem('profile_id'),
              "instr_name": 'Spouse Term',
              "invest_type": 0,
              "invest_amount": data.spousepremiumAmount.replace(/,/g, ""),
              "amount": data.spousepremiumAmount.replace(/,/g, ""),
              "cost_amount": data.spouseMoneyType * data.spousesumAssured,
              "insured_value": data.spouseMoneyType * data.spousesumAssured,
              "current_value": data.spouseMoneyType * data.spousesumAssured,
              "moneyDigits": data.spouseMoneyType,
              "frequency": data.spouseFrequency,
              "ppt": 1,
              "policy_term": 1,
              "rider_id": [Decryption(sessionStorage.getItem('spouse_prof_det_id'))],
              "invest_id": data.spouseInvestId,
              "admin_id":sessionStorage.getItem("admin_id")
            };

            // logical fields - start_date, months

            axioInstance.post(`investment/add-investment`, Postdata)
              .then(function (response) {
                setButtonSpinner(false);
                sessionStorage.setItem("protectionData", JSON.stringify(data))
                setSubstep(nextsubStep(substep, 2))

              })
              .catch(function (error) {
                SweetAlert.fire({
                  toast: true,
                  icon: 'error',
                  title: 'Failed. Something went wrong. Retry again.',
                  animation: false,
                  position: 'top-right',
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                    toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                  }
                });
              });

          } else {
            setButtonSpinner(false);
            setSubstep(nextsubStep(substep, 2))
          }

        })
        .catch(function (error) {

          SweetAlert.fire({
            toast: true,
            icon: 'error',
            title: 'Failed. Something went wrong. Retry again.',
            animation: false,
            position: 'top-right',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', SweetAlert.stopTimer)
              toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
            }
          })

        });

    } else {
      setSubstep(nextsubStep(substep, 2))
    }

  }


  const [selfInput, setSelfInput] = useState(true);
  const [spousefInput, setSpouseInput] = useState(true);

  const validateSelf = (checkbox) => {

    if (checkbox === "no") {
      setSelfInput(false);
    } else {
      setSelfInput(true);
    }

  }

  const validateSpouse = (checkbox) => {

    if (checkbox === "no") {
      setSpouseInput(false)
    } else {
      setSpouseInput(true);
    }


  }




  return (
    <Fragment>

      <div className="mainwrapper">

        <div className="form_title">
          <div className="mt30 font20 fw600">{clientName} Protection</div>
          <div className="font15 color495">Few questions to understand your family's protection</div>
        </div>

        <div className="row formWpr mt25">
          <div className="col-lg-8 formouter">
            <ul className="form_swipe_tab font12 fw600">
              <li className="act">1</li>
              <li>2</li>
            </ul>

            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

              <div className="formtitle flex_center justify_center">
                <div>
                  <div className="font20 fw600">Are you protected enough?</div>
                  <div className="font15 color495 ">Give us a sneak of your term life insurance details</div>
                </div>
                <div>

                  <button type={buttonSpinner ? "button" : "submit"} className="bluebtn font13 fw600 d-none d-md-block" disabled={!isDirty && !isValid}>{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Save"}
                    <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                    </svg></span></button>
                </div>
              </div>

              {/* Form */}

              <div className="p35">

                <input type="hidden" name="selfInvestId" {...register("selfInvestId")} value="" />
                <input type="hidden" name="spouseInvestId" {...register("spouseInvestId")} value="" />

                <div className="form-row mb-2">
                  <div>
                    <div className="color212 font14 fw600">Do you have Term Life Insurance? <span className="colorfa8">*</span>
                    </div>
                    <ul className="steps_tag font14 fw500 color182 mt15">
                      <li>
                        <label>
                          <input type="radio" name="selftermInsurance" {...register("selftermInsurance", { required: 'Term Insurance is required' })} value="yes" onClick={() => validateSelf('yes')} checked={selfInput === true ? "checked" : ""} />
                          <span className="rdo_text_box">
                            Yes <i className="fa-solid fa-check FaIcon"></i>
                          </span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type="radio" name="selftermInsurance" {...register("selftermInsurance", { required: 'Term Insurance is required' })} value="no" onClick={() => validateSelf('no')} {...selfInput === false ? "checked" : ""} />
                          <span className="rdo_text_box">
                            No <i className="fa-solid fa-xmark FaIcon"></i>
                          </span>
                        </label>
                      </li>
                    </ul>
                    <span className="text-danger"> {errors.selftermInsurance && errors.selftermInsurance.message}</span>
                  </div>
                </div>

                {

                  selfInput === true && (<>
                    <div className="form-row">
                      <div className="col-md-6 pr20">
                        <label htmlFor="inputEmail4" className="font14 fw600">Sum assured</label>
                        <div className="input-group selectarrow">
                          <input type="text" className="form-control font14 color212 fw500" name="selfsumAssured" aria-label="Text input with dropdown button" autoComplete='off' {...register("selfsumAssured", { required: 'Sum assured is required' })} defaultValue={selfsumAssured} onChange={handleChange} maxLength={3} minLength={1} />
                          <select className="custom-select" id="inputGroupSelect01" name='selfMoneyType' {...register("selfMoneyType")}>
                            <option value="100000" defaultValue={"100000"}>Lakhs</option>
                            <option value="10000000">Crore</option>
                          </select>
                          <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                        </div>
                        <span className="text-danger"> {errors.selfsumAssured && errors.selfsumAssured.message}</span>
                      </div>
                      <div className="col-md-6 pl20">
                        <label htmlFor="inputEmail4" className="font14 fw600">Premium amount</label>
                        <div className="input-group selectarrow">
                          <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" name="selfpremiumAmount" autoComplete='off' {...register("selfpremiumAmount", { required: 'Premium Amount is required' })} onChange={handleChange} value={selfpremiumAmount} />
                          <select className="custom-select" id="inputGroupSelect01" name='selfFrequency' {...register("selfFrequency")}>
                            <option value="12">Monthly</option>
                            <option value="4">Quarterly</option>
                            <option value="2">Half Yearly</option>
                            <option value="1" defaultValue="1" selected>Yearly</option>
                            <option value="0">Lumpsum</option>
                          </select>

                          <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                        </div>
                        <span className="text-danger"> {errors.selfpremiumAmount && errors.selfpremiumAmount.message}</span>
                      </div>
                    </div>
                  </>)
                }

                {
                  (lifeStage === "separatedWithKids" || lifeStage === "single") ? <></> :
                    <>
                      <Fragment>
                        <div className="formdevider mt35 mb35" />

                        <div className="form-row">
                          <div>
                            <div className="color212 font14 fw600">Does your spouse have have Term Life Insurance?<span className="colorfa8">*</span>
                            </div>
                            <ul className="steps_tag font14 fw500 color182 mt15">
                              <li>
                                <label>
                                  <input type="radio" name="spousetermInsurance" {...register("spousetermInsurance", { required: 'Term Insurance is required' })} value="yes" onClick={() => validateSpouse('yes')} checked={spousefInput === true ? "checked" : ""} />
                                  <span className="rdo_text_box">
                                    Yes <i className="fa-solid fa-check FaIcon"></i>
                                  </span>
                                </label>
                              </li>
                              <li>
                                <label>
                                  <input type="radio" name="spousetermInsurance" {...register("spousetermInsurance", { required: 'Term Insurance is required' })} value="no" onClick={() => validateSpouse('no')} {...selfInput === false ? "checked" : ""} />
                                  <span className="rdo_text_box">
                                    No <i className="fa-solid fa-xmark FaIcon"></i>
                                  </span>
                                </label>
                              </li>
                            </ul>
                            <span className="text-danger"> {errors.spousetermInsurance && errors.spousetermInsurance.message}</span>
                          </div>
                        </div>

                        {

                          spousefInput === true && (<>
                            <div className="form-row">
                              <div className="col-md-6 pr20">
                                <label htmlFor="inputEmail4" className="font14 fw600">Sum assured</label>
                                <div className="input-group selectarrow">
                                  <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" name="spousesumAssured" {...register("spousesumAssured", { required: 'Sum assured is required' })} maxLength={3} minLength={1} />
                                  <select className="custom-select" id="inputGroupSelect01" name='spouseMoneyType' {...register("spouseMoneyType")}>
                                    <option selected value="100000">Lakhs</option>
                                    <option value="10000000">Crore</option>
                                  </select>
                                  <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                </div>
                                <span className="text-danger"> {errors.spousesumAssured && errors.spousesumAssured.message}</span>
                              </div>
                              <div className="col-md-6 pl20">
                                <label htmlFor="inputEmail4" className="font14 fw600">Premium amount</label>
                                <div className="input-group selectarrow">
                                  <input type="text" className="form-control font14 color212 fw500" aria-label="Text input with dropdown button" name="spousepremiumAmount" {...register("spousepremiumAmount", { required: 'Sum assured is required' })} onChange={handleChange} value={spousepremiumAmount} />
                                  <select className="custom-select" id="inputGroupSelect01" name='spouseFrequency' {...register("spouseFrequency")}>
                                    <option value="12">Monthly</option>
                                    <option value="4">Quarterly</option>
                                    <option value="2">Half Yearly</option>
                                    <option value="1" selected>Yearly</option>
                                    <option value="0">Lumpsum</option>
                                  </select>
                                  <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                                </div>
                                <span className="text-danger"> {errors.spousepremiumAmount && errors.spousepremiumAmount.message}</span>
                              </div>
                            </div>
                          </>)
                        }


                      </Fragment>
                    </>
                }


              </div>

              <div className="saveBtn d-block d-sm-none">
                <button type={buttonSpinner ? "button" : "submit"} disabled={!isDirty && !isValid} className="btn btn-primary">{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Save and Continue"} <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10"></path></svg></span></button>
              </div>
            </form>
            {/* Form End */}

          </div>

          <div className="col-lg-4 qouteouter d-none d-md-block">
            <div className="qoutetext font18 fw500 color212 pl45 pr45">
              <div className="qoutesign">
                &#8220;
              </div>
              {"We care about your insurance because this comes first in your financial journey."}
            </div>


            <div className="qouteimg" ><img src={SideImg} alt='Protection' /></div>
          </div>
        </div>

      </div>
    </Fragment>
  )
}
