import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import axioInstance from '../../axiosInstance';
import {  ageCalculate } from '../../global';
//import formatAmount from 'indian-currency-formatter';
import { Encryption } from '../../global';

export default function EditFamily({ toggleClass, setActive, profileData }) {

    const [buttonSpinner, setButtonSpinner] = useState(false);

    const { register, handleSubmit, setError, formState: { errors } } = useForm();

    const Back = () => {
        setActive(false);
    }

    const [getDob, setGetDob] = useState(false);

    const onSubmit = data => {

        setButtonSpinner(true);


        // eslint-disable-next-line
        const PostData = {
            "profile_id": data.profile_id ? Encryption(data.profile_id) : sessionStorage.getItem('profile_id'),
            "profile_details_id": data.profile_details_id,
            "dob": data.birthDate,
            "name": data.fullName,
            "relation": data.relationship,
            "gender": data.gender,
            "age": data.age,
            "dob_flag": getDob === true ? 2 : data.dob_flag
        };



        const calAge = ageCalculate(data.birthDate);
        console.log('calAge', calAge);


        if (profileData.relation === "self") {

            if (calAge >= 65) {

                const postData = { profile_id: data.profile_id ? Encryption(data.profile_id) : sessionStorage.getItem('profile_id'), assumption: { retire_age: parseInt(calAge) + 5 } };

                axioInstance.post(`profile-assumptions/update_assumption`, postData)
                    .then(function (response) {

                        const gpostData = { profile_id: data.profile_id ? Encryption(data.profile_id) : sessionStorage.getItem('profile_id') };
                        axioInstance.post(`goals/reset`, gpostData)
                            .then(function (response) {

                                axioInstance.post(`profile/summary-update`, { profile_id: data.profile_id ? Encryption(data.profile_id) : sessionStorage.getItem('profile_id') }).then(
                                    (response, data) => {
                                        setButtonSpinner(false);
                                        window.location.reload();
                                    }
                                );
                            })
                    })
            }
        }

        if (parseInt(data.age) === parseInt(calAge)) {

            axioInstance.post(`profile-details/create`, PostData)
                .then(function (response) {
                    setButtonSpinner(false);
                    window.location.reload();
                });

        } else {

            const checkData = {
                "profile_id": Encryption(data.profile_id),
                "profile_details_id": Encryption(data.profile_details_id),
            };

            axioInstance.post(`check-goal-count`, checkData)
                .then(function (response) {

                    if (response.data > 0) {

                        setError('birthDate', { type: 'custom', message: 'A goal is already added. Please delete it first' });

                    } else {

                        axioInstance.post(`profile-details/create`, PostData)
                            .then(function (response) {
                                setButtonSpinner(false);
                                window.location.reload();
                            });

                    }
                });


            console.log('diff age check goals');
        }

        /*
*/
    }


    useEffect(() => {
        const profile_id = sessionStorage.getItem('profile_id');
        if (profile_id !== null) {
            axioInstance.post(`get-income-expense`, { "profile_id": profile_id, "type": 1 }).then(
                (response, data) => {
                    if (response.data.length > 0) {
                    }
                });
        }
    }, []);

    return (
        <Fragment>


            <div className="rightSide_menu_outer">
                <div className="rightSide_menu">

                    <div className="rightSide_menu_header">
                        <div>
                            <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
                        </div>
                        <div className="font14 fw500 color182">
                            {profileData.full_name ? 'Edit' : 'Add'} Member
                        </div>
                        <div>&nbsp;</div>
                    </div>
                    <div className="topBanner pl25">
                        <div className="font18 fw600 color182 mt50">Member Details</div>
                        <div className="font14 fw500 color485">{profileData.full_name ? 'Edit' : 'Add'} {profileData.full_name} details</div>
                    </div>

                    <div className="form_prnt p20">
                        <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>


                            <input type="hidden" name="profile_id" {...register("profile_id")} defaultValue={profileData.profile_id} />
                            <input type="hidden" name="profile_details_id" {...register("profile_details_id")} defaultValue={profileData.profile_details_id} />

                            <div className="form-row">
                                <div className="col-md-12 pr20">
                                    <label htmlFor="inputEmail4" className="font14 fw600">Full Name <span className="colorfa8">*</span></label>
                                    <input type="text" className="form-control font14 color212 fw500" name="fullName" autoComplete='off' {...register("fullName", { required: 'Name is required', minLength: { value: 3, message: 'Name is too short' }, maxLength: 'Name is too long' })} placeholder="Enter full name" defaultValue={profileData.full_name} />
                                    <small id="emailHelp" className="form-text font11 text-danger">{errors.fullName && errors.fullName.message}</small>
                                </div>
                            </div>

                            {
                                profileData.relation === "self" ? (
                                    <>

                                        <div className="form-row mt25">
                                            <div className="col-md-12 pr20">
                                                <div className="font14 color212 fw600">Gender</div>
                                                <div className="selectgoal propertysearch mt10">
                                                    <div className="input-group selectarrow">
                                                        <select className="custom-select font14 color212 fw500" id="inputGroupSelect01" name='gender' {...register("gender", { required: 'Gender is required' })} defaultValue={profileData.gender !== "na" ? profileData.gender : ""}>
                                                            <option value="">Select gender</option>
                                                            <option value="male">Male</option>
                                                            <option value="female">Female</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <small className="form-text font11 text-danger">{errors.relationship && errors.relationship.message}</small>
                                            </div>
                                        </div>
                                        <input type="hidden" name="relationship" {...register("relationship")} defaultValue={profileData.relation} />
                                    </>
                                ) :
                                    (
                                        <>
                                            <input type="hidden" name="gender" {...register("gender")} defaultValue={'na'} />
                                            <div className="form-row mt25">
                                                <div className="col-md-12 pr20">
                                                    <div className="font14 color212 fw600">Relationship</div>
                                                    <div className="selectgoal propertysearch mt10">
                                                        <div className="input-group selectarrow">
                                                            <select className="custom-select font14 color212 fw500" id="inputGroupSelect01" name='relationship' {...register("relationship", { required: 'Relationship is required' })} defaultValue={profileData.relation}>
                                                                <option value="">Select Relation</option>
                                                                <option value="spouse">Spouse</option>
                                                                <option value="son">Son</option>
                                                                <option value="daughter">Daughter</option>

                                                                <option value="father">Father</option>
                                                                <option value="mother">Mother</option>
                                                                <option value="HUF">HUF</option>
                                                                <option value="father-in-law">Father-in-law</option>
                                                                <option value="mother-in-law">Mother-in-law</option>
                                                                <option value="sibling">Sibling</option>
                                                                <option value="company">Company</option>
                                                                <option value="employee">Employee</option>
                                                                <option value="other">Other</option>
                                                                <option value="Individual">Individual</option>
                                                                <option value="Non-Individual">Non-Individual</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <small className="form-text font11 text-danger">{errors.relationship && errors.relationship.message}</small>
                                                </div>
                                            </div>

                                        </>
                                    )
                            }



                            <div className="form-row mt25">

                                {
                                    profileData.dob_flag === '1' ?
                                        (<>
                                            <div className="col-md-12 pr20">
                                                <label htmlFor="inputEmail4" className="font14 fw600">Age <span className="editBtn" style={{ 'font-size': '11px' }} onClick={() => setGetDob(true)}> <i className="fa-solid fa-pencil" style={{ 'font-size': '9px' }}></i> Edit</span></label>
                                                <input type="text" className="form-control font14 color212 fw500" id="age" name="age" autoComplete='off' {...register("age", { required: 'age is required' })} defaultValue={profileData.age} readOnly />
                                                <small id="emailHelp" className="form-text font11 text-danger">{errors.age && errors.age.message}</small>
                                            </div>
                                            <input type="hidden" name='dob_flag' {...register("dob_flag")} defaultValue={"1"} />

                                            <input type="hidden" id="birthDate" name="birthDate" autoComplete='off' {...register("birthDate")} defaultValue={profileData.dob} />

                                        </>) :

                                        (<>

                                            <input type="hidden" name='age' {...register("age")} defaultValue={profileData.age} />
                                            <div className="col-md-12 pr20">
                                                <label htmlFor="inputEmail4" className="font14 fw600">Date of birth <span className="colorfa8">*</span></label>
                                                <input type="date" maxLength="4" pattern="[1-9][0-9]{3}" max={"9999-12-31"} className="form-control font14 color212 fw500" id="birthDate" name="birthDate" autoComplete='off' {...register("birthDate", { required: 'DOB is required' })} placeholder="Select the date" defaultValue={profileData.dob} />
                                                <small id="emailHelp" className="form-text font11 text-danger">{errors.birthDate && errors.birthDate.message}</small>
                                            </div>
                                            <input type="hidden" name='dob_flag' {...register("dob_flag")} defaultValue={"2"} />
                                        </>)
                                }

                                {
                                    getDob === true ? (<>
                                        <div className="col-md-12 pr20 mt20">
                                            <label htmlFor="inputEmail4" className="font14 fw600">SET Date of birth <span className="colorfa8">*</span></label>
                                            <input type="date" maxLength="4" pattern="[1-9][0-9]{3}" max={"9999-12-31"} className="form-control font14 color212 fw500" id="birthDate" name="birthDate" autoComplete='off' {...register("birthDate", { required: 'DOB is required' })} placeholder="Select the date" defaultValue={profileData.dob} />
                                            <small id="emailHelp" className="form-text font11 text-danger">{errors.birthDate && errors.birthDate.message}</small>
                                        </div>
                                        <input type="hidden" name='dob_flag' {...register("dob_flag")} defaultValue={"2"} />
                                    </>) : ''
                                }

                            </div>

                            <div className="saveBtn backsaveBtn">
                                <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                                <button type={buttonSpinner ? "button" : "submit"} className="btn btn-primary">{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> adding</> : "Save"} </button>
                            </div>
                        </form>
                    </div>



                </div>
            </div>

        </Fragment >
    )
}
