import axios from 'axios';
import React, { Fragment, useState } from 'react'
//import axioInstance from '../axiosInstance';
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'
//import { Encryption } from '../global';
import { apiUrl } from '../global';

export default function ULoginPasswordReset({ setUforgotPass }) {

  const { register, handleSubmit, formState: { errors } } = useForm();

  const [buttonSpinner, setButtonSpinner] = useState(false);


  const onSubmit = data => {

    setButtonSpinner(true);

    console.log(data.emailAddress);


    axios.post(apiUrl + 'ulogin/forgotpasslink', {
      email_id: data.emailAddress
    }).then(function (response) {

      SweetAlert.fire({
        toast: true,
        icon: 'success',
        title: response.data.msg,
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', SweetAlert.stopTimer)
          toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
        }
      }).then(function () {
        window.location.href = "/ulogin"
      })

    })



  }

  return (
    <Fragment>

      <div className="logincard">
        <div className="logincard_title pt30 pb30 pl35 pr35">
          <div className="font18 color212 fw600">Reset Password</div>
          <div className="font13 fw500 color626">We will send you a reset link on your Email</div>
          <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => setUforgotPass(false)}><span className="blueleftarrow mr5" /> Back</button>
        </div>
        <div className="p35">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="font14 fw600 color212">Registered Email<span className="colorfa8 ml5">*</span></div>
            <div className="mt12 mobilefield">
              <input type="text" id="emailAddress" name="emailAddress" autoComplete='off' {...register("emailAddress", { required: 'Email is required', pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'Invalid Email' } })} placeholder="Enter email address" />
            </div>
            <span className="text-danger font11"> {errors.emailAddress && errors.emailAddress.message}</span>

            <button type={buttonSpinner ? "button" : "submit"} className="btn btn-primary btn-lg btn-block font13 fw500 mt20 continuebtn">{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Continue"}</button>
          </form>
        </div>
      </div>
    </Fragment>
  )
}
